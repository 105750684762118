import React from 'react';
import styles from './ScheduleServiceButton.module.css';
import clsx from 'clsx';
import { enterKeyPressed } from '@utils/enterKeyPressed';
import { Button } from '@components-lib';

type ScheduleServiceButtonProps = {
  url: string;
  label: string;
  displayViewMode: string;
  classes?: { container?: string; button?: string };
};

const ScheduleServiceButton = ({ url, label, displayViewMode, classes }: ScheduleServiceButtonProps) => {
  const isWarningViewMode = displayViewMode === 'warning';
  return (
    <Button
      size="large"
      variant={isWarningViewMode ? 'contained' : 'outlined'}
      className={clsx(
        styles['ScheduleServiceButton'],
        {
          [styles['ScheduleServiceButton--warning']]: isWarningViewMode,
        },
        classes?.button,
      )}
      onClick={() => window.open(url, '_blank')}
      aria-label="Schedule Service Button"
    >
      {label}
    </Button>
  );
};

export default ScheduleServiceButton;
