import React, { useEffect, useMemo } from 'react';
import { useFlowMachineContextFlowStorage } from '@lib-appRouter/RouterContext';
import { hasPackageAdded } from '@manageSubscription';
import TrialPackageOptions from '@manageSubscription/Packages/DiscountPackage/TrialDiscount/TrialPackageOptions';
import { EligiblePackageInfo } from '@manageSubscription/Types';

export interface TrialPackageOptionsWrapperProps {
  trialPackage: EligiblePackageInfo;
  setSelectedTrialPackage: (trialVariant: EligiblePackageInfo) => void;
}

const TrialPackageOptionsWrapper = ({ trialPackage, setSelectedTrialPackage }: TrialPackageOptionsWrapperProps) => {
  const { packageSubscriptions } = useFlowMachineContextFlowStorage();

  const selectedTrial = useMemo(() => {
    const selected = trialPackage?.variants.find((v) =>
      hasPackageAdded(packageSubscriptions, { ...trialPackage, variant: v }),
    );
    return selected ? { ...trialPackage, variant: selected } : null;
  }, [packageSubscriptions]);

  useEffect(() => {
    setSelectedTrialPackage(selectedTrial);
  }, [packageSubscriptions]);

  return (
    <TrialPackageOptions
      trialPackage={trialPackage}
      selectedTrialPackage={selectedTrial}
      onTrialVariantSelect={setSelectedTrialPackage}
    />
  );
};

export default TrialPackageOptionsWrapper;
