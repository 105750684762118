// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vc6P2SyrlbIarUgp63cF {
  --checkbox-color: var(--theme-color-grey-c1);
  --checkbox-disabled-icon-color: var(--checkbox-color);
  --checkbox-icon-color: var(--theme-color-grey-93);

  align-items: center;
  display: grid;
  grid-template-columns: 2fr repeat(var(--settings-grid-columns, 1), 1fr);
}

.vc6P2SyrlbIarUgp63cF > form {
  display: contents;
}

.OrVJZEP_TgVl8jQb8cgL {
  font-weight: bold;
}

.OrVJZEP_TgVl8jQb8cgL > div {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.zcVUHXNkV63EAetIHEuT {
  display: contents;
}

._ftXTRoimzjDloUIvbGg {
  padding: 0.5em;
  justify-self: center;
}

._ftXTRoimzjDloUIvbGg:first-child {
  padding-left: 0;
  justify-self: left;
}

.zrTaV4oNgjBRWjfOf2x6 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5em;
  height: 1.5em;
  border: 1px solid var(--checkbox-color);
  border-radius: 0;
  margin: 0 auto;
}

.zrTaV4oNgjBRWjfOf2x6 svg {
  display: none;
  color: var(--checkbox-icon-color);
  font-size: 0.95em;
}

._Bbq6xi1QMNeuj8Gpnq3 svg {
  display: block;
}

.LQkO3plfSW2n6VUq75Z4 svg {
  color: var(--checkbox-disabled-icon-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/SettingsGrid/SettingsGrid.module.css"],"names":[],"mappings":"AAAA;EACE,4CAA4C;EAC5C,qDAAqD;EACrD,iDAAiD;;EAEjD,mBAAmB;EACnB,aAAa;EACb,uEAAuE;AACzE;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,uCAAuC;EACvC,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,iCAAiC;EACjC,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,0CAA0C;AAC5C","sourcesContent":[".SettingsGrid {\n  --checkbox-color: var(--theme-color-grey-c1);\n  --checkbox-disabled-icon-color: var(--checkbox-color);\n  --checkbox-icon-color: var(--theme-color-grey-93);\n\n  align-items: center;\n  display: grid;\n  grid-template-columns: 2fr repeat(var(--settings-grid-columns, 1), 1fr);\n}\n\n.SettingsGrid > form {\n  display: contents;\n}\n\n.SettingsGrid-head {\n  font-weight: bold;\n}\n\n.SettingsGrid-head > div {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  max-width: 100%;\n}\n\n.SettingsGrid-row {\n  display: contents;\n}\n\n.SettingsGrid-cell {\n  padding: 0.5em;\n  justify-self: center;\n}\n\n.SettingsGrid-cell:first-child {\n  padding-left: 0;\n  justify-self: left;\n}\n\n.SettingsGrid-checkbox {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 1.5em;\n  height: 1.5em;\n  border: 1px solid var(--checkbox-color);\n  border-radius: 0;\n  margin: 0 auto;\n}\n\n.SettingsGrid-checkbox svg {\n  display: none;\n  color: var(--checkbox-icon-color);\n  font-size: 0.95em;\n}\n\n.SettingsGrid-checkbox--checked svg {\n  display: block;\n}\n\n.SettingsGrid-checkbox--disabled svg {\n  color: var(--checkbox-disabled-icon-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SettingsGrid": `vc6P2SyrlbIarUgp63cF`,
	"SettingsGrid-head": `OrVJZEP_TgVl8jQb8cgL`,
	"SettingsGrid-row": `zcVUHXNkV63EAetIHEuT`,
	"SettingsGrid-cell": `_ftXTRoimzjDloUIvbGg`,
	"SettingsGrid-checkbox": `zrTaV4oNgjBRWjfOf2x6`,
	"SettingsGrid-checkbox--checked": `_Bbq6xi1QMNeuj8Gpnq3`,
	"SettingsGrid-checkbox--disabled": `LQkO3plfSW2n6VUq75Z4`
};
export default ___CSS_LOADER_EXPORT___;
