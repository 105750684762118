import { useReducer } from 'react';
import { SubscribedPackage } from '@cv/portal-cps-lib/subscription/subscription-management/models/package-subscription';

type Cancellation = {
  selectedPackages: SubscribedPackage[];
};

enum CancelAction {
  UPDATE_PACKAGE = 'UPDATE_PACKAGE',
}

const initialState: Cancellation = {
  selectedPackages: [],
};

type cancellationReducer = (
  state: Cancellation,
  action: { type: CancelAction; payload: SubscribedPackage },
) => Cancellation;

const cancelReducer: cancellationReducer = (state = initialState, action) => {
  switch (action.type) {
    case CancelAction.UPDATE_PACKAGE:
      const isAlreadySelected = state.selectedPackages.some(
        (pkgItem) => pkgItem.subscriptionPackageId === action.payload.subscriptionPackageId,
      );

      let newSelectedPackages = [];

      if (isAlreadySelected) {
        newSelectedPackages = state.selectedPackages.filter(
          (pkgItem) => pkgItem.subscriptionPackageId !== action.payload.subscriptionPackageId,
        );
      } else {
        newSelectedPackages = [...state.selectedPackages, action.payload];
      }

      return {
        selectedPackages: newSelectedPackages,
      };
    default:
      return state;
  }
};

const useCancellationReducer = () => {
  const [state, dispatch] = useReducer(cancelReducer, initialState);

  const updatePackage = (selectedPkg: SubscribedPackage) =>
    dispatch({ type: CancelAction.UPDATE_PACKAGE, payload: selectedPkg });

  return {
    state,
    updatePackage,
  };
};

export default useCancellationReducer;
