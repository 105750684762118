import { DiscountInfo, EligiblePackageInfo, SubscribedPackageInfo, VariantInfo } from '@manageSubscription/Types';
import { DiscountType, OfferType, SalesChannel } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import {
  getHighestPaidSubscription,
  hasSubscribedPackage,
  isPackageMatch,
  isTermMatch,
  isTrialPackage,
} from '@manageSubscription';
import { cloneDeep, partition } from 'lodash';

export const getEligiblePromoPackages = (ePackages: EligiblePackageInfo[], promoCriteria: string) => {
  const [pkgTier, term, termUnit = 'MONTHS', pkgCount] = promoCriteria.split('_');
  const expectedPkgTier = Number(pkgTier);
  const expectedTerm = Number(term);
  const promoPackages: EligiblePackageInfo[] = [];
  ePackages.forEach((pkg) => {
    if (!isPackageMatch(pkg, expectedPkgTier)) return;
    pkg.variants.forEach((variant) => {
      const discounts = getDiscounts(variant.discounts, [OfferType.Promotional]);
      if (
        !discounts?.length ||
        !isTermMatch(variant, termUnit, expectedTerm) ||
        promoPackages.length >= Number(pkgCount)
      ) {
        return;
      }
      const newVariant = cloneDeep(variant);
      newVariant.discounts = discounts;
      newVariant.listPrice = determineListPrice(variant);
      promoPackages.push({ ...pkg, variant: newVariant });
    });
  });
  return promoPackages;
};

export const getPackagesMatchingOfferType = (
  ePackages: EligiblePackageInfo[],
  promoCriteria: string,
  offerTypes: OfferType[],
) => {
  const [pkgTier, , , pkgCount] = promoCriteria.split('_');
  const expectedPkgTier = Number(pkgTier);
  const promoPackages: EligiblePackageInfo[] = [];
  ePackages.forEach((pkg) => {
    if (!isPackageMatch(pkg, expectedPkgTier)) {
      return;
    } else {
      pkg.variants.forEach((variant) => {
        const discounts = getDiscounts(variant.discounts, offerTypes);
        if (!discounts?.length || promoPackages.length >= Number(pkgCount)) {
          return;
        }
        const newVariant = cloneDeep(variant);
        newVariant.discounts = discounts;
        newVariant.listPrice = determineListPrice(variant);
        promoPackages.push({ ...pkg, variant: newVariant });
      });
    }
  });
  return promoPackages;
};

export const findPromoSubscription = (pkg: SubscribedPackageInfo) => {
  if (!pkg?.variant) {
    return null;
  }
  return pkg.variant.discounts?.find((d) => d.offerType === OfferType.Promotional);
};

export const getPromoCodePackage = (sPackages: SubscribedPackageInfo[], promoCode: string) => {
  const promoCodePackages = getPromoCodePackages(sPackages, promoCode);
  if (!promoCodePackages?.length) {
    return { variant: null, discount: null, totalDiscountAmount: 0.0 };
  }
  const totalDiscountAmount = promoCodePackages.reduce((total, pkg) => total + Number(pkg.discountAmount), 0);
  const { packageName, variant } = promoCodePackages[0];
  return { packageName, variant, discount: variant.discounts[0], totalDiscountAmount };
};

export const noPromosPackagesAvailable = (
  eligiblePromoPackages: EligiblePackageInfo[],
  subscribedPackages: SubscribedPackageInfo[],
  eligiblePackages: EligiblePackageInfo[],
  salesChannel: SalesChannel,
) => {
  return (
    (eligiblePromoPackages?.length == 0 && salesChannel === SalesChannel.Rapid_registration) ||
    hasSubscribedPackage(eligiblePackages, getHighestPaidSubscription(subscribedPackages))
  );
};

export const getPromoCodePackages = (sPackages: SubscribedPackageInfo[], promoCode: string) => {
  if (!promoCode) {
    return [];
  }
  return sPackages?.filter((sPkg) => {
    const discounts = getPromoCodeDiscounts(sPkg.variant, promoCode);
    if (!discounts?.length) {
      return false;
    }
    sPkg.variant.discounts = discounts;
    sPkg.discountAmount = getTotalDiscount(discounts, sPkg.amountWithoutTax);
    return sPkg;
  });
};

export const getPromoCodeDiscounts = (variant: VariantInfo, promoCode: string) => {
  if (!variant || isTrialPackage(variant)) {
    return [];
  }
  return variant.discounts?.filter((d) => d.offerType === OfferType.Promo_code && d.promoCode === promoCode);
};

export const getTotalDiscount = (discounts: DiscountInfo[], actualPrice: number) => {
  return discounts?.reduce((total, { discountType, amount }) => {
    const discountAmt = discountType === DiscountType.Percentage ? ((amount / 100) * actualPrice).toFixed(2) : amount;
    return total + Number(discountAmt);
  }, 0);
};

export const getActualPrice = (discounts: DiscountInfo[], listPrice = 0.0) => {
  const discountsOtherThanPromoCode = discounts?.filter((d) => d.offerType !== OfferType.Promo_code);
  const totalDiscount = getTotalDiscount(discountsOtherThanPromoCode, listPrice);
  return listPrice - totalDiscount;
};

export const getPromoDiscountInfo = (discounts: DiscountInfo[], listPrice = 0.0) => {
  const [promoDiscounts, otherDiscounts] = partition(discounts, (d) => d.offerType === OfferType.Promotional);
  const [promoDiscount] = promoDiscounts;
  const regularPrice = listPrice - getTotalDiscount(otherDiscounts, listPrice);
  return { promoDiscount, regularPrice };
};

export const buildOfferDetailKeys = (variant: VariantInfo, discount: DiscountInfo, packageName: string) => {
  if (!variant || !discount) {
    return null;
  }
  const { initialTerm, initialTermUnit } = variant;
  const { amount, type, discountType, offerType: offer } = discount;

  const dType = type || discountType;
  const offerType = dType === DiscountType.Percentage ? `${Number(amount).toFixed(0)}-${dType}` : dType;

  return [
    `${offerType}-${initialTerm}${initialTermUnit}-${Number(amount).toFixed(2)}-${packageName}`.toLowerCase(),
    `${offerType}-${initialTerm}${initialTermUnit}`.toLowerCase(),
    `${offerType}-${offer}`.toLowerCase(),
  ];
};

export const isPromoPackage = (variant: VariantInfo) => {
  if (!variant) return false;
  return getDiscounts(variant.discounts, [OfferType.Promotional, OfferType.Promo_code]).length > 0;
};

export const getDiscounts = (discounts, offerTypes: OfferType[]) =>
  discounts?.filter((d) => offerTypes.includes(d.offerType));

export const determineListPrice = ({ discounts, listPrice }: VariantInfo) => {
  const internalDiscounts = discounts?.filter(
    (d) => d.offerType === OfferType.PromotionalClassAVariableTerm || d.offerType === OfferType.Base_discount,
  );
  return getActualPrice(internalDiscounts, listPrice);
};
