import React from 'react';
import { SubscribedPackageInfo } from '../../Types';
import { Content, LineBreak, LineSeparator } from '../../../styled-components/globalStyles';
import { DateContent, PackageInfo, Title } from './styles';
import { findPromoSubscription } from '../../utils';
import { formatDateByLocale } from '@cv/portal-common-lib';
import { PackagePrice } from '../Price/PackagePrice';
import { ComponentRoutes } from '../../../Router/types';
import { OfferDetails } from '../../Discount';
import {
  CommonWebContent,
  ContentfulAssets,
  DiscountWebContent,
  PackagesWebContent,
  SubscriptionProps,
} from '../../Subscription';
import TrialPackageTerm from '@manageSubscription/Packages/DiscountPackage/TrialDiscount/TrialPackageTerm';

export interface SubscribedPackageProps {
  subscribedPackage: SubscribedPackageInfo;
  expireRenewText: SubscriptionProps['expireRenewText'];
  commonWebContent: CommonWebContent;
  packagesWebContent: PackagesWebContent;
  customerCareCancelPackageNumber: string;
  discountWebContent: DiscountWebContent;
  location: string;
  assets: ContentfulAssets;
}

export const SubscribedPackage = ({
  subscribedPackage,
  expireRenewText,
  commonWebContent: { okButtonLabel, currencyLabel, shouldIncludeTax },
  packagesWebContent: {
    packagePrefixLabel,
    subscribedPackagesWebContent: { startsLabel, cancelEffectiveLabel, renewLabel, expiresLabel },
    eligiblePackagesWebContent: { perTermsLabel },
  },
  location,
  discountWebContent,
  assets,
  customerCareCancelPackageNumber,
}: SubscribedPackageProps) => {
  const {
    packageName,
    marketingName,
    active,
    amountWithoutTax,
    autoRenew,
    termEndDate,
    termServiceEndDate,
    variant: {
      startDate,
      endDate,
      cancelEffectiveDate,
      taxTotal,
      renewable,
      termServiceEndDate: variantTermServiceEndDate,
    },
  } = subscribedPackage;
  const promoDiscount = findPromoSubscription(subscribedPackage);
  const displayMarketingName = packagePrefixLabel ? `${packagePrefixLabel} ${marketingName}` : marketingName;

  return (
    <div data-testid="subscribed-package-component">
      <PackageInfo>
        <Title>{displayMarketingName}</Title>
        {location !== ComponentRoutes.managePaidPackages && (
          <Title>
            <PackagePrice
              currencyLabel={currencyLabel}
              shouldIncludeTax={shouldIncludeTax}
              perTermsLabel={perTermsLabel}
              amountWithoutTax={amountWithoutTax}
              taxTotal={taxTotal}
            />
          </Title>
        )}
      </PackageInfo>
      <div>
        <Content>
          <TrialPackageTerm startDate={startDate} endDate={endDate} isAddTrialLabel={false} />
        </Content>
        {(location === ComponentRoutes.reviewOrder || location === ComponentRoutes.payment) && (
          <Content>
            <LineBreak />
            {active ? (
              <>
                {startsLabel} {formatDateByLocale(startDate)}
              </>
            ) : (
              <>
                {cancelEffectiveLabel} {formatDateByLocale(cancelEffectiveDate)}
              </>
            )}
          </Content>
        )}
        {active && (
          <DateContent>
            {expireRenewText({
              autoRenew: !!(autoRenew || renewable),
              termEndDate: termEndDate || endDate,
              termServiceEndDate: termServiceEndDate || variantTermServiceEndDate,
              renewLabel,
              expiresLabel,
            })}
          </DateContent>
        )}
        {location !== ComponentRoutes.managePaidPackages && promoDiscount && (
          <OfferDetails
            assets={assets}
            packageName={packageName}
            variant={subscribedPackage.variant}
            discount={promoDiscount}
            okButtonLabel={okButtonLabel}
            discountWebContent={discountWebContent}
            customerCareCancelPackageNumber={customerCareCancelPackageNumber}
          />
        )}
      </div>
      {location !== ComponentRoutes.managePaidPackages && <LineSeparator />}
    </div>
  );
};
