import React from 'react';
import clsx from 'clsx';
import ContentRenderer from '@components/ContentRenderer';
import config from '@config/config';
import styles from './Footer.module.css';

export type FooterContent = {
  componentType: string;
  name: string;
  content: FooterContent[];
};

type Props = Pick<FooterContent, 'content'>;

function Footer({ content }: Props): JSX.Element {
  const oemClassName = config.getOemName().toLowerCase();
  return (
    <footer className={clsx(styles['Footer-container'], styles[oemClassName])}>
      <div className={styles['Footer-wrapper']}>
        <ContentRenderer name="footer" content={content} />
      </div>
    </footer>
  );
}

export default Footer;
