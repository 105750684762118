// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HPcEFauZ7JTqn_pcZOyw {
  margin-top: 10px;
}
.HPcEFauZ7JTqn_pcZOyw p {
  font-size: var(--theme-paragraph-font-size);
}

.HPcEFauZ7JTqn_pcZOyw button {
  padding: 0;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  color: var(--theme-color-primary);
  font-size: inherit;
  cursor: pointer;
}

.HPcEFauZ7JTqn_pcZOyw button:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/components/AgreementLink/AgreementLink.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,2CAA2C;AAC7C;;AAEA;EACE,UAAU;EACV,SAAS;EACT,gBAAgB;EAChB,6BAA6B;EAC7B,iCAAiC;EACjC,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".AgreementLinkContainer {\n  margin-top: 10px;\n}\n.AgreementLinkContainer p {\n  font-size: var(--theme-paragraph-font-size);\n}\n\n.AgreementLinkContainer button {\n  padding: 0;\n  border: 0;\n  border-radius: 0;\n  background-color: transparent;\n  color: var(--theme-color-primary);\n  font-size: inherit;\n  cursor: pointer;\n}\n\n.AgreementLinkContainer button:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AgreementLinkContainer": `HPcEFauZ7JTqn_pcZOyw`
};
export default ___CSS_LOADER_EXPORT___;
