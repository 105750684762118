import React from 'react';
import { ContentfulAssets } from '@app/components-lib';
import { ContentCenter, Content, Heading, IconSection, LineBreak } from '@styled-components/globalStyles';
import Image from '@lib-components/Image';
import { Header } from './styles';

type MessageType = 'success' | 'warning' | 'error';

interface MessageProps {
  type: MessageType;
  header: string;
  subheader?: string;
  body: string;
  assets?: ContentfulAssets;
}

const Message = ({ type, header, subheader, body, assets }: MessageProps) => {
  const getIconName = (type: MessageType) => {
    switch (type) {
      case 'success':
        return 'CheckCircleGreen';
      case 'warning':
        return 'Warning';
      case 'error':
        return 'Error';
      default:
        return '';
    }
  };

  const getFormattedBody = (text: string) => {
    return text?.split('\n').map((innerText, index) => (
      <React.Fragment key={index}>
        {innerText}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <ContentCenter>
      {assets && (
        <IconSection>
          <Image assets={assets} name={getIconName(type)} size="45px" />
        </IconSection>
      )}
      <Header>{header}</Header>
      {subheader && <Content>{subheader}</Content>}
      <Content>{getFormattedBody(body)}</Content>
    </ContentCenter>
  );
};

export default Message;
