import { styled } from '@mui/material';
import Button from '../Button';
import { breakpoint } from '../../styled-components/globalStyles';

export const TabsPanel = styled('div')`
  display: flex;
  margin: 0;

  @media only screen and ${breakpoint.device.md} {
    justify-content: flex-start;
  }
`;

export const StyledTab = styled(Button)`
  background: lightgrey;
  border: none;
  border-top: 2px solid transparent;
  color: black;
  font-size: 0.875rem;
  margin: 0;
  padding: 0.5rem 1.3rem;
  text-transform: none;
  ${({ variant, theme }) =>
    variant === 'contained' && {
      background: 'none',
      color: theme.palette.primary.main,
      borderTopColor: theme.palette.primary.main,
    }};

  @media only screen and ${breakpoint.device.md} {
    width: 168px;
  }
`;

export const TabsLine = styled('div')`
  border-bottom: 1px solid darkgrey;
  display: none;

  @media only screen and ${breakpoint.device.md} {
    display: block;
    width: 100%;
  }
`;
