import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import Markdown from '@components/Markdown';

import { FormikInputField } from '@components/FormikFields';
import Widget from '@components/Widget/Widget';
import InputRadio from '@components/InputRadio';
import { Button } from '@components-lib';
import { formatAnalyticsEventName } from '@cv/portal-common-lib';
import clsx from 'clsx';
import styles from './SecurityAuthentication.module.css';
import { CommonFormAnalyticsWrapper } from '@app/components-lib/components/Analytics';

export type Contacts = {
  primaryPhone?: string;
  secondaryPhone?: string;
  email?: string;
};

type SecurityAuthenticationFormProps = {
  onSubmit: (channel: string) => Promise<void>;
  labels: { [index: string]: { primary?: string; analyticsEventName?: string } };
  contacts: Contacts;
  showFormWithEmptyValues: boolean;
};

enum channelTypes {
  sms = 'sms',
  smsSecondary = 'sms-secondary',
  email = 'email',
}
interface FormProps {
  channel: string;
}

const initialValues = {
  channel: '',
};

const SecurityAuthenticationForm = ({
  onSubmit,
  labels,
  contacts,
  showFormWithEmptyValues,
}: SecurityAuthenticationFormProps) => {
  const {
    otpSendTitle,
    otpSendBody,
    otpSendEmail,
    otpSendEmailDescription,
    otpSendSms,
    otpSendSmsDescription,
    otpSendSmsTermsAndPolicy,
    submitButton,
  } = labels;

  const { primaryPhone = '', secondaryPhone = '', email = '' } = contacts;

  const OTP_SEND_VALUES = [
    (primaryPhone || showFormWithEmptyValues) && {
      label: otpSendSms.primary,
      value: channelTypes.sms,
      description: `${otpSendSmsDescription.primary} ${primaryPhone}`.trimEnd(),
      analyticsEventName: otpSendSms.analyticsEventName,
    },
    secondaryPhone && {
      label: otpSendSms.primary,
      value: channelTypes.smsSecondary,
      description: `${otpSendSmsDescription.primary} ${secondaryPhone}`.trimEnd(),
      analyticsEventName: otpSendSms.analyticsEventName,
    },
    (email || showFormWithEmptyValues) && {
      label: otpSendEmail.primary,
      value: channelTypes.email,
      description: `${otpSendEmailDescription.primary} ${email}`.trimEnd(),
      analyticsEventName: otpSendEmail.analyticsEventName,
    },
  ].filter(Boolean);

  const handleSubmit = ({ channel }: { channel: string }) => {
    onSubmit(channel);
  };
  const showSmsTermsAndPolicy = primaryPhone || secondaryPhone;

  const styleSmsTAP = ({ values }: FormikProps<FormProps>) =>
    clsx(!(values.channel === channelTypes.sms || values.channel === channelTypes.smsSecondary) && styles.Opacity70);

  return (
    <Widget title={otpSendTitle.primary} titleAlignment="center">
      <div className={styles['SecurityForm']}>
        <p>{otpSendBody.primary}</p>
        <CommonFormAnalyticsWrapper name="send otp form">
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {(props: FormikProps<FormProps>) => (
              <Form onSubmit={props.handleSubmit}>
                <FormikInputField
                  name="channel"
                  InputComponent={InputRadio}
                  onChange={() => {}}
                  options={OTP_SEND_VALUES}
                />
                {showSmsTermsAndPolicy && (
                  <div className={styles['termsAndPolicy']}>
                    {otpSendSmsTermsAndPolicy?.primary && (
                      <Markdown className={styleSmsTAP(props)}>{otpSendSmsTermsAndPolicy?.primary}</Markdown>
                    )}
                  </div>
                )}
                <div className={styles['buttonWrapper']}>
                  <Button
                    disabled={!props.values.channel}
                    className={styles['submitButton']}
                    type="submit"
                    role="button"
                    variant="contained"
                    data-analyticseventname={formatAnalyticsEventName(submitButton.analyticsEventName)}
                  >
                    {submitButton.primary}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </CommonFormAnalyticsWrapper>
      </div>
    </Widget>
  );
};

export default SecurityAuthenticationForm;
