import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { FaCheck } from 'react-icons/fa';
import RoundToggleButton from '@components/RoundToggleButton';
import ControlLabel from '@components/ControlLabel';

import styles from './SettingsGrid.module.css';

export type SettingsGridRowProps = {
  fields: Record<string, string>;
  data: Record<string, string | boolean>;
  isHead?: boolean;
  mode?: 'edit' | 'view';
  indexing?: string;
  onChange?: (id: string, name: string, value: boolean) => void;
};

function SettingsGridRow({
  data,
  fields,
  isHead = false,
  mode = 'view',
  indexing = 'id',
  onChange,
}: SettingsGridRowProps) {
  const renderCheckbox = (id: string, name: string, value: boolean): ReactNode => {
    return (
      <ControlLabel
        label=""
        control={
          <RoundToggleButton
            name={name}
            checked={value}
            onToggle={(checked) => onChange?.(id, name, checked)}
            disabled={mode !== 'edit'}
            classes={{
              input: styles['SettingsGrid-checkbox'],
              selected: styles['SettingsGrid-checkbox--checked'],
              disabled: styles['SettingsGrid-checkbox--disabled'],
            }}
          >
            <FaCheck />
          </RoundToggleButton>
        }
      />
    );
  };

  return (
    <div className={clsx(styles['SettingsGrid-row'], { [styles['SettingsGrid-head']]: isHead })}>
      {Object.keys(fields).map((name) => (
        <div className={styles['SettingsGrid-cell']} key={name}>
          {typeof data[name] === 'boolean' ? (
            renderCheckbox(String(data[indexing]), name, data[name] as boolean)
          ) : (
            <span title={data[name]}>{data[name]}</span>
          )}
        </div>
      ))}
    </div>
  );
}

export default SettingsGridRow;
