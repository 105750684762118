import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useField } from 'formik';
import { RootState } from '@app/reducers';
import useMarketingPreference from '@api/queries/useMarketingPreference';
import { get } from '@components/GlobalPreferences/GlobalPreferences';
import Field from '@components/Field';
import InputCheckbox from '@components/InputCheckbox';

export type OptSmsProps = {
  topLabel: string;
  name: string;
  isSubmitting: boolean;
};

type PreferenceAccountData = { primaryPhone: { number: string }; email: string };

const OptSms = ({ name, topLabel, isSubmitting }: OptSmsProps) => {
  const {
    primaryPhone: { number: smsNumber },
    email,
  } = useSelector(
    ({ accountReducer }: RootState): PreferenceAccountData =>
      accountReducer.account?.data || { primaryPhone: { number: '' }, email },
  );

  const { data, readError, createPreference, updatePreference } = useMarketingPreference({ smsNumber });

  const isChecked = useMemo(() => !!data?.preferences?.some(({ optIn }) => !!optIn), [data]);

  const [, { initialValue }, { setValue: setFormValue }] = useField<boolean | null | undefined>(name);
  const [checked, setChecked] = useState<boolean | undefined>(undefined);

  const onChange = () => {
    if (typeof checked !== 'undefined') {
      const newValue = !checked;

      setFormValue(newValue === isChecked ? initialValue : newValue);
      setChecked(newValue);
    } else {
      setFormValue(!isChecked);
      setChecked(!isChecked);
    }
  };

  useEffect(() => {
    if (isSubmitting && !readError) {
      const hasPreference = data?.preferences?.length;

      if (hasPreference && typeof checked !== 'undefined' && checked !== isChecked) {
        updatePreference({ optIn: Boolean(checked), phone: smsNumber, email });
      } else if (!hasPreference && checked) {
        createPreference({ optIn: checked, phone: smsNumber, email });
      }
    }
  }, [
    isSubmitting,
    readError,
    data?.preferences?.length,
    isChecked,
    checked,
    smsNumber,
    email,
    updatePreference,
    createPreference,
  ]);

  if (readError) {
    return null;
  }

  const rightLabel = get('smsCheckboxRightLabel') || '';

  return (
    <Field label={topLabel}>
      <InputCheckbox
        name={name}
        checked={typeof checked === 'undefined' ? isChecked : checked}
        onChange={onChange}
        placeholder={rightLabel}
      />
    </Field>
  );
};

export default OptSms;
