import React from 'react';
import clsx from 'clsx';
import { SubscribedPackage } from '@cv/portal-cps-lib/subscription/subscription-management/models/package-subscription';
import { useCancelLabels } from '../../CancelLabelsContext';
import Button from '@lib-components/Button';

import styles from '../../Cancellation.module.css';

type AllBenefitsProps = {
  selectedPackages: SubscribedPackage[];
  onContinue: () => void;
  onKeep: () => void;
};

const AllBenefits = ({ selectedPackages, onContinue, onKeep }: AllBenefitsProps) => {
  const { benefits, continueButton, keepButton } = useCancelLabels();

  return (
    <>
      <h3 className={styles['cancel-layout-header']}>{benefits}</h3>
      {selectedPackages.map((pkg) => (
        <span key={pkg.subscriptionPackageId}>{pkg.marketingName}</span>
      ))}
      <div className={styles['buttons-continue-keep-container']}>
        <Button
          className={clsx(styles['bottom-button'], styles['bottom-continue'])}
          onClick={onContinue}
          disabled={true}
        >
          {continueButton}
        </Button>
        <Button className={styles['bottom-button']} variant="outlined" onClick={onKeep}>
          {keepButton}
        </Button>
      </div>
    </>
  );
};

export default AllBenefits;
