import set from 'lodash/set';
import config from '@config/config';
import { getUserInfoByID, getUserInfoByQuery } from '@cv/portal-idm-lib/user/user-Info';
import { Environment } from '@cv/portal-common-lib/ajax/constants';
import { UserByIdRequest, UserInfoByQueryRequest } from '@cv/portal-idm-lib/user/user-Info/models';
import { userInfoUrls } from '@cv/portal-idm-lib/user/user-Info/enums';
import { QueryFilterKeys } from '@cv/portal-idm-lib/models';
import { formatUserData } from '../formatters/';
import { UserUpdateRequest, UserUpdatePayload } from '@cv/portal-idm-lib/user/user-update-profile/models';
import { UserUpdateProfile } from '@cv/portal-idm-lib/user/user-update-profile';
import queryClient from '@api/queries/queryClient';

const createQueryFilter = (data = {}) => {
  return Object.entries(data)
    .map(([key, value]) => `${key} eq "${value}"`)
    .join(' and ');
};

type GetAccountDetailsArgs = {
  userId: string;
  accessToken: string;
  locale: string;
};

type QueryAccountArgs = {
  filter: QueryFilterKeys;
  fields?: string[];
  accessToken: string;
  locale: string;
  tenantId: string;
};

type UpdateAccountDetails = GetAccountDetailsArgs & {
  data: UserUpdatePayload[];
};

export default class AccountDetailsService {
  private environment: Environment;

  constructor(environment = Environment.LOCAL) {
    this.environment = environment;
  }

  updateUserInfo({ userId, accessToken, locale, data }: UpdateAccountDetails) {
    const request: UserUpdateRequest = {
      pathParams: {
        _id: userId,
      },
      environment: this.environment,
      headers: {
        Authorization: accessToken,
        'Accept-Language': locale,
        'If-Match': '*',
      },
      data,
    };

    return UserUpdateProfile(request).then((response) => ({ ...response, data: formatUserData(response.data) }));
  }

  async getAccountDetails({ userId, accessToken, locale }: GetAccountDetailsArgs) {
    const request: UserByIdRequest = {
      pathParams: {
        _id: userId,
      },
      environment: this.environment,
      headers: {
        Authorization: accessToken,
      },
    };

    const payload = set(request, 'headers.Accept-Language', locale);
    return queryClient
      .fetchQuery(['getAccountDetails', userId], () => getUserInfoByID(payload))
      .then((response) => {
        if (response.data) {
          return { data: formatUserData(response.data) };
        }
        return response;
      });
  }

  queryAccount({ filter, fields = [], accessToken, locale, tenantId }: QueryAccountArgs) {
    const request: UserInfoByQueryRequest = {
      queryParams: {
        _queryFilter: filter,
        _fields: fields.concat(['*']).join(','),
      },
      environment: this.environment,
      headers: {
        Authorization: accessToken,
      },
    };

    const idmApiProxyBaseUrl = config.get<string>('IDM_API_PROXY_BASE_URL');
    if (idmApiProxyBaseUrl) {
      const parseQueryFilter = createQueryFilter(request.queryParams._queryFilter);
      const { getUserInfoByQuery } = userInfoUrls;

      const url = new URL(getUserInfoByQuery, idmApiProxyBaseUrl);
      url.searchParams.set('_queryFilter', parseQueryFilter);
      request.url = url.toString();

      request.headers['CV-Tenant-Id'] = tenantId;
    }

    const payload = set(request, 'headers.Accept-Language', locale);
    return getUserInfoByQuery(payload);
  }
}
