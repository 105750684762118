import 'react-phone-number-input/style.css';

import { isSupportedCountry } from 'react-phone-number-input';
import React, { useRef } from 'react';
import { getIn, useField } from 'formik';
import { PhoneNumberInput } from '@components-lib';
import { CountryCode } from 'libphonenumber-js/core';

import Field from '@components/Field';
import nextId from '@utils/next-id';
import { useSelector } from 'react-redux';

type FormikPhoneFieldProps = {
  name: string;
  fieldValue?: string;
  placeholder?: string;
  helpCallout?: React.ReactNode;
  form: {
    errors: Record<string, string>;
    handleBlur: (e: any) => void;
    setFieldValue: (name: string, value: string) => void;
    touched: Record<string, boolean>;
    values?: any;
  };
  label?: string;
  id?: string;
  onChange?: (value: string) => void;
  fieldPhoneCountry?: CountryCode;
  classes?: {
    field?: {
      label?: string;
      wrapper?: string;
      helpText?: string;
      error?: string;
    };
    input?: string;
  };
};

const FormikPhoneField = (props: FormikPhoneFieldProps) => {
  const {
    id = nextId(),
    name,
    fieldValue,
    placeholder,
    helpCallout,
    form: { errors, handleBlur, setFieldValue, touched },
    label,
    onChange,
    fieldPhoneCountry,
    classes,
  } = props;
  const [field] = useField<string>({ name });
  const value = fieldValue || field.value;
  const input = useRef<any>(null);
  const locale: string = useSelector(({ settingsReducer }) => settingsReducer.locale);
  const phoneError = getIn(errors, name);
  const error = phoneError || '';
  const isError = !!error;
  const isCurrentElementActive = input.current === document.activeElement;
  const errorMessage = isCurrentElementActive ? '' : error;
  const countryFormat = locale.split('-')[1] || fieldPhoneCountry;
  const correctCountry = !!countryFormat && isSupportedCountry(countryFormat as CountryCode) ? countryFormat : 'US';

  const onValueChange = () => {
    const phoneNumber = input.current?.value?.split(' ').join('') || ''; // if empty, use "" instead of undefined

    setFieldValue(name, phoneNumber);
    if (onChange && typeof onChange === 'function') {
      onChange(phoneNumber);
    }
  };

  return (
    <Field
      label={label}
      helpCallout={helpCallout}
      error={isError}
      helpText={errorMessage}
      htmlFor={id}
      classes={classes?.field}
    >
      <PhoneNumberInput
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onValueChange}
        international
        defaultCountry={correctCountry as CountryCode}
        countries={['US', 'CA', 'MX']}
        onBlur={handleBlur}
        id={id}
        className={classes?.input}
        ref={input}
        aria-label={name}
        countrySelectProps={{ disabled: true }}
      />
    </Field>
  );
};

export default FormikPhoneField;
