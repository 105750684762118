import { useMemo } from 'react';
import { mapValues, isEqual, compact } from 'lodash';
import { useSelector } from 'react-redux';

import { RootState } from '@app/reducers';
import config from '@config/config';
import { VehicleServiceDetails } from '@cv/portal-cps-lib/vehicle/vehicle-management/models';
import { CapableServiceDetails } from '@api';

const enum ComponentType {
  PortalFilter = 'portalFilter',
}

export type FrontendFilterProps = {
  oemName?: string;
  envName?: string;
  telematicsProgramId?: string;
  capableProducts?: string[];
  capableServices?: string[];
  carMake?: string;
};

export interface PortalFilterRule extends ContentfulObject {
  telematicsProgramIds?: string[];
  carMakes?: string[];
  capableProducts?: string[];
  capableServices?: string[];
}

export interface PortalFilter extends ContentfulObject {
  fallbackValue?: FilterableObject[];
  content: FilterableObject[];
  filterRules?: PortalFilterRule[];
}

export type ContentfulObject = {
  componentType?: string;
  tags?: string[];
  envTags?: string[];
};

export type FilterableObject = ContentfulObject | PortalFilter | null | undefined;
type FilterObject = FilterableObject | string | undefined;

const valueIsNotInTheList = (valuesList?: string[], value?: string | string[]) => {
  if (!valuesList?.length) {
    return false;
  }

  if (!value) {
    return true;
  }

  // if we have multiple values to compare
  if (Array.isArray(value)) {
    return !valuesList.some((member) =>
      value.some((valueMember) => member.toLowerCase() === valueMember.toLowerCase()),
    );
  }
  // excluded if not explicitly listed, matching case-insensitively
  return !valuesList.some((member) => member.toLowerCase() === value.toLowerCase());
};

const filterByTag = (obj?: ContentfulObject, props?: FrontendFilterProps) => {
  return valueIsNotInTheList(obj?.tags, props?.oemName);
};

const filterByEnv = (obj?: ContentfulObject, props?: FrontendFilterProps) => {
  return valueIsNotInTheList(obj?.envTags, props?.envName);
};

const filterByTelematics = (obj?: PortalFilterRule, props?: FrontendFilterProps) => {
  return valueIsNotInTheList(obj?.telematicsProgramIds, props?.telematicsProgramId);
};

const filterByCarMake = (obj?: PortalFilterRule, props?: FrontendFilterProps) => {
  return valueIsNotInTheList(obj?.carMakes, props?.carMake);
};

const filterByCapableProducts = (obj?: PortalFilterRule, props?: FrontendFilterProps) => {
  return valueIsNotInTheList(obj?.capableProducts, props?.capableProducts);
};

const filterByCapableServices = (obj?: PortalFilterRule, props?: FrontendFilterProps) => {
  return valueIsNotInTheList(obj?.capableServices, props?.capableServices);
};

const globalFilterRules = [filterByTag, filterByEnv];
const portalFilterRules = [filterByTelematics, filterByCarMake, filterByCapableProducts, filterByCapableServices];

const filterObject = (currentObject: FilterableObject, props: FrontendFilterProps): FilterableObject | null => {
  const shouldBeFilteredByTagsOrEnv = globalFilterRules.some((filter) => filter(currentObject, props));
  if (shouldBeFilteredByTagsOrEnv) return null;

  if (currentObject?.componentType === ComponentType.PortalFilter) {
    const filterObject = currentObject as PortalFilter;
    //checking if at least one filterRule among all filter rules returns true
    const shouldBeFiltered = (filterObject?.filterRules || []).some((filterRule) =>
      portalFilterRules.some((filter) => filter(filterRule, props)),
    );
    if (shouldBeFiltered) return null;
  }

  return currentObject;
};

const processFilterContentAndFallback = (
  currentObject: FilterableObject,
): FilterableObject | FilterableObject[] | undefined => {
  if (currentObject?.componentType === ComponentType.PortalFilter) {
    const filterObject = currentObject as PortalFilter;
    const hasContent =
      filterObject.content.length && filterObject.content.some((c) => processFilterContentAndFallback(c));
    if (hasContent) {
      return filterObject.content;
    } else {
      return filterObject.fallbackValue;
    }
  }
  return currentObject;
};

const processContent = (
  obj: FilterObject,
  props: FrontendFilterProps,
  processor: (currentObject: FilterableObject, props: FrontendFilterProps) => FilterableObject | FilterableObject[],
): FilterObject | FilterObject[] => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return compact(obj.flatMap((o: FilterObject) => processContent(o, props, processor)));
  }

  const processedObject = processor(obj, props);

  if (!processedObject) {
    return null;
  }

  if (Array.isArray(processedObject)) {
    return compact(processedObject.flatMap((o: FilterObject) => processContent(o, props, processor)));
  }

  return mapValues(processedObject, (value: FilterObject) => processContent(value, props, processor)) as FilterObject;
};

const filterContent = (obj: FilterObject, props: FrontendFilterProps) => {
  const filteredContent = (processContent(obj, props, filterObject) || {}) as FilterObject;
  const processedContent = processContent(filteredContent, props, processFilterContentAndFallback);
  return processedContent || {};
};

// Useless hook to hide all needed information for filter content
// to keep all mess in one place

// WARNING!
// This hooks is used in ContentfulTagFilter which is the one of the TOP level
// components. Any change to state/redux here, trigger whole app to re-render.
// Please check that you are using data that is not changing link(in memory) all the time
// when you checking it (for example preferences from Redux they change based on this filter
// as preferences is filtered content).
export const useFilterContent = (pageContent: ContentfulObject) => {
  const selected = useSelector(
    ({ vehicleReducer, accountReducer }: RootState) => ({
      telematicsProgramId: vehicleReducer.vehicle?.telematicsProgramId,
      carMake: vehicleReducer.vehicle?.make,
      capableProducts: accountReducer.capableServices?.data?.map(
        (product: CapableServiceDetails) => product.productName,
      ),
      capableServices: accountReducer.capableServices?.data
        ?.map(({ services }) => services)
        .flat()
        .map((service: VehicleServiceDetails) => service.vehicleServiceName),
    }),
    isEqual,
  );

  return useMemo(() => {
    const { telematicsProgramId, carMake, capableProducts, capableServices } = selected;
    const isContentLoaded = Boolean(Object.keys(pageContent).length);
    if (!isContentLoaded) {
      // TODO: Display loading indicator. We can't use LoaderBackdrop here because it relies on a theme
      return null;
    }

    return filterContent(pageContent, {
      telematicsProgramId,
      carMake,
      capableProducts,
      capableServices,
      oemName: config.getTenantId(),
      envName: config.getEnvironmentEnum(),
    });
  }, [pageContent, selected]);
};
