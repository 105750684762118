import React, { useEffect, useState } from 'react';
import { CapableServicesResponse, useApi } from '@api';
import SubscriptionInformation, {
  SubscribedPackageWithServices,
  SubscriptionInformationProps,
} from './SubscriptionInformation';
import { useSelector } from 'react-redux';
import { RootState } from '@app/reducers';
import { mapPackagesWithServices } from '@utils/subscriptionUtils';
import Spinner from '@app/components-lib/components/Spinner';
import { useVehicleSelector } from '@redux/selectors/vehicle';

const SubscriptionInformationConnector = (props: SubscriptionInformationProps) => {
  const api = useApi();
  const userId = useSelector(({ userReducer }) => userReducer.userId);
  const selectedVehicle = useVehicleSelector();
  const accountSubscriptions = useSelector(({ userReducer }: RootState) => userReducer.subscribedServices);
  const [selectedVehiclePackages, setSelectedVehiclePackages] = useState([]);
  const [capableServices, setCapableServices] = useState<CapableServicesResponse['data'] | null>(null);
  const [data, setData] = useState<SubscribedPackageWithServices[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (selectedVehicle && accountSubscriptions?.length && userId) {
      const vehicleSubscription = accountSubscriptions.find(
        (packageItem: any) => packageItem.vehicleId === selectedVehicle.vehicleId,
      );
      setSelectedVehiclePackages(vehicleSubscription?.packages);

      const loadData = async () => {
        const services = await api.getCapableServices({
          vehicleId: selectedVehicle.vehicleId,
          userId,
        });
        setCapableServices(services.data);
      };
      if (selectedVehicle.vehicleId) {
        loadData();
      }
    }
  }, [selectedVehicle, accountSubscriptions, api, userId]);

  useEffect(() => {
    const packagesWithServices = mapPackagesWithServices(capableServices, selectedVehiclePackages);

    setData(packagesWithServices);
    setLoading(false);
  }, [capableServices, selectedVehiclePackages]);

  return loading ? <Spinner /> : <SubscriptionInformation {...props} data={data} />;
};

export default SubscriptionInformationConnector;
