import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { StyledTab, TabsLine, TabsPanel } from './styles';

interface TabsProps {
  children: ReactNode;
  active: number;
  onTabChanged?: (index: number) => void;
}

const Tab = ({ children, active, onTabChanged }: TabsProps) => {
  const [activeTab, setActiveTab] = useState(active);
  const [tabsData, setTabsData] = useState([]);

  useEffect(() => {
    let data = [];

    React.Children.forEach(children, (element: ReactElement<TabPaneProps>) => {
      if (!React.isValidElement(element)) return;

      const {
        props: { tabTitle, children },
      } = element;
      data.push({ tabTitle, children });
    });
    setTabsData(data);
    setActiveTab(active);
  }, [children]);

  const getActiveTab = (index: number) => {
    return index === activeTab ? 'contained' : 'outlined';
  };

  const handleTabClick = (index: number) => () => {
    setActiveTab(index);
    onTabChanged?.(index);
  };

  return (
    <div>
      <TabsPanel>
        {tabsData.map(({ tabTitle }, index) => (
          <StyledTab key={index} variant={getActiveTab(index)} onClick={handleTabClick(index)}>
            {tabTitle}
          </StyledTab>
        ))}
        <TabsLine />
      </TabsPanel>

      <div>{tabsData[activeTab] && tabsData[activeTab].children}</div>
    </div>
  );
};

interface TabPaneProps {
  tabTitle: string | number;
  children: ReactElement;
}

const TabPane: React.FC<TabPaneProps> = ({ children }) => {
  return <div>{children}</div>;
};

Tab.TabPane = TabPane;

export default Tab;
