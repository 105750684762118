import { isEqual } from 'lodash';
import React from 'react';
import { initialize } from '../../configs';
import { invalidateCache } from '../utils';
import Subscription, { ManageSubscriptionProps } from './Subscription';

export const ManageSubscription = React.memo(
  (props: ManageSubscriptionProps) => {
    initialize(props);

    return (
      // We need this key to re-mount the whole subscription component on vehicle switch
      // this allows us to re-initialize our flow completely from scratch with new state machine and data
      <Subscription key={props.subscriptionProps.vehicleDetails.vehicleId} {...props} />
    );
  },
  (prevProps, nextProps) => {
    const isSameVehicle =
      prevProps.subscriptionProps.vehicleDetails.vehicleId === nextProps.subscriptionProps.vehicleDetails.vehicleId;

    if (!isSameVehicle) {
      invalidateCache();
    }

    const isSameLocale = prevProps.subscriptionProps.locale === nextProps.subscriptionProps.locale;

    const isSameContent = isEqual(prevProps.content, nextProps.content);

    return isSameVehicle && isSameLocale && isSameContent;
  },
);
