import {
  AccountCreationService,
  AccountDetailsService,
  AccountLinkService,
  AccountUpdateService,
  AgreementService,
  AlertsService,
  AuthService,
  CurfewService,
  CalendarService,
  DestinationService,
  DoorLockUnlockService,
  EngineStartStopService,
  FolderService,
  GeofenceService,
  GetLinkService,
  HeadUnitsService,
  HornLightsService,
  JourneyService,
  MockService,
  NotificationPreferencesService,
  OTPCodeService,
  PasswordService,
  PaymentMethodsService,
  PinService,
  PoiService,
  RoleService,
  SecurityPinService,
  SecurityQuestionsService,
  StolenVehicleLocatorService,
  StoreService,
  SubscribedPackagesService,
  TelematicServices,
  TokenExchangeService,
  TokenLoginService,
  VehicleContactsService,
  VehicleContactUpdateService,
  VehicleDetailsService,
  VehicleLinkService,
  VehicleLocationService,
  VehicleStatusService,
  VinService,
} from './services';
import {
  AccountFormData,
  AddVinData,
  CapableServicesResponse,
  ContactFormData,
  PaymentMethodData,
  SubscribedServices,
} from './types/';
import MapDistance from '@customTypes/MapDistance';
import UserCalendar from '@customTypes/UserCalendar';
import { AddDestination, UpdateDestination } from '@cv/portal-rts-lib/v0/information/destinations/models';
import { CreateAgreementPayload, GetEffectiveAgreement } from '@cv/portal-cps-lib/agreements/agreement-service/models';
import { CreateAlertsRequest, DrivingBehaviorByVehicleId } from '@cv/portal-rts-lib/alerts/models';
import { CreateCurfewPayload, UpdateCurfewPayload } from '@cv/portal-rts-lib/curfews/models';
import { CreateGeofencePayload, UpdateGeofencePayload } from '@cv/portal-rts-lib/geofences/models';
import { OrderPayload, Product } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { ResetPinByKBAPayload, ResetPinPayload } from '@cv/portal-idm-lib/pin/models';
import { SearchVehiclePayload, VehicleServiceDetails } from '@cv/portal-cps-lib/vehicle/vehicle-management/models';
import {
  SetupPinAndSecurityQuestionsRequest,
  UpdateSecurityQuestionsByPinRequest,
} from '@cv/portal-idm-lib/security-questions-pin/models';
import { invoke, uniq } from 'lodash';

import { AddJourney } from '@cv/portal-rts-lib/v0/information/journey/models';
import ApiRequestPayload from '@app/types/ApiRequestPayload';
import { ContactTypes } from '@cv/portal-idm-lib/contact/enums';
import { DistanceUnit } from '@cv/portal-rts-lib/v0/enums';
import { DoorUnlockRequestPayload } from './services/DoorLockUnlockService';
import { EngineStartStopRequestPayload } from './services/EngineStartStopService';
import { HornLightsRequestPayload } from './services/HornLightsService';
import { IVehicle } from '@redux/actions';
import { Journey } from '@cv/portal-rts-lib/v0/information/journey';
import { PackageType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { QueryFilterKeys } from '@cv/portal-idm-lib/models';
import { UpdatePasswordPayload } from '@cv/portal-idm-lib/password/models';
import { UserCreatePayload } from '@cv/portal-idm-lib/user/user-create/models';
import { UserUpdatePayload } from '@cv/portal-idm-lib/user/user-update-profile/models';
import config from '@config/config';
import { preferencesData } from '@cv/portal-cps-lib/vehicle/vehicle-notification-service/models';
import SearchAddressService from './services/SearchAddressService';
import { convertToSend } from '@api/formatters/convertCalendar';
import isPackageToShow from '@utils/isPackageToShow';
import SubscriptionPackage from '@customTypes/SubscriptionPackage';
import {
  ReadPreferencePayload,
  PftPreferencePayload,
} from '@cv/portal-cps-lib/preferences/marketing-preference/models';
import MarketingPreferenceService, { ReadPayload, EditPayload } from './services/MarketingPreferenceService';
import urlSearchParams from '@utils/urlSearchParams';
import {
  EventDataBuilder,
  EventType,
  UserProfileUpdatedData,
  openAnalyticsBracket,
  sendAnalyticsEvent,
} from '@lib-components/Analytics';

const environmentEnum = config.getEnvironmentEnum();

export default class Api {
  mockService: MockService;
  authService: AuthService;
  accountDetailsService: AccountDetailsService;
  vehicleContactsService: VehicleContactsService;
  vehicleLocationService: VehicleLocationService;
  subscribedPackagesService: SubscribedPackagesService;
  accountUpdateService: AccountUpdateService;
  paymentMethodService: PaymentMethodsService;
  accountCreationService: AccountCreationService;
  accountLinkService: AccountLinkService;
  vehicleStatusService: VehicleStatusService;
  doorLockUnlockService: DoorLockUnlockService;
  hornLightsService: HornLightsService;
  engineStartStopService: EngineStartStopService;
  vehicleDetailsService: VehicleDetailsService;
  alertsService: AlertsService;
  curfewService: CurfewService;
  geofenceService: GeofenceService;
  vehicleContactUpdateService: VehicleContactUpdateService;
  pinService: PinService;
  securityQuestionsService: SecurityQuestionsService;
  passwordService: PasswordService;
  headUnitsService: HeadUnitsService;
  securityPinService: SecurityPinService;
  poiService: PoiService;
  folderService: FolderService;
  calendarService: CalendarService;
  destinationService: DestinationService;
  journeyService: JourneyService;
  vehicleLinkService: VehicleLinkService;
  agreementService: AgreementService;
  roleService: RoleService;
  notificationPreferencesService: NotificationPreferencesService;
  stolenVehicleLocatorService: StolenVehicleLocatorService;
  vinService: VinService;
  telematicServices: TelematicServices;
  tokenExchangeService: TokenExchangeService;
  otpCodeService: OTPCodeService;
  tokenLoginService: TokenLoginService;
  searchAddressService: SearchAddressService;
  getLinkService: GetLinkService;
  constructor(public storeService: StoreService) {
    const environmentEnum = config.getEnvironmentEnum();
    this.accountCreationService = new AccountCreationService(environmentEnum);
    this.accountDetailsService = new AccountDetailsService(environmentEnum);
    this.accountLinkService = new AccountLinkService(environmentEnum);
    this.accountUpdateService = new AccountUpdateService(environmentEnum);
    this.agreementService = new AgreementService(environmentEnum);
    this.alertsService = new AlertsService(environmentEnum);
    this.authService = new AuthService();
    this.calendarService = new CalendarService(environmentEnum);
    this.curfewService = new CurfewService(environmentEnum);
    this.destinationService = new DestinationService(environmentEnum);
    this.doorLockUnlockService = new DoorLockUnlockService(environmentEnum);
    this.engineStartStopService = new EngineStartStopService(environmentEnum);
    this.folderService = new FolderService(environmentEnum);
    this.geofenceService = new GeofenceService(environmentEnum);
    this.getLinkService = new GetLinkService();
    this.headUnitsService = new HeadUnitsService(environmentEnum);
    this.hornLightsService = new HornLightsService(environmentEnum);
    this.journeyService = new JourneyService(environmentEnum);
    this.mockService = new MockService();
    this.notificationPreferencesService = new NotificationPreferencesService(environmentEnum);
    this.otpCodeService = new OTPCodeService();
    this.passwordService = new PasswordService(environmentEnum);
    this.paymentMethodService = new PaymentMethodsService(environmentEnum);
    this.pinService = new PinService(environmentEnum);
    this.poiService = new PoiService(environmentEnum);
    this.roleService = new RoleService(environmentEnum);
    this.searchAddressService = new SearchAddressService(environmentEnum);
    this.securityPinService = new SecurityPinService(environmentEnum);
    this.securityQuestionsService = new SecurityQuestionsService(environmentEnum);
    this.stolenVehicleLocatorService = new StolenVehicleLocatorService(environmentEnum);
    this.subscribedPackagesService = new SubscribedPackagesService(environmentEnum);
    this.telematicServices = new TelematicServices(environmentEnum);
    this.tokenExchangeService = new TokenExchangeService();
    this.tokenLoginService = new TokenLoginService();
    this.vehicleContactsService = new VehicleContactsService(environmentEnum);
    this.vehicleContactUpdateService = new VehicleContactUpdateService(environmentEnum);
    this.vehicleDetailsService = new VehicleDetailsService(environmentEnum);
    this.vehicleLinkService = new VehicleLinkService(environmentEnum);
    this.vehicleLocationService = new VehicleLocationService(environmentEnum);
    this.vehicleStatusService = new VehicleStatusService(environmentEnum);
    this.vinService = new VinService(environmentEnum);
  }

  createAccount(data: UserCreatePayload) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return this.accountCreationService.createUser({ data, accessToken, locale });
  }

  userRoleLink({ userId, roleId, tenantId }: { userId: string; roleId?: string; tenantId: string }) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return this.accountLinkService.userRoleLink({ userId, roleId, tenantId, accessToken, locale });
  }

  updateUserInfo(data: UserUpdatePayload[], locale?: string, userId?: string) {
    return this.accountDetailsService.updateUserInfo({
      userId: userId || this.storeService.getUserId(),
      locale: locale || this.storeService.getLocale(),
      accessToken: this.storeService.getAccessToken(),
      data,
    });
  }

  getAccountDetails(locale?: string, userId?: string, accessToken?: string) {
    return this.accountDetailsService.getAccountDetails({
      userId: userId || this.storeService.getUserId(),
      locale: locale || this.storeService.getLocale(),
      accessToken: accessToken || this.storeService.getAccessToken(),
    });
  }

  queryAccount({ filter, fields, tenantId = '' }: { filter: QueryFilterKeys; fields?: string[]; tenantId?: string }) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return this.accountDetailsService.queryAccount({ filter, fields, accessToken, locale, tenantId });
  }

  getVehicleLocation(accessToken: string) {
    /*if (environmentEnum === Environment.LOCAL) {
      return mockService.getVehicleLocation(3000);
    }*/
    return this.vehicleLocationService.getVehicleLocation(
      accessToken || this.storeService.getAccessToken(),
      this.storeService.getVehicleData().vehicleId,
      this.storeService.getIdToken(),
    );
  }

  getServiceRequestId(accessToken: string) {
    return this.vehicleLocationService.getServiceRequestId(
      accessToken || this.storeService.getAccessToken(),
      this.storeService.getVehicleData().vehicleId,
      this.storeService.getIdToken(),
    );
  }

  getCurrentVehicleLocation(accessToken: string, svcReqId: string) {
    return this.vehicleLocationService.getVehicleLocation(
      accessToken || this.storeService.getAccessToken(),
      this.storeService.getVehicleData().vehicleId,
      this.storeService.getIdToken(),
      svcReqId,
    );
  }

  getNotificationPreferences() {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();
    const userId = this.storeService.getUserId();
    const locale = this.storeService.getLocale();
    return this.notificationPreferencesService.getPreferences({ accessToken, locale, vin, userId });
  }

  updateNotificationPreferences(data: preferencesData) {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();
    const userId = this.storeService.getUserId();
    const locale = this.storeService.getLocale();
    return this.notificationPreferencesService.updatePreferences({ accessToken, locale, vin, userId, ...data });
  }

  readMarketingPreference(payload: ReadPayload) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    const userId = this.storeService.getUserId();
    const data: ReadPreferencePayload = { userId, ...payload };

    return marketingPreferenceService.readMarketingPreference({ accessToken, locale, data });
  }

  createMarketingPreference(payload: EditPayload) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    const userId = this.storeService.getUserId();
    const data: PftPreferencePayload = { userId, ...payload };

    return marketingPreferenceService.createMarketingPreference({ accessToken, locale, data });
  }

  updateMarketingPreference(payload: EditPayload) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    const userId = this.storeService.getUserId();
    const data: PftPreferencePayload = { userId, ...payload };

    return marketingPreferenceService.updateMarketingPreference({ accessToken, locale, data });
  }

  getSubscription({
    vehicleId,
    userId,
    tenantId,
    excludeDefaultPkgs = false,
  }: {
    vehicleId?: string;
    userId?: string;
    tenantId?: string;
    excludeDefaultPkgs?: boolean;
  }) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();

    return this.subscribedPackagesService.getSubscription({
      userId: userId || this.storeService.getUserId(),
      vehicleId: vehicleId || this.storeService.getVehicleData().vehicleId,
      tenantId: tenantId || this.storeService.getTenantId(),
      excludeDefaultPkgs,
      accessToken,
      locale,
    });
  }

  getAgreement() {
    return this.agreementService.getAgreement(
      this.storeService.getLocale(),
      this.storeService.getAccessToken(),
      this.storeService.getUserId(),
      this.storeService.getVehicleData().vin,
    );
  }

  searchEligiblePackages({ vehicleId, userId }: { vehicleId?: string; userId?: string }) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    const tenantId = this.storeService.getTenantId();
    return this.subscribedPackagesService.searchEligiblePackages({
      userId: userId || this.storeService.getUserId(),
      vehicleId: vehicleId || this.storeService.getVehicleData().vehicleId,
      accessToken,
      locale,
      tenantId,
    });
  }

  async getCapableServices({
    vehicleId: initialVehicleId,
    userId: initialUserId,
  }: {
    vehicleId?: string;
    userId?: string;
  }): Promise<CapableServicesResponse> {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    const tenantId = this.storeService.getTenantId();
    const vehicle = this.storeService.getVehicleData();
    const vehicleId = initialVehicleId || vehicle.vehicleId;
    const userId = initialUserId || this.storeService.getUserId();
    let { vehicleServiceDetails } = vehicle;

    const { data: eligiblePackages } = await this.subscribedPackagesService.searchEligiblePackages({
      userId,
      vehicleId,
      accessToken,
      locale,
      tenantId,
    });
    const allProducts = eligiblePackages.map((item) => item.products).flat();
    const allProductsById = allProducts.reduce<Record<string, Product>>(
      (memo, product) => ({ ...memo, [product.id]: product }),
      {},
    );

    if (!vehicleServiceDetails?.length) {
      const {
        data: { allVehicleServiceDetails },
      } = await this.vehicleDetailsService.getVehicleDetails({
        subscribedServices: this.storeService.getSubscribedServices(),
        vehicleId,
        accessToken,
        locale,
        tenantId,
      });
      vehicleServiceDetails = allVehicleServiceDetails.vehicleServiceDetails;
    }

    const capableServices = vehicleServiceDetails.filter(
      (service) => service.customerFacing && service.provisioningStatus !== 'NULL',
    );
    const capableServicesById = capableServices.reduce<Record<string, VehicleServiceDetails>>(
      (memo, service) => ({ ...memo, [service.vehicleServiceId]: service }),
      {},
    );

    const productsWithServices = Object.values(allProductsById)
      .map(({ id, productName, serviceIds }) => ({
        id,
        productName,
        services: serviceIds.map((id) => capableServicesById[id]).filter(Boolean),
      }))
      .filter((product) => product.services.length > 0);

    return Promise.resolve({ data: productsWithServices });
  }

  createOrder({
    vehicleId,
    userId,
    ...rest
  }: {
    vehicleId?: string;
    userId?: string;
  } & OrderPayload) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    const tenantId = this.storeService.getTenantId();
    return this.subscribedPackagesService.createOrder({
      userId: userId || this.storeService.getUserId(),
      vehicleId: vehicleId || this.storeService.getVehicleData().vehicleId,
      accessToken,
      locale,
      tenantId,
      ...rest,
    });
  }

  createAgreement(data: CreateAgreementPayload) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return this.agreementService.createAgreement({ data, accessToken, locale });
  }

  getEffectiveAgreement(data: GetEffectiveAgreement) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return this.agreementService.getEffectiveAgreement({ data, accessToken, locale });
  }

  getContacts(locale: string, accessToken: string, vehicleId?: string) {
    return this.vehicleContactsService.getContacts(
      { type: ContactTypes.EmergencyContact },
      vehicleId || this.storeService.getVehicleData().vehicleId,
      locale,
      accessToken || this.storeService.getAccessToken(),
    );
  }

  getDrivers(locale: string, accessToken: string, vehicleId?: string) {
    return this.vehicleContactsService.getContacts(
      { type: ContactTypes.AdditionalDriver },
      vehicleId || this.storeService.getVehicleData().vehicleId,
      locale,
      accessToken || this.storeService.getAccessToken(),
    );
  }

  getPaymentMethod(locale: string, accessToken: string) {
    return this.paymentMethodService.getPaymentMethods(
      this.storeService.getUserId(),
      locale,
      accessToken || this.storeService.getAccessToken(),
    );
  }

  getByString(path: string) {
    return this.mockService.getByString(path);
  }

  fetchService(serviceName: string, locale: string) {
    return invoke(this.services.GET, serviceName, locale);
  }

  addContact(data: ContactFormData) {
    const accessToken = this.storeService.getAccessToken();
    return this.vehicleContactUpdateService.postContact(
      data,
      { type: ContactTypes.EmergencyContact },
      accessToken,
      this.storeService.getVehicleData().vehicleId,
      this.storeService.getUserId(),
      this.storeService.getTenantId(),
    );
  }

  addDriver(data: ContactFormData) {
    const accessToken = this.storeService.getAccessToken();
    return this.vehicleContactUpdateService.postContact(
      data,
      { type: ContactTypes.AdditionalDriver },
      accessToken,
      this.storeService.getVehicleData().vehicleId,
      this.storeService.getUserId(),
      this.storeService.getTenantId(),
    );
  }

  changeAccount(data: AccountFormData, accessToken?: string) {
    const token = accessToken || this.storeService.getAccessToken();
    const userEmail = urlSearchParams.get<string>('userEmail');
    const userId = this.storeService.getUserId();
    (
      new EventDataBuilder(EventType.UserProfileUpdatedEvent).withArgs({
        userId: userId,
        email: userEmail || '',
      }) as unknown as Promise<UserProfileUpdatedData>
    ).then((userProfileUpdatedEventData: UserProfileUpdatedData) => {
      sendAnalyticsEvent(userProfileUpdatedEventData);
    });
    return this.accountUpdateService.patchAccount(data, token);
  }

  changeContact(data: ContactFormData) {
    const accessToken = this.storeService.getAccessToken();
    return this.vehicleContactUpdateService.patchContact(data, accessToken);
  }

  changeDriver(data: ContactFormData) {
    const accessToken = this.storeService.getAccessToken();
    return this.vehicleContactUpdateService.patchContact(data, accessToken);
  }

  changePaymentMethod(data: PaymentMethodData) {
    const accessToken = this.storeService.getAccessToken();
    const userId = this.storeService.getUserId();
    return this.paymentMethodService.updatePaymentMethod(data, userId, accessToken);
  }

  deleteContact(data: ContactFormData) {
    const accessToken = this.storeService.getAccessToken();
    return this.vehicleContactUpdateService.deleteContact(data, accessToken);
  }

  exchangeToken(tokenId: string, clientId: string) {
    return this.tokenExchangeService.exchangeTokens({ tokenId, clientId });
  }

  sendOTPCode(requestData: {
    userId?: string;
    vehicleId?: string;
    channel: string;
    discountId?: string;
    externalTenantUserId?: string;
    isSecondaryPhone?: boolean;
    expiresIn?: number;
  }) {
    const tenantId = this.storeService.getTenantId();
    return this.otpCodeService.sendOTPCode({ tenantId, ...requestData });
  }

  validateOTPCode(tokenId: string, otp: string) {
    return this.otpCodeService.validateOTPCode(tokenId, otp);
  }

  loginToken(tokenId: string, otp?: string) {
    return this.tokenLoginService.loginToken(tokenId, otp);
  }

  updateService(serviceName: string, operation: string, data?: ApiRequestPayload) {
    return invoke(this.services, `${operation.toUpperCase()}.${serviceName}`, data);
  }

  services = {
    GET: {
      contacts: this.getContacts.bind(this),
      drivers: this.getDrivers.bind(this),
      subscription: this.getSubscription.bind(this),
      account: this.getAccountDetails.bind(this),
      vehicle: this.getVehicleDetails.bind(this),
      vehicleHealth: this.getVehicleHealth.bind(this),
      storeService: this.storeService,
      myCarLocation: this.getVehicleLocation.bind(this),
      paymentMethod: this.getPaymentMethod.bind(this),
      capableServices: this.getCapableServices.bind(this),
    },
    POST: {
      contacts: this.addContact.bind(this),
      drivers: this.addDriver.bind(this),
    },
    PUT: {
      account: this.changeAccount.bind(this),
      contacts: this.changeContact.bind(this),
      drivers: this.changeDriver.bind(this),
      paymentMethod: this.changePaymentMethod.bind(this),
    },
    DELETE: {
      contacts: this.deleteContact.bind(this),
      drivers: this.deleteContact.bind(this),
    },
  };

  resetVehicleData() {
    return this.headUnitsService.wipeVehicle(
      this.storeService.getAccessToken(),
      this.storeService.getIdToken(),
      this.storeService.getVehicleData().vehicleId,
    );
  }

  getPoiItems(searchTerm: string, maxReturned?: number, distance: MapDistance = {}) {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();
    const coordinates = this.storeService.getVehicleLocation() || this.storeService.getMapCenter();
    const { unit = DistanceUnit.Mile, value = 5 } = distance;
    const data = {
      searchString: searchTerm,
      geoNear: {
        coordinates,
        distance: {
          unit,
          value,
        },
      },
      maxReturned,
    };
    const id_token = this.storeService.getIdToken();

    return this.poiService.poiSearch({ accessToken, data, vin, id_token });
  }

  getAutocompleteAddress(searchTerm: string) {
    return this.mockService.getAutocompleteAddress(searchTerm);
  }

  searchDestinations(destination: string) {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();
    const id_token = this.storeService.getIdToken();

    const data = {
      searchString: destination,
    };
    return this.poiService.poiSearch({ accessToken, data, vin, id_token });
  }

  getDestinations(folderId: string, channelId: string) {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();

    return this.destinationService.getDestinations({
      accessToken,
      vin,
      folderId,
      channelId,
    });
  }

  addDestination(data: AddDestination) {
    const accessToken = this.storeService.getAccessToken();
    const id_token = this.storeService.getIdToken();
    const { vin } = this.storeService.getVehicleData();
    return this.destinationService.addDestination({
      accessToken,
      id_token,
      vin,
      data,
    });
  }

  deleteDestination(data: UpdateDestination) {
    const accessToken = this.storeService.getAccessToken();
    const id_token = this.storeService.getIdToken();
    const { vin } = this.storeService.getVehicleData();

    return this.destinationService.deleteDestination({
      accessToken,
      id_token,
      vin,
      data,
    });
  }

  getFavorites() {
    return this.mockService.getFavorites();
  }

  getSentToCar() {
    return this.mockService.getSentToCar();
  }

  getVehicleHealth() {
    return this.vehicleStatusService.getHealthInformation(
      this.storeService.getAccessToken(),
      this.storeService.getIdToken(),
      this.storeService.getVehicleData().vehicleId,
      this.storeService.getLocale(),
    );
  }

  getVehicleHealthReports() {
    return this.vehicleStatusService.getHealthReports(
      this.storeService.getAccessToken(),
      this.storeService.getIdToken(),
      this.storeService.getVehicleData().vehicleId,
      this.storeService.getLocale(),
    );
  }

  makeRemoteDoorAPICall(req: DoorUnlockRequestPayload) {
    return this.doorLockUnlockService.makeRemoteDoorAPICall(
      req,
      this.storeService.getAccessToken(),
      this.storeService.getIdToken(),
      this.storeService.getVehicleData().vehicleId,
    );
  }

  getVehicleDetails(locale?: string, vehicleId?: string, include?: string, subscribedServices?: SubscribedServices[]) {
    return this.vehicleDetailsService.getVehicleDetails({
      vehicleId: vehicleId || this.storeService.getVehicleData().vehicleId,
      subscribedServices: subscribedServices || this.storeService.getSubscribedServices(),
      accessToken: this.storeService.getAccessToken(),
      locale: locale || this.storeService.getLocale(),
      tenantId: this.storeService.getTenantId(),
      include,
    });
  }

  getVehiclesData(vehicleIds: string[]) {
    const vehiclesDetailRequests = vehicleIds.map((vehicleIds) => this.getVehicleDetails(undefined, vehicleIds));
    return Promise.all(vehiclesDetailRequests).then((results) =>
      results?.map((el) => {
        return {
          vin: el?.data?.vin,
          active: el?.data?.active,
          make: el?.data?.make,
          model: el?.data?.model,
          nickname: el?.data?.nickname,
          year: el?.data?.year,
          vehicleId: el?.data?.vehicleId,
          telematicsProgramId: el?.data?.telematicsProgramId,
          vehicleServiceDetails: el?.data?.vehicleServiceDetails,
          activeServices: el?.data?.activeServices,
          services: el?.data?.services,
          marketingServiceNames: el?.data?.marketingServiceNames,
          registrationCountry: el?.data?.registrationCountry,
          ownershipType: el?.data?.ownershipType,
          additionalFeatures: el?.data?.additionalFeatures,
          color: el?.data?.color,
        } as IVehicle;
      }),
    );
  }

  getVinSubscribedPackages = (vehicleIds: string[], userId?: string) => {
    const subscribedPackagesRequests = vehicleIds?.map((vehicleId) =>
      this.getSubscription({ vehicleId, userId, excludeDefaultPkgs: true }),
    );
    return Promise.all(subscribedPackagesRequests)
      .then((results) =>
        results?.map(
          (packages) =>
            ({
              vin: packages?.data?.vehicle?.vin,
              vehicleId: packages?.data?.vehicle?.vehicleId,
              activeType: packages?.data?.services?.some(
                (subscribed) =>
                  subscribed.packageType === PackageType.Regular || subscribed.packageType === PackageType.Default,
              ),
              subscribedServiceIds: uniq(
                packages?.data?.subscribedPackages
                  .filter((subscribedPackage) => isPackageToShow(subscribedPackage as SubscriptionPackage))
                  .flatMap((subscribedPackage) => {
                    const serviceIds = subscribedPackage.serviceIds;
                    const productsServiceIds = subscribedPackage.products.flatMap((product) => product.serviceIds);
                    return [...serviceIds, ...productsServiceIds];
                  }),
              ),
              packages: packages?.data?.subscribedPackages,
            } as SubscribedServices),
        ),
      )
      .catch((error) => {
        console.error('sxm subscribed vehicle status error', error);
        return [];
      });
  };

  searchVehicle({
    vin,
    activeCriteria,
    deviceId,
    telematicsProgramId,
  }: Optional<SearchVehiclePayload, 'activeCriteria'>) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    const tenantId = this.storeService.getTenantId();
    return this.vehicleDetailsService.searchVehicle({
      vin,
      activeCriteria,
      deviceId,
      telematicsProgramId,
      accessToken,
      locale,
      tenantId,
    });
  }

  queryVehicle({ filter, fields }: { filter: QueryFilterKeys; fields?: string[] }) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return this.vehicleDetailsService.queryVehicle({ filter, fields, accessToken, locale });
  }

  linkVehicle({ vehicleId, userId, roleId }: { vehicleId: string; userId: string; roleId: string }) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return this.vehicleLinkService.linkVehicle({ vehicleId, userId, roleId, accessToken, locale });
  }

  unlinkVehicle({ vehicleId, userId, roleId }: { vehicleId: string; userId: string; roleId: string }) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return this.vehicleLinkService.unlinkVehicle({ vehicleId, userId, roleId, accessToken, locale });
  }

  makeHornLightsAPICall(request: HornLightsRequestPayload) {
    return this.hornLightsService.makeHornLightsAPICall(
      request,
      this.storeService.getAccessToken(),
      this.storeService.getIdToken(),
      this.storeService.getVehicleData().vehicleId,
    );
  }

  makeEngineStartStopCall(request: EngineStartStopRequestPayload) {
    return this.engineStartStopService.makeEngineStartStopCall(
      request,
      this.storeService.getAccessToken(),
      this.storeService.getIdToken(),
      this.storeService.getVehicleData().vehicleId,
    );
  }

  getBreaches(type: string, alertType: string, startDateTime: string, endDateTime: string) {
    const vehicleId = this.storeService.getVehicleData().vehicleId;
    const accessToken = this.storeService.getAccessToken();
    const idToken = this.storeService.getIdToken();
    // return mockService.getBreaches(); // Mock service for local development
    return this.alertsService.getBreaches({
      vehicleId,
      accessToken,
      type,
      alertType,
      startDateTime,
      endDateTime,
      idToken,
    });
  }

  getPostalAddress(coordinates: { latitude: number; longitude: number }) {
    const vin = this.storeService.getVehicleData().vin;
    const accessToken = this.storeService.getAccessToken();
    const idToken = this.storeService.getIdToken();
    return this.alertsService.getPostalAddress({ vin, accessToken, idToken, coordinates });
  }

  getAlertService() {
    const vehicleId = this.storeService.getVehicleData().vehicleId;
    const accessToken = this.storeService.getAccessToken();
    const idToken = this.storeService.getIdToken();
    return this.alertsService.getAlerts({ vehicleId, accessToken, idToken });
  }

  updateAlertService(data: DrivingBehaviorByVehicleId) {
    const vehicleId = this.storeService.getVehicleData().vehicleId;
    const accessToken = this.storeService.getAccessToken();
    const idToken = this.storeService.getIdToken();
    return this.alertsService.updateAlerts({ vehicleId, accessToken, idToken, data });
  }

  createAlertService(data: CreateAlertsRequest) {
    const vehicleId = this.storeService.getVehicleData().vehicleId;
    const accessToken = this.storeService.getAccessToken();
    const idToken = this.storeService.getIdToken();
    return this.alertsService.createAlerts({ vehicleId, accessToken, idToken, data });
  }

  deleteAlertService(data: { lastSvcReqId: string }) {
    const vehicleId = this.storeService.getVehicleData().vehicleId;
    const accessToken = this.storeService.getAccessToken();
    const idToken = this.storeService.getIdToken();
    return this.alertsService.deleteAlerts({ vehicleId, accessToken, idToken, data });
  }

  getCurfewsService() {
    const vehicleId = this.storeService.getVehicleData().vehicleId;
    const accessToken = this.storeService.getAccessToken();
    const idToken = this.storeService.getIdToken();
    return this.curfewService.getCurfews({ vehicleId, accessToken, idToken });
  }

  updateCurfewsService(data: UpdateCurfewPayload) {
    const vehicleId = this.storeService.getVehicleData().vehicleId;
    const accessToken = this.storeService.getAccessToken();
    const idToken = this.storeService.getIdToken();
    return this.curfewService.updateCurfews({ vehicleId, accessToken, idToken, data });
  }

  createCurfewsService(data: CreateCurfewPayload) {
    const vehicleId = this.storeService.getVehicleData().vehicleId;
    const accessToken = this.storeService.getAccessToken();
    const idToken = this.storeService.getIdToken();
    return this.curfewService.createCurfews({ vehicleId, accessToken, idToken, data });
  }

  deleteCurfewsService(data: { id: string }) {
    const vehicleId = this.storeService.getVehicleData().vehicleId;
    const accessToken = this.storeService.getAccessToken();
    const idToken = this.storeService.getIdToken();
    return this.curfewService.deleteCurfews({ vehicleId, accessToken, idToken, data });
  }

  getGeofence() {
    const accessToken = this.storeService.getAccessToken();
    const { vehicleId } = this.storeService.getVehicleData();
    const idToken = this.storeService.getIdToken();
    return this.geofenceService.getGeofence({ accessToken, vehicleId, idToken });
  }

  createGeofence(data: CreateGeofencePayload) {
    const accessToken = this.storeService.getAccessToken();
    const { vehicleId } = this.storeService.getVehicleData();
    const idToken = this.storeService.getIdToken();
    return this.geofenceService.createGeofence({ accessToken, vehicleId, data, idToken });
  }

  updateGeofence(data: UpdateGeofencePayload) {
    const accessToken = this.storeService.getAccessToken();
    const { vehicleId } = this.storeService.getVehicleData();
    const idToken = this.storeService.getIdToken();
    return this.geofenceService.updateGeofence({ accessToken, vehicleId, data, idToken });
  }

  deleteGeofence(geofenceId: string) {
    const accessToken = this.storeService.getAccessToken();
    const { vehicleId } = this.storeService.getVehicleData();
    const idToken = this.storeService.getIdToken();
    return this.geofenceService.deleteGeofence({ accessToken, vehicleId, geofenceId, idToken });
  }

  getFolders() {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();
    return this.folderService.getFolders({ accessToken, vin });
  }

  getJourney(folderId: string, channelId: string) {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();

    return this.journeyService.getJourney({
      accessToken,
      vin,
      folderId,
      channelId,
    });
  }

  updateJourney(data: Journey) {
    const accessToken = this.storeService.getAccessToken();
    const id_token = this.storeService.getIdToken();
    const { vin } = this.storeService.getVehicleData();

    return this.journeyService.updateJourney({
      accessToken,
      id_token,
      vin,
      data,
    });
  }

  addJourney(data: AddJourney) {
    const accessToken = this.storeService.getAccessToken();
    const id_token = this.storeService.getIdToken();
    const { vin } = this.storeService.getVehicleData();

    return this.journeyService.addJourney({
      accessToken,
      id_token,
      vin,
      data,
    });
  }

  deleteJourney(folderId: string, channelId: string) {
    const accessToken = this.storeService.getAccessToken();
    const id_token = this.storeService.getIdToken();
    const { vin } = this.storeService.getVehicleData();

    return this.journeyService.deleteJourney({
      accessToken,
      id_token,
      vin,
      folderId,
      channelId,
    });
  }

  getJourneys() {
    return this.mockService.getJourneys();
  }

  removeJourney() {
    return this.mockService.removeJourney();
  }

  getVehicleConditionCarInfo() {
    return this.mockService.getVehicleConditionCarInfo();
  }

  updatePin(data: ResetPinPayload) {
    const userId = this.storeService.getUserId();
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return this.pinService.pinUpdate({ data, userId, accessToken, locale });
  }

  resetPin(data: ResetPinByKBAPayload) {
    const userId = this.storeService.getUserId();
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return this.pinService.pinReset({ data, userId, accessToken, locale });
  }

  updatePasswordData(data: UpdatePasswordPayload | {}, userId: string, tenantId: string) {
    const accessToken = this.storeService.getAccessToken();
    return this.passwordService.updatePassword({ data, userId, accessToken, tenantId });
  }

  getSecurityQuestions() {
    const userId = this.storeService.getUserId();
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale()
    return this.securityQuestionsService.getSecurityQuestions({
      userId,
      accessToken,
      locale,
    });
  }

  updateSecurityQuestions(data: UpdateSecurityQuestionsByPinRequest['data']) {
    const userId = this.storeService.getUserId();
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return this.securityQuestionsService.updateSecurityQuestions({
      data,
      userId,
      accessToken,
      locale,
    });
  }

  setupSecurityPin(data: SetupPinAndSecurityQuestionsRequest['data']) {
    const userId = this.storeService.getUserId();
    const accessToken = this.storeService.getAccessToken();
    return this.securityPinService.setupSecurityPin({ data, userId, accessToken });
  }

  storeTokenResponse(data: { access_token: string; refresh_token: string; id_token: string }) {
    const { access_token, refresh_token, id_token } = data;

    this.storeService.setTokens({
      accessToken: access_token,
      refreshToken: refresh_token,
      idToken: id_token,
    });
    return data;
  }

  authenticateWithToken(token: string) {
    return this.authService.exchangeToken({ token }).then(({ data }) => this.storeTokenResponse(data));
  }

  getIdmAccessToken({ code, verifier, stateCode }: { code: string; verifier: string; stateCode: string }) {
    return this.authService.getIdmAccessToken({ code, verifier, stateCode });
  }

  getAccessToken({ exchangeToken }: { exchangeToken: string }) {
    return this.authService.getAccessToken({ exchangeToken }).then(({ data }) => {
      data.id_token ||= exchangeToken;
      return data;
    });
  }

  refreshToken() {
    const refreshToken = this.storeService.getRefreshToken();

    if (!refreshToken) {
      return Promise.reject('Missing refresh token');
    }

    return this.authService.refreshToken({ refreshToken }).then((response) => {
      const { access_token, id_token } = response.data;

      this.storeService.setTokens({
        accessToken: access_token,
        idToken: id_token,
      });

      return response;
    });
  }

  getSxmKeyDetails({ sxmKey }: { sxmKey: string }) {
    return this.authService.getSxmKeyDetails({ sxmKey });
  }

  getUserInfo() {
    const accessToken = this.storeService.getAccessToken();
    return this.authService.getUserInfo({ accessToken });
  }

  queryRole({ filter, fields }: { filter: QueryFilterKeys; fields?: string[] }) {
    const accessToken = this.storeService.getAccessToken();
    const locale = this.storeService.getLocale();
    return this.roleService.queryRole({ filter, fields, accessToken, locale });
  }

  getSvl(vehicleId: string) {
    return this.stolenVehicleLocatorService.getSvl(
      this.storeService.getLocale(),
      this.storeService.getAccessToken(),
      vehicleId || this.storeService.getVehicleData().vehicleId,
      this.storeService.getIdToken(),
    );
  }

  addVin(data: AddVinData) {
    const locale = this.storeService.getLocale();
    const userId = this.storeService.getUserId();
    const tenantId = this.storeService.getTenantId();

    return this.vinService.addVin({ data, locale, userId, tenantId });
  }

  removeVin({ vin }: { vin: string }) {
    const locale = this.storeService.getLocale();
    const userId = this.storeService.getUserId();
    const tenantId = this.storeService.getTenantId();

    return this.vinService.removeVin({ vin, locale, userId, tenantId });
  }

  getTelematicServices(vehicleId: string, locale: string) {
    const accessToken = this.storeService.getAccessToken();
    return this.telematicServices.getTelematicServices({
      vehicleId,
      accessToken,
      locale,
    });
  }

  getOTPContacts(userId: string) {
    return this.otpCodeService.getOTPContacts(userId);
  }

  async listAddresses(data: { isoCountryCode: string; query: string }) {
    const accessToken = this.storeService.getAccessToken();
    return this.searchAddressService.listAddresses({ accessToken, ...data });
  }

  async getFormattedAddress(data: { isoCountryCode: string; id: string }) {
    const accessToken = this.storeService.getAccessToken();
    return this.searchAddressService.getFormattedAddress({ accessToken, ...data });
  }

  getLink(linkConfig: string | undefined) {
    const accessToken = this.storeService.getAccessToken();
    const refreshToken = this.storeService.getRefreshToken();

    return this.getLinkService.getLink({ accessToken, refreshToken, linkConfig });
  }

  async retrieveCalendar() {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();

    return this.calendarService.retrieveCalendar({ accessToken, vin });
  }

  async saveCalendar(selectedCalendar: UserCalendar) {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();
    const data = convertToSend(selectedCalendar);

    return this.calendarService.saveCalendar({ accessToken, vin, data });
  }

  async deleteCalendar() {
    const accessToken = this.storeService.getAccessToken();
    const { vin } = this.storeService.getVehicleData();

    return this.calendarService.deleteCalendar({ accessToken, vin });
  }

  async getListOfRecentInvoices(numberOfInvoices: number) {
    const accessToken = this.storeService.getAccessToken();
    const userId = this.storeService.getUserId();
    return this.paymentMethodService.getListOfRecentInvoices(numberOfInvoices, userId, accessToken);
  }

  async downloadInvoice(invoiceId: string, invoiceType: string) {
    const accessToken = this.storeService.getAccessToken();
    const userId = this.storeService.getUserId();
    const locale = this.storeService.getLocale();
    return this.paymentMethodService.downloadInvoice(locale, accessToken, userId, invoiceId, invoiceType);
  }
}
