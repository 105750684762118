import React from 'react';

import styles from './Header.module.css';

type HeaderHamburgerProps = {
  onClick: () => void;
};

export default function HeaderHamburger({ onClick }: HeaderHamburgerProps) {
  return (
    <button type="button" onClick={onClick} className={styles['Header-hamburger']}>
      <span>Hamburger menu</span>
    </button>
  );
}
