import { EligiblePackagesResponse, Variant } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import {
  IVehicle,
  setAccountData,
  setSubscribedServices,
  setUserPinStatus,
  setVehicleIds,
  setVehicleVins,
  svlStatus,
} from '@redux/actions';
import { OfferType, PackageType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { PORTAL_LABEL_DICTIONARY, PROVISIONING_COMPLETE } from './constants';
import React, { Fragment, useEffect, useState } from 'react';
import { myCarFinderLabels } from '@components/Map/constants';
import { ServiceData, SubscribedServices, useApi } from '@api';

import { Button } from '@components-lib';
import { CardView } from '@components/Card';
import ContentRenderer from '@components/ContentRenderer';
import ContentfulComponent from '@customTypes/ContentfulComponent';
import Dialog from '@components/Dialog';
import Grid from '@components/Grid';
import { LoaderBackdrop } from '@components/Loader';
import { TelematicsServiceDetail } from '@cv/portal-cps-lib/vehicle/service-management/models/search-telematics-service';
import { UpdateOperation } from '@cv/portal-idm-lib/user/user-update-profile/enums';
import Widget from '@components/Widget/Widget';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { isServiceSubscribed } from '@utils/checkIsServiceSubscribed';
import { labelReducer } from '@utils/filter-label-dictionary';
import styles from './VinStatusPage.module.css';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import usePreferencesSelector from '@redux/selectors/preferences';
import useToggle from '@hooks/useToggle';
import { setPackages } from '@redux/actions/user';

type PageListData = {
  contentSections?: Array<ContentfulComponent>;
};

const VinStatusPage = ({ contentSections }: PageListData) => {
  const vinStatusLabels = labelReducer(
    contentSections?.find(({ componentType }: ContentfulComponent) => componentType === PORTAL_LABEL_DICTIONARY),
  );
  const preferences = usePreferencesSelector();
  const history = useHistory();
  const dispatch = useDispatch();
  const api = useApi();

  const [vehicleList, setVehicleList] = useState<IVehicle[]>([]);
  const [subscribedVehicleList, setSubscribedVehicleList] = useState<SubscribedServices[]>([]);
  const [eligibleVehicleList, setEligibleVehicleList] = useState<SubscribedServices[]>([]);
  const [apiStatus, setApiStatus] = useState<'idle' | 'loading' | 'error' | 'success' | 'first-login'>('loading');
  const [isFirstLogin, setFirstLogin] = useState(false);
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [deleteVehicle, setDeleteVehicle] = useState<IVehicle | null>(null);
  const [isVehicleDeleteResult, setIsVehicleDeleteResult] = useState<boolean>(false);
  const [vinsFetched, setVinsFetched] = useToggle(false);
  const [packagesFetched, setPackagesFetched] = useToggle(false);

  useEffect(() => {
    getUserInfo();
  }, [api]);

  useEffect(() => {
    if (vinsFetched && packagesFetched) {
      setApiStatus('idle');
    }
  }, [vinsFetched, packagesFetched]);

  const getUserInfo = async () => {
    try {
      setVinsFetched(false);
      setPackagesFetched(false);
      setApiStatus('loading');
      const {
        data: { sub: userId },
      } = await api.getUserInfo();
      getAccountDetails(userId);
    } catch (error) {
      setApiStatus('error');
      console.error('sxm userInfo error', error);
    }
  };

  const getAccountDetails = async (sub: string) => {
    try {
      const { data } = await api.getAccountDetails(undefined, sub);
      if (data) {
        api.storeService.setUserID(data._id);
        dispatch(setVehicleIds(data?.vehicleIds || []));
        dispatch(setAccountData(data));
        dispatch(setUserPinStatus(data?.isPinConfigured || false));

        if (data.customFields.field1) {
          updateLoginTime();
        } else {
          showFirstLoginDialog();
        }
      } else {
        setApiStatus('idle');
      }
    } catch (error) {
      console.error('sxm account error', error);
      setApiStatus('error');
    }
  };

  const updateLoginTime = (firstLogin = isFirstLogin) => {
    const time = new Date().toISOString();
    api
      .updateUserInfo([
        {
          operation: UpdateOperation.Replace,
          field: 'customField01',
          value: time,
        },
        {
          operation: UpdateOperation.Replace,
          field: 'customFieldLabel01',
          value: 'Last login time',
        },
      ])
      .then(({ data }) => {
        if (data) {
          const { primarySubscriberVehicleIds, vehicleIds } = data;
          const correctVehicles = primarySubscriberVehicleIds || vehicleIds;
          getVinSubscribedPackages(correctVehicles || []);
          vehicleIds && getVinStatus(vehicleIds);
        } else {
          setApiStatus('idle');
        }
      })
      .catch(() => {
        setApiStatus(firstLogin ? 'first-login' : 'error');
      });
  };

  const showFirstLoginDialog = () => {
    setApiStatus('first-login');
    setFirstLogin(true);
  };

  const getVinStatus = (vehicleIds: string[]) => {
    api
      .getVehiclesData(vehicleIds)
      .then((vehiclesData: IVehicle[]) => {
        setVehicleList(vehiclesData);
        dispatch(setVehicleVins(vehiclesData));
        setVinsFetched(true);
      })
      .catch((error: Error) => {
        console.error('sxm vehicle error', error);
        setApiStatus('error');
      });
  };

  const getVinSubscribedPackages = (vehicleIds: string[]) => {
    const apiListSubscribedVehicle = vehicleIds?.map((vehicleId) =>
      api.getSubscription({ vehicleId, excludeDefaultPkgs: true }),
    );

    setApiStatus('loading');
    Promise.all(apiListSubscribedVehicle)
      .then((results) => {
        if (results) {
          const _subscribed = results?.map((packages) => {
            const { data } = packages;
            return {
              vehicleId: data?.vehicle?.vehicleId,
              activeType: data?.services?.some(
                (subscribed: ServiceData) =>
                  subscribed.packageType === PackageType.Regular || subscribed.packageType === PackageType.Default,
              ),
              subscribedServiceIds: [].concat(...data?.services.map((service: any) => service.serviceIds)),
              packages: data?.subscribedPackages,
            } as unknown as SubscribedServices;
          });
          dispatch(setPackages(_subscribed));
          setSubscribedVehicleList(_subscribed);
          dispatch(setSubscribedServices(_subscribed));

          getVinEligiblePackages(_subscribed);
        } else {
          setApiStatus('idle');
        }
      })
      .catch((error) => {
        console.error('sxm subscribed vehicle status error', error);
        setApiStatus('error');
      });
  };

  const getVinEligiblePackages = (vehicleList: SubscribedServices[]) => {
    const apiListEligibleVehicle = vehicleList
      ?.filter((vehicle) => !vehicle.activeType)
      ?.map((vehicle) => api.searchEligiblePackages({ vehicleId: vehicle.vehicleId }));
    setApiStatus('loading');
    Promise.all(apiListEligibleVehicle)
      .then((results) => {
        if (results) {
          const _eligible = results?.map((packages: any) => {
            return {
              vehicleId: packages?.vehicleId,
              activeType: packages?.data?.some(
                (eligible: EligiblePackagesResponse) =>
                  eligible.packageType === PackageType.Regular &&
                  eligible?.variants.some((variant: Variant) =>
                    variant?.discounts?.some((disc) => disc.offerType === OfferType.Trial),
                  ),
              ),
            } as SubscribedServices;
          });
          setEligibleVehicleList(_eligible);
        }
        setPackagesFetched(true);
      })
      .catch((error) => {
        console.error('sxm eligible vehicle status error', error);
        setApiStatus('error');
      });
  };

  const goTo = (path: string) => {
    window.setTimeout(() => {
      if (path === 'home' || path === 'profile') {
        const win = window.open(`/${path}`, '_blank');
        win?.focus();
      } else {
        history.push(`/${path}`);
      }
    }, 150);
  };

  const getSvl = async (vehicleId: string, path: string) => {
    let nextPath = path;
    try {
      const { data } = await api.getSvl(vehicleId);
      setApiStatus('success');
      if (!isEmpty(data)) {
        dispatch(svlStatus(data));
        nextPath = 'profile';
      }
    } catch (err) {
      setApiStatus('error');
      console.log(err);
    } finally {
      goTo(nextPath);
    }
  };

  const getActiveServices = async (vehicleId: string) => {
    let activeServices: string[] = [];
    try {
      const { data } = await api.getTelematicServices(vehicleId, 'en-US'); // Response is always expected to be in en-US, no Translation is needed to get active services
      if (!isEmpty(data)) {
        const subscribedServices = subscribedVehicleList
          .filter((subscribedVehicle: SubscribedServices) => subscribedVehicle.vehicleId === vehicleId)
          .flatMap((vehicle: SubscribedServices) => vehicle.subscribedServiceIds);
        activeServices = data?.telematicsServiceDetails?.reduce(
          (serviceList: string[], serviceObj: TelematicsServiceDetail) => {
            if (
              serviceObj?.provisioningStatus === PROVISIONING_COMPLETE &&
              subscribedServices.includes(serviceObj.vehicleServiceId)
            ) {
              serviceList.push(serviceObj.vehicleServiceName);
            }
            return serviceList;
          },
          [],
        );
      }
    } catch (err) {
      console.log(err);
    }
    return activeServices;
  };

  const goToWithVehicle = async (vehicleData: IVehicle, path: string) => {
    const {
      DOOR_LOCK_UNLOCK,
      DOOR_UNLOCK,
      HORN_LIGHT,
      START_STOP_ENGINE,
      REMOTE_VEHICLE_STATUS,
      STOLEN_VEHICLE_LOCATOR,
    } = vinStatusLabels;
    const remoteServices = [DOOR_LOCK_UNLOCK, DOOR_UNLOCK, HORN_LIGHT, START_STOP_ENGINE, REMOTE_VEHICLE_STATUS];

    setApiStatus('loading');
    vehicleData.activeServices = await getActiveServices(vehicleData?.vehicleId);
    api.storeService.setVehicleData(vehicleData);
    if (!isServiceSubscribed([...remoteServices, ...myCarFinderLabels], vehicleData?.activeServices)) {
      path = 'profile';
    }
    if (isServiceSubscribed([STOLEN_VEHICLE_LOCATOR], vehicleData?.activeServices)) {
      getSvl(vehicleData.vehicleId, path);
    } else {
      setApiStatus('success');
      goTo(path);
    }
  };

  const registerVehicle = (vehicleData: IVehicle, path: string) => {
    api.storeService.setVehicleData(vehicleData);
    goTo(path);
  };

  const checkSubscribedStatus = (vehicleId: string): boolean => {
    return subscribedVehicleList.filter((el) => el.vehicleId === vehicleId)?.[0]?.activeType;
  };

  const checkEligibleStatus = (vehicleId: string): boolean => {
    return eligibleVehicleList.filter((el) => el.vehicleId === vehicleId)?.[0]?.activeType;
  };

  const openDeleteConfirmation = (vehicleData: IVehicle) => {
    setDeleteVehicle(vehicleData);
    setModalVisible(true);
  };

  const onDeleteCancel = () => {
    setDeleteVehicle(null);
    setModalVisible(false);
  };

  const onDelete = async () => {
    if (!deleteVehicle) {
      getUserInfo();
      setModalVisible(false);
      setIsVehicleDeleteResult(true);
      return;
    }
    try {
      setModalVisible(false);
      setApiStatus('loading');
      await api.removeVin({ vin: deleteVehicle.vin });
      setApiStatus('success');
    } catch (error) {
      console.error('sxm unlink vehicle status error', error);
      setApiStatus('error');
    } finally {
      setDeleteVehicle(null);
      setIsVehicleDeleteResult(true);
      setModalVisible(true);
    }
  };

  const dialogStatusmessage = () => {
    switch (apiStatus) {
      case 'success':
        return '¡EXCELENTE!';
      case 'error':
        return `Parece que hay un problema. Llame a atención al cliente al ${preferences.customerCareNumber}.`;
      default:
        return 'Eliminar borrará automáticamente el vehículo y lo dará de baja de todos los servicios, incluyendo llamada de emergencia y notificación de colisión automática.';
    }
  };

  if (apiStatus === 'loading') {
    return <LoaderBackdrop />;
  }

  const infoBox = contentSections?.find(({ isAccountFormContainer }) => !!isAccountFormContainer);

  if (apiStatus === 'first-login' && infoBox) {
    const handleAfterSubmit = () => {
      setApiStatus('loading');
      updateLoginTime();
    };

    const handleAfterCancel = () => {
      setFirstLogin(false);
      setApiStatus('idle');
      updateLoginTime(false);
    };

    return (
      <div className={styles['CardContainer']}>
        <CardView type="main">
          <ContentRenderer
            name="InfoBox"
            content={[infoBox]}
            isFormOpen
            afterSubmit={handleAfterSubmit}
            afterCancel={handleAfterCancel}
          />
        </CardView>
      </div>
    );
  }

  return (
    <Fragment>
      <Widget title={vinStatusLabels?.manageVin} subTitle={vinStatusLabels?.associatedVins}>
        <div>
          <Grid className={clsx(styles['Grid'], styles['GridSeperation'])} columns={'2'}>
            <div className={styles['GridBody']}>
              <Grid className={clsx(styles['Grid'], styles['grid-column-2-mobile'])} columns={'2'}>
                <div className={styles['GridHeader']}>{vinStatusLabels?.vin}</div>
                <div className={styles['GridHeader']}>{vinStatusLabels?.status}</div>
              </Grid>
            </div>
            <div className={styles['GridBody']}></div>
          </Grid>
          {vehicleList?.map((row: IVehicle, index: number) => (
            <Grid className={clsx(styles['Grid'], styles['GridSeperation'])} columns={'2'} key={index}>
              <div className={styles['GridBody']}>
                <Grid className={clsx(styles['Grid'], styles['grid-column-2-mobile'])} columns={'2'}>
                  <div className={styles['GridBody']}>{row.vin}</div>
                  <div className={styles['GridBody']}>
                    {checkSubscribedStatus(row.vehicleId) ? (
                      <Fragment>
                        <span className={clsx(styles['Dot'], styles['DotActive'])}></span>
                        <span className={styles['Active']}> {vinStatusLabels?.active} </span>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <span className={clsx(styles['Dot'], styles['DotNotActive'])}></span>
                        <span>{vinStatusLabels?.notActive}</span>
                      </Fragment>
                    )}
                  </div>
                </Grid>
              </div>
              <div className={styles['GridBody']}>
                <Grid className={styles['Grid']} columns={'2'}>
                  <div className={styles['GridBody']}>
                    {checkSubscribedStatus(row.vehicleId) ? (
                      <Button
                        variant="contained"
                        className={styles['Button']}
                        onClick={() => goToWithVehicle(row, 'home')}
                      >
                        {vinStatusLabels?.manageSubscription}
                      </Button>
                    ) : (
                      checkEligibleStatus(row.vehicleId) && (
                        <Button
                          variant="text"
                          className={styles['Button']}
                          onClick={() => registerVehicle(row, 'serviceOverview')}
                        >
                          {vinStatusLabels?.register}
                        </Button>
                      )
                    )}
                  </div>
                  <div className={styles['GridBody']}>
                    <Button
                      role="presentation"
                      className={styles['Anchor']}
                      variant="text"
                      onClick={() => openDeleteConfirmation(row)}
                    >
                      {vinStatusLabels?.delete}
                    </Button>
                  </div>
                </Grid>
              </div>
            </Grid>
          ))}
        </div>
      </Widget>

      {isModalVisible && (
        <Dialog
          title="¿Estás seguro de querer eliminar este vehículo?"
          labelOk={!isVehicleDeleteResult ? 'ELIMINAR' : 'CERRAR'}
          labelCancel={!isVehicleDeleteResult ? 'CANCELAR' : ''}
          onOk={onDelete}
          onCancel={onDeleteCancel}
        >
          <p>{dialogStatusmessage()}</p>
        </Dialog>
      )}
    </Fragment>
  );
};

export default VinStatusPage;
