import React, { useState } from 'react';
import { PackageTrial } from './PackageTrial';
import { DropdownSection, ExpandCollapseSection, Wrapper } from '../../PaidPackage/styles';
import { ContinueButton, ContinueButtonsWrapper, ProductSection } from '../../../ManagePackages/styles';
import Products from '../../../Products';
import { EligiblePackageInfo } from '../../../Types';
import { useSelector } from '@xstate/react';
import { useFlowMachine } from '@lib-appRouter/RouterContext';
import { ContentfulAssets, isTiered } from '../../..';
import Image from '@lib-components/Image';
import { TrialPackagesContainer } from '@manageSubscription/Packages/DiscountPackage/styles';
import { Container } from '@styled-components/globalStyles';
import TrialPackageOptionsWrapper from '@manageSubscription/Packages/DiscountPackage/TrialDiscount/TrialPackageOptionsWrapper';

export interface TrialPackagesProps {
  trialPackages: EligiblePackageInfo[];
  addAllPackages: (props) => void;
  productsToHide: string[];
  assets: ContentfulAssets;
  make: string;
  locale: string;
  tenantId: string;
  location: string;
  hasMultipleTrials: boolean;
  hasOneTrialEligibleVariant: boolean;
  handleNoThanks: () => void;
  selectedTrialPackage: EligiblePackageInfo;
  setSelectedTrialPackage: (trialPkg: EligiblePackageInfo) => void;
}

const TrialPackages = ({
  trialPackages = [],
  addAllPackages,
  productsToHide,
  assets,
  make,
  locale,
  tenantId,
  location,
  hasMultipleTrials,
  hasOneTrialEligibleVariant,
  handleNoThanks,
  selectedTrialPackage,
  setSelectedTrialPackage,
}: TrialPackagesProps) => {
  const flowMachine = useFlowMachine();
  const {
    commonWebContent: { continueButtonLabel, skipAndContinueButtonLabel },
  } = useSelector(flowMachine, (state) => state.context.content);

  const [click, setClick] = useState(-1);

  if (!trialPackages.length) {
    return null;
  }

  const hasMultipleVariantsOnATrial = trialPackages.some((pkg) => pkg.variants.length > 1);

  const onProductInfoToggle = (index: number) => {
    return setClick(click === index ? -1 : index);
  };

  const determineVariant = (selectedPackage: EligiblePackageInfo, defaultPackage: EligiblePackageInfo) => {
    return selectedPackage ? selectedPackage.variant : defaultPackage.variant;
  };

  return (
    <Container>
      {trialPackages
        .sort((p1, p2) => p2.basePriceMonthly - p1.basePriceMonthly)
        .map((trialPackage, index: number) => {
          const { marketingName, shortDescription, tier, products, variants } = trialPackage;
          const isActive = hasMultipleTrials ? click === index : true;
          const hasMultipleVariants = variants.length > 1;
          return (
            <TrialPackagesContainer key={marketingName}>
              <Wrapper showBottomBorder={!isActive}>
                <PackageTrial
                  marketingName={marketingName}
                  shortDescription={shortDescription}
                  variant={determineVariant(selectedTrialPackage, trialPackage)}
                />
                {hasMultipleTrials && (
                  <ExpandCollapseSection onClick={() => onProductInfoToggle(index)}>
                    <Image assets={assets} name={isActive ? 'ArrowUp' : 'ArrowDown'} />
                  </ExpandCollapseSection>
                )}
              </Wrapper>
              {isActive && (
                <DropdownSection showTopBorder={!hasMultipleTrials} showBottomBorder={hasMultipleTrials}>
                  <ProductSection>
                    <Products
                      products={products}
                      productsToHide={productsToHide}
                      assets={assets}
                      make={make}
                      locale={locale}
                      tenantId={tenantId}
                      location={location}
                    />
                  </ProductSection>
                  {isTiered(tier) && hasMultipleVariants && (
                    <TrialPackageOptionsWrapper
                      trialPackage={trialPackage}
                      setSelectedTrialPackage={setSelectedTrialPackage}
                    />
                  )}
                </DropdownSection>
              )}
            </TrialPackagesContainer>
          );
        })}
      <ContinueButtonsWrapper>
        <ContinueButton
          fullWidth
          disabled={hasMultipleVariantsOnATrial && !selectedTrialPackage}
          onClick={() => addAllPackages(selectedTrialPackage ? [selectedTrialPackage] : trialPackages)}
        >
          {continueButtonLabel}
        </ContinueButton>
        {hasOneTrialEligibleVariant && (
          <ContinueButton variant="outlined" onClick={handleNoThanks}>
            {skipAndContinueButtonLabel}
          </ContinueButton>
        )}
      </ContinueButtonsWrapper>
    </Container>
  );
};

export default TrialPackages;
