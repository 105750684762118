import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CgSearch } from 'react-icons/cg';
import { FaPlusCircle, FaEdit, FaTimes } from 'react-icons/fa';

import './MapWindow.css';

import { setLoadingStatus } from '@redux/actions/loading';
import { useApi } from '@api';
import { useAnalytics } from '@cv/webframework-react-components';
import ModalContainer from '@components/ModalContainer/';
import closeIcon from '../../assets/close-icon.png';
import { SET_JOURNEYS } from './constants';
import { Folder, PointOfInterest } from './types';
import { enterKeyPressed } from '@utils/enterKeyPressed';
import { formatPins } from '@utils/formatPins';
import bluePin from '../../assets/pin.png';
import selectedPin from '../../assets/Pin_select.png';
import Loader from '@components/Loader';

type JourneyType = 'start' | 'end' | 'third' | 'fourth' | 'fifth';

type WaypointInputType = {
  isVisible: boolean;
  setWayPointInput: (e: any) => void;
  type: JourneyType;
  value: string;
};

type MapWindowJourneyOverlayProps = {
  labels: {
    AddJourneyButton: string;
    AddWaypointButton: string;
    FinalRoute: string;
    JourneyBodyText: string;
    JourneyTab: string;
    JourneyTitle: string;
    NameJourneyButton: string;
    NameJourneyText: string;
    ReverseRouteButton: string;
    RouteSummary: string;
    SelectWaypointTitle: string;
    SendToCarButton: string;
    StartOverButton: string;
    ViewRouteButton: string;
    JourneyInputPlaceholder: string;
    JourneyNameInputPlaceholder: string;
    JourneyModalP1: string;
    JourneyModalP2Nissan: string;
    JourneyModalP2Infiniti: string;
    JourneyModalP3Infiniti: string;
    JourneyModalP3Nissan: string;
    JourneyModalP4: string;
    JourneyModalLi1: string;
    JourneyModalLi2: string;
    JourneyModalLi3: string;
    JourneyModalLi4: string;
    JourneyModalHeader: string;
    JourneyStartingPointText: string;
    RouteSummaryStartLabel: string;
    RouteSummaryEndLabel: string;
    PoiSearchErrorBody: string;
    PoiSearchErrorTitle: string;
    MaxJourneys?: number;
    JourneySearchErrorLine1: string;
    JourneySearchErrorLine2: string;
  };
  setDirections: (waypoints: (string | null)[] | null) => void;
  resetMap: () => void;
  isLoading: boolean;
};

export default function MapWindowJourneyOverlay({
  labels,
  setDirections,
  resetMap,
  isLoading,
}: MapWindowJourneyOverlayProps) {
  const api = useApi();
  const { trackEvent } = useAnalytics();
  const [startPointInput, setStartPointInput] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [startPoint, setStartPoint] = useState<PointOfInterest | null>(null);
  const [endPointInput, setEndPointInput] = useState('');
  const [journeyNameInput, setJourneyNameInput] = useState('');
  const [endPoint, setEndPoint] = useState<PointOfInterest | null>(null);
  const [destinations, setDestinations] = useState([]);
  const [waypoints, setWaypoints] = useState<PointOfInterest[]>([]);
  const [currentView, setCurrentView] = useState('landing');
  const [destinationType, setDestinationType] = useState('start');
  const [thirdWayPointInput, setThirdWayPointInput] = useState('');
  const [thirdWayPoint, setThirdWayPoint] = useState<PointOfInterest | null>(null);
  const [fourthWayPointInput, setFourthWayPointInput] = useState('');
  const [fourthWayPoint, setFourthWayPoint] = useState<PointOfInterest | null>(null);
  const [fifthWayPointInput, setFifthWayPointInput] = useState('');
  const [fifthWayPoint, setFifthWayPoint] = useState<PointOfInterest | null>(null);
  const [thirdWaypointInputVisible, toggleThirdWaypointVisibility] = useState(false);
  const [fourthWaypointInputVisible, toggleFourthWaypointVisibility] = useState(false);
  const [fifthWaypointInputVisible, toggleFifthWaypointVisibility] = useState(false);
  const [updateJourney, setUpdateJourney] = useState(null);
  const journeys = useSelector(({ mapReducer }) => mapReducer.journeys);
  const folders = useSelector(({ mapReducer }) => mapReducer.folders);
  const routeSummary = useSelector(({ mapReducer }) => mapReducer.routeSummary);
  const selectedPointOfInterest = useSelector(({ mapReducer }) => mapReducer.selectedPointOfInterest);
  const dispatch = useDispatch();

  const {
    AddJourneyButton,
    AddWaypointButton,
    FinalRoute,
    JourneyBodyText,
    JourneyTitle,
    NameJourneyButton,
    NameJourneyText,
    ReverseRouteButton,
    RouteSummary,
    SelectWaypointTitle,
    SendToCarButton,
    StartOverButton,
    ViewRouteButton,
    JourneyInputPlaceholder,
    JourneyNameInputPlaceholder,
    JourneyModalP1,
    JourneyModalP2Infiniti,
    JourneyModalP2Nissan,
    JourneyModalP3Infiniti,
    JourneyModalP3Nissan,
    JourneyModalP4,
    JourneyModalLi1,
    JourneyModalLi2,
    JourneyModalLi3,
    JourneyModalLi4,
    JourneyModalHeader,
    JourneyStartingPointText,
    RouteSummaryStartLabel,
    RouteSummaryEndLabel,
    MaxJourneys,
    JourneySearchErrorLine1,
    JourneySearchErrorLine2,
  } = labels;

  useEffect(() => {
    resetMap();
  }, []);

  const waypointsConfig = {
    0: {
      setInput: setStartPointInput,
      setValue: setStartPoint,
      value: startPoint,
      type: 'start',
    },
    1: {
      setInput: setEndPointInput,
      setValue: setEndPoint,
      value: endPoint,
      type: 'end',
    },
    2: {
      setInput: setThirdWayPointInput,
      setValue: setThirdWayPoint,
      toggleVisibility: toggleThirdWaypointVisibility,
      value: thirdWayPoint,
      type: 'third',
    },
    3: {
      setInput: setFourthWayPointInput,
      setValue: setFourthWayPoint,
      toggleVisibility: toggleFourthWaypointVisibility,
      value: fourthWayPoint,
      type: 'fourth',
    },
    4: {
      setInput: setFifthWayPointInput,
      setValue: setFifthWayPoint,
      toggleVisibility: toggleFifthWaypointVisibility,
      value: fifthWayPoint,
      type: 'fifth',
    },
  };

  const renderJourneyBody = () => {
    if (currentView === 'landing' && !journeys.length) {
      return (
        <div className="map-window-destinations__body--journey">
          <div className="map-window-destinations__body--journey-title-body">
            <h1 className="map-window-destinations__body--header">{JourneyTitle}</h1>
            <p className="map-window-destinations__body--paragraph">{JourneyBodyText}</p>
          </div>
          <button
            onClick={() => {
              trackEvent('ServicesDestinations::JourneyPlanner::AddNewJourney-Clicked');
              setCurrentView('input');
            }}
            className="map-window-destinations__body--button"
          >
            {AddJourneyButton}
          </button>
        </div>
      );
    }

    if (currentView === 'landing' && journeys) {
      const maxJourneys = MaxJourneys || 5;
      const sendToCarButtonDisabled = journeys.length >= maxJourneys;
      return (
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'space-between',
            height: 400,
          }}
        >
          <div className="map-window-destinations__body">
            <h1 className="map-window-destinations__body--header">{JourneyTitle}</h1>
            <div className="map-window-destinations__body--journeys">{renderJourneys()}</div>
          </div>
          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'center',
            }}
          >
            <button
              disabled={sendToCarButtonDisabled}
              onClick={() => setCurrentView('input')}
              className="map-window-destinations__body--button"
            >
              {AddJourneyButton}
            </button>
          </div>
        </div>
      );
    }
  };

  const locationAddressString = (location: PointOfInterest) => {
    if (location) {
      const { street, city, state, postalCode, country } = location.geoAddress.address;
      let addrLine: string = [street, city, state, postalCode].filter(Boolean).join(' ');
      if (country) {
        addrLine = addrLine.concat(', ', country);
      }
      return addrLine;
    }

    return null;
  };

  const removeJourney = (journey: any) => {
    const { channelId, folderId } = journey;
    try {
      dispatch(setLoadingStatus(true));
      const journeyRemoved = api.deleteJourney(folderId, channelId);
      dispatch({ type: 'REMOVE_JOURNEY', channelId });
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setLoadingStatus(false));
    }
  };

  const handleEditJourneyClick = (journey: any) => {
    journey.waypoints.forEach((waypoint: PointOfInterest, index: number) => {
      const waypointToSet = waypointsConfig[index];
      if (!waypointToSet) {
        return;
      }
      const name = waypoint.name;
      waypointToSet.setInput(name);
      waypointToSet.setValue(waypoint);
      waypointToSet.toggleVisibility && waypointToSet.toggleVisibility(true);
    });
    setWaypoints(journey.waypoints);
    setJourneyNameInput(journey.channelName);
    setUpdateJourney(journey);
    setCurrentView('input');
  };

  const renderJourneys = () => {
    return journeys.map((journey: any) => {
      return (
        <div className="map-window-destinations__body--journey-item">
          <div>
            <p style={{ fontSize: 14 }}>{journey.channelName}</p>
          </div>
          <div>
            <button
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                outline: 'none',
                cursor: 'pointer',
              }}
              onClick={() => handleEditJourneyClick(journey)}
            >
              <FaEdit className="map-window-destinations__body--edit-button" />
            </button>
            <button
              onClick={() => removeJourney(journey)}
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                outline: 'none',
                cursor: 'pointer',
              }}
            >
              <img src={closeIcon} style={{ height: 20, width: 20 }} alt="" />
            </button>
          </div>
        </div>
      );
    });
  };

  const handleJourneyClick = async (type: JourneyType) => {
    const types = {
      start: startPointInput,
      end: endPointInput,
      third: thirdWayPointInput,
      fourth: fourthWayPointInput,
      fifth: fifthWayPointInput,
    };
    const searchTerm = types[type];

    if (searchTerm) {
      try {
        dispatch(setLoadingStatus(true));
        const res = await api.getPoiItems(searchTerm, 20);
        if (res.data) {
          const formattedDestinations = formatPins(res.data, dispatch);
          setDestinations(formattedDestinations);
          dispatch({
            type: 'SET_POINTS_OF_INTEREST',
            pointsOfInterest: formattedDestinations,
          });
          setCurrentView('destination select');
          setDestinationType(type);
        } else {
          setCurrentView('poiError');
        }
      } catch (err) {
        // TODO - IMPLEMENT ERROR HANDLING
        console.log(err);
      } finally {
        dispatch(setLoadingStatus(false));
      }
    }
  };

  const resetDestinations = () => {
    setStartPoint(null);
    setEndPoint(null);
    setThirdWayPoint(null);
    setFourthWayPoint(null);
    setFifthWayPoint(null);
    setStartPointInput('');
    setEndPointInput('');
    setThirdWayPointInput('');
    setFourthWayPointInput('');
    setFifthWayPointInput('');
    setCurrentView('input');
    toggleThirdWaypointVisibility(false);
    toggleFourthWaypointVisibility(false);
    toggleFifthWaypointVisibility(false);
    setJourneyNameInput('');
    setDirections(null);
  };

  const renderJourneyInputView = () => {
    const renderWaypointButtons = () => {
      if (startPoint && endPoint) {
        return (
          <div>
            {!fifthWaypointInputVisible && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <button
                  onClick={() => {
                    if (!thirdWaypointInputVisible) {
                      toggleThirdWaypointVisibility(true);
                    } else if (!fourthWaypointInputVisible) {
                      toggleFourthWaypointVisibility(true);
                    } else {
                      toggleFifthWaypointVisibility(true);
                    }
                  }}
                  className="map-window-destinations__body--button-waypoint"
                >
                  <FaPlusCircle />
                  {AddWaypointButton}
                </button>
              </div>
            )}
            <div
              style={{
                display: 'flex',
                flexFlow: 'row',
                justifyContent: 'space-between',
              }}
            >
              <button onClick={resetDestinations} className="map-window-destinations__body--button inverse">
                {StartOverButton}
              </button>
              <button
                onClick={() => {
                  const directions = waypoints.map((point) => locationAddressString(point));
                  setDirections(directions);
                  dispatch({
                    type: 'SET_POINTS_OF_INTEREST',
                    pointsOfInterest: [],
                  });
                  setCurrentView('route summary');
                }}
                className="map-window-destinations__body--button"
              >
                {ViewRouteButton}
              </button>
            </div>
          </div>
        );
      }
    };

    const renderClearButton = (index: number) => {
      const { setInput, setValue, value } = waypointsConfig[index];

      const handleClick = () => {
        setInput('');
        setValue(null);
      };
      const isVisible = value;

      if (!isVisible) return null;

      return (
        <button
          onClick={handleClick}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            outline: 'none',
            cursor: 'pointer',
          }}
        >
          <img src={closeIcon} className="map-window-journey__close-icon" alt="" />
        </button>
      );
    };

    const handleKeyPress = (e, journeyType: JourneyType) => {
      enterKeyPressed(e) && handleJourneyClick(journeyType);
    };

    const renderInput = ({ isVisible, setWayPointInput, type, value }: WaypointInputType, index: number) => {
      const inputLetter = {
        third: 'C',
        fourth: 'D',
        fifth: 'E',
      };

      if (isVisible) {
        return (
          <div className="map-window-destinations__body--input-wrapper">
            <div className="map-window-destinations__body--input-button">{inputLetter[type]}</div>
            <input
              className="map-window-destinations__body--input"
              type="text"
              placeholder={JourneyInputPlaceholder}
              value={value}
              onChange={(e) => {
                setWayPointInput(e.target.value);
              }}
              onKeyPress={(e) => {
                handleKeyPress(e, type);
              }}
            />
            <button
              className="map-window-destinations__body--input-search-button"
              onClick={() => {
                trackEvent('ServicesDestinations::JourneyPlanner::SearchLocation-Clicked');
                handleJourneyClick(type);
              }}
            >
              <CgSearch />
            </button>
            {renderClearButton(index)}
          </div>
        );
      }
    };

    const renderAdditionalWaypointInputs = () => {
      const waypoints: WaypointInputType[] = [
        {
          isVisible: thirdWaypointInputVisible,
          setWayPointInput: (value: string) => setThirdWayPointInput(value),
          type: 'third',
          value: thirdWayPointInput,
        },
        {
          isVisible: fourthWaypointInputVisible,
          setWayPointInput: (value: string) => setFourthWayPointInput(value),
          type: 'fourth',
          value: fourthWayPointInput,
        },
        {
          isVisible: fifthWaypointInputVisible,
          setWayPointInput: (value: string) => setFifthWayPointInput(value),
          type: 'fifth',
          value: fifthWayPointInput,
        },
      ];

      return waypoints.map((waypoint: WaypointInputType, index: number) => {
        return renderInput({ ...waypoint }, index + 2);
      });
    };

    if (currentView === 'input') {
      return (
        <div className="map-window-destinations__body max-height space-between">
          <div className="map-window-destinations__body center-content">
            <div className="map-window-destinations__body--input-wrapper no-mb">
              <div className="map-window-destinations__body--input-button">A</div>
              <input
                className="map-window-destinations__body--input"
                type="text"
                placeholder={JourneyInputPlaceholder}
                value={startPointInput}
                onChange={(e) => setStartPointInput(e.target.value)}
                onKeyPress={(e) => {
                  handleKeyPress(e, 'start');
                }}
              />
              <button
                className="map-window-destinations__body--input-search-button"
                onClick={() => handleJourneyClick('start')}
              >
                <CgSearch />
              </button>
              {renderClearButton(0)}
            </div>
            <span className="map-window-destinations__body--journey-disclaimer">{JourneyStartingPointText}</span>
            <div className="map-window-destinations__body--input-wrapper">
              <div className="map-window-destinations__body--input-button">B</div>
              <input
                className="map-window-destinations__body--input"
                type="text"
                placeholder={JourneyInputPlaceholder}
                value={endPointInput}
                onChange={(e) => setEndPointInput(e.target.value)}
                onKeyPress={(e) => {
                  handleKeyPress(e, 'end');
                }}
              />
              <button
                className="map-window-destinations__body--input-search-button"
                onClick={() => handleJourneyClick('end')}
              >
                <CgSearch />
              </button>
              {renderClearButton(1)}
            </div>
            {renderAdditionalWaypointInputs()}
          </div>
          <div className="map-window-destinations__body center-content">{renderWaypointButtons()}</div>
        </div>
      );
    }
  };

  const handleReverseRoute = () => {
    const newEndPoint = waypoints[0];
    const newStartPoint = waypoints[waypoints.length - 1];

    setStartPoint(newStartPoint);
    setEndPoint(newEndPoint);

    const revertedWaypoints = waypoints.reverse().map((point) => {
      return locationAddressString(point);
    });

    setDirections(revertedWaypoints);
  };

  const onMouseEnterOrLeaveHandler = (pinId, poiIndex, icon) => {
    dispatch({
      type: 'SET_SELECTED_POINT_OF_INTEREST',
      selectedPointOfInterest: pinId,
      poiIndex,
      icon,
    });
  };

  const renderDestinationOptions = () => {
    const handleClick = (destination: PointOfInterest) => {
      const { name } = destination;
      const configKey = Object.keys(waypointsConfig).find((key) => waypointsConfig[key].type === destinationType);
      const maxLength = Math.max(...Object.keys(waypointsConfig));
      const waypointToSet = waypointsConfig[configKey];

      waypointToSet.setInput(name);
      waypointToSet.setValue(destination);

      const updatedWaypoints = waypoints.slice(0, maxLength);
      updatedWaypoints[configKey] = destination;

      setCurrentView('input');
      dispatch({
        type: 'SET_POINTS_OF_INTEREST',
        pointsOfInterest: [...updatedWaypoints],
      });
      setWaypoints([...updatedWaypoints]);
      setDestinations([]);
    };

    if (destinations.length) {
      return destinations.map((destination: PointOfInterest, index) => {
        const { street, city, state, postalCode, country } = destination.geoAddress.address;
        const address = `${street}, ${city}, ${state} ${postalCode}`;
        const addrLine = [street, city, state, postalCode, country].filter(Boolean).join(', ');
        const backgroundColor = destination.pinId === selectedPointOfInterest ? '#E3E2DF' : 'white';
        return (
          <div
            onMouseEnter={() => onMouseEnterOrLeaveHandler(destination.pinId, index, selectedPin)}
            onMouseLeave={() => onMouseEnterOrLeaveHandler(null, index, bluePin)}
            id={destination.pinId}
            onClick={() => handleClick(destination)}
            style={{
              width: '90%',
              padding: 10,
              backgroundColor: backgroundColor,
              borderBottom: '1px solid #C1C1C1',
              outline: 'none',
              textAlign: 'left',
              cursor: 'pointer',
              margin: '0px auto',
            }}
          >
            {address !== destination.name && (
              <h3 className="map-window-destinations__poi-list--item-left-line-1">{destination.name}</h3>
            )}
            <h3 className="map-window-destinations__poi-list--item-left-line-2">{street}</h3>
            <h3 className="map-window-destinations__poi-list--item-left-line-2">{addrLine}</h3>
          </div>
        );
      });
    }
  };

  const renderPoiError = () => {
    if (currentView === 'poiError') {
      return (
        <div className="poi-error-wrapper">
          <button className="poi-error-button" onClick={() => setCurrentView('input')}>
            X
          </button>
          <div className="poi-error-text-wrapper">
            <p className="poi-error-text">{JourneySearchErrorLine1}</p>
            <p className="poi-error-text">{JourneySearchErrorLine2}</p>
          </div>
        </div>
      );
    }
  };

  const renderDestinationSelection = () => {
    if (currentView === 'destination select') {
      return (
        <div className="map-window-destinations__body-journey">
          <div className="map-window-destinations__body-journey--header-container">
            <h1 className="map-window-destinations__body--header">{SelectWaypointTitle}</h1>
            <button className="poi-error-button" onClick={() => setCurrentView('input')}>
              <FaTimes />
            </button>
          </div>
          <div id="poi_list" className="map-window-journey_poi-list">
            {renderDestinationOptions()}
          </div>
        </div>
      );
    }
  };

  const updateJourneys = () => {
    return new Promise(async (res, rej) => {
      try {
        dispatch(setLoadingStatus(true));
        const { data: folders } = await api.getFolders();

        dispatch({ type: 'SET_FOLDERS', folders });
        const journeysFolder = folders.find((folder: Folder) => folder.folderName === 'Journey Planner');
        const channels = journeysFolder.channels.map((channel) => channel.channelId);
        const updatedJourneysArray: Array<object> = [];

        await Promise.all(
          channels.map(async (channelId: string) => {
            const { data: journey } = await api.getJourney(journeysFolder.folderId, channelId);
            updatedJourneysArray.push(...journey);
          }),
        );

        res(updatedJourneysArray);
      } catch (err) {
        rej(err);
      } finally {
        dispatch(setLoadingStatus(false));
      }
    });
  };

  const sendToCar = async () => {
    trackEvent('ServicesDestinations::JourneyPlanner::SendJourney-Clicked');
    const setWaypoints = () => {
      const waypointsWithId = [...waypoints];

      const getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
      };

      // There is no id generated from bing maps but our api requires it
      waypointsWithId.forEach((waypoint: PointOfInterest) => {
        waypoint.id = getRandomInt(100000, 999999).toString();
        // XXX THIS IS TEMP UNTIL RTS FIXES THE COUNTRY BUG XXX
        switch (waypoint.geoAddress.address.country) {
          case 'USA':
            waypoint.geoAddress.address.country = 'US';
            break;
          case 'Mexico':
            waypoint.geoAddress.address.country = 'MX';
            break;
        }
      });

      return waypointsWithId;
    };

    if (updateJourney) {
      updateJourney.waypoints = setWaypoints();
      updateJourney.channelName = journeyNameInput;

      try {
        dispatch(setLoadingStatus(true));
        const res = await api.updateJourney(updateJourney);
        const updatedJourneys = await updateJourneys();
        dispatch({ type: SET_JOURNEYS, journeys: updatedJourneys });
        setShowConfirmationModal(true);
        setUpdateJourney(null);
      } catch (err) {
        console.log('ERROR', err);
      } finally {
        dispatch(setLoadingStatus(false));
      }
    } else {
      const journeysFolder = folders.find((folder: Folder) => folder.folderName === 'Journey Planner');
      if (journeysFolder) {
        const { folderId, folderName } = journeysFolder;
        const waypoints = setWaypoints();
        const channelName = journeyNameInput;

        const journey = {
          folderId,
          folderName,
          waypoints,
          channelName,
        };

        try {
          dispatch(setLoadingStatus(true));
          const res = await api.addJourney(journey);
          const updatedJourneys = await updateJourneys();
          dispatch({ type: SET_JOURNEYS, journeys: updatedJourneys });
          setShowConfirmationModal(true);
        } catch (err) {
          console.log('ERROR', err);
        } finally {
          dispatch(setLoadingStatus(false));
        }
      }
    }
  };

  const convertSecondsToTime = (totalSeconds: number) => {
    let formattedTime = '';
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds - hours * 3600) / 60);

    if (hours > 0) {
      formattedTime = hours + ' hr ';
    }
    if (minutes > 0) {
      if (hours > 0) {
        formattedTime += minutes + ' min ';
      } else {
        formattedTime = minutes + ' min ';
      }
    }

    return formattedTime;
  };

  const renderRouteDetails = () => {
    if (routeSummary) {
      const { via, distance, timeWithTraffic, departureTime } = routeSummary;
      const routeDuration = convertSecondsToTime(timeWithTraffic);

      return (
        <div className="map-window-destinations__body--info-summary flex-space-between">
          <div>
            <p>{via}</p>
            <span>{departureTime}</span>
          </div>
          <div>
            <p>{routeDuration}</p>
            <span>{distance}</span>
          </div>
        </div>
      );
    }

    return null;
  };

  const renderRouteSummaryView = () => {
    if (currentView === 'route summary') {
      return (
        <div className="map-window-destinations__body--journey flex-align-start">
          <h1 className="map-window-destinations__body--header no-ml">{RouteSummary}</h1>
          <div className="map-window-destinations__body--route">
            <span className="map-window-destinations__body--gray">{RouteSummaryStartLabel}</span>{' '}
            <span>{`${waypoints[0]?.name}`}</span>
          </div>
          <div className="map-window-destinations__body--route">
            <span className="map-window-destinations__body--gray">{RouteSummaryEndLabel}</span>{' '}
            <span>{`${waypoints[waypoints.length - 1]?.name}`}</span>
          </div>
          <span className="map-window-destinations__body--final-route">{FinalRoute}</span>
          {renderRouteDetails()}
          <button className="map-window-destinations__body--reverse-route" onClick={handleReverseRoute}>
            {ReverseRouteButton}
          </button>
          <h1 className="map-window-destinations__body--header no-ml">{NameJourneyButton}</h1>
          <p className="map-window-destinations__body--gray mb-1rem">{NameJourneyText}</p>
          <input
            className="map-window-destinations__body--input"
            type="text"
            placeholder={JourneyNameInputPlaceholder}
            value={journeyNameInput}
            onChange={(e) => setJourneyNameInput(e.target.value)}
            onKeyPress={(e) => {
              enterKeyPressed(e) && sendToCar();
            }}
          />
          <div className="flex-space-between">
            <button onClick={() => setCurrentView('input')} className="map-window-destinations__body--button inverse">
              {StartOverButton}
            </button>
            <button disabled={!journeyNameInput} onClick={sendToCar} className="map-window-destinations__body--button">
              {SendToCarButton}
            </button>
          </div>
          <ModalContainer
            show={showConfirmationModal}
            header={{ text: JourneyModalHeader, position: 'center' }}
            onCloseHandler={() => setShowConfirmationModal(false)}
          >
            <div className="map-window-destinations__confirmation--disclaimer">
              <p className="modal-text-bold modal-text-padding-bottom">{JourneyModalP1}</p>
              <p>{JourneyModalP2Nissan || JourneyModalP2Infiniti}</p>
              <ul>
                <li>{JourneyModalLi1}</li>
                <li>{JourneyModalLi2}</li>
              </ul>

              <p>{JourneyModalP3Nissan || JourneyModalP3Infiniti}</p>
              <p className="modal-text-bold">{JourneyModalP4}</p>
              <ol>
                <li>{JourneyModalLi3}</li>
                <li>{JourneyModalLi4}</li>
              </ol>

              <div className="text-center">
                <button
                  className="map-window-destinations__body--button"
                  onClick={() => {
                    setShowConfirmationModal(false);
                    resetDestinations();
                    setCurrentView('landing');
                    setDirections(null);
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </ModalContainer>
        </div>
      );
    }
  };
  if (isLoading) return <Loader style={{ height: '100%' }} />;
  return (
    <div className="max-height">
      {renderJourneyBody()}
      {renderJourneyInputView()}
      {renderDestinationSelection()}
      {renderRouteSummaryView()}
      {renderPoiError()}
    </div>
  );
}
