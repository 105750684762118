import React, { ReactNode } from 'react';

import './Grid.css';

type GridProps = {
  className?: string;
  columns?: '1' | '2' | '3' | '4';
  children?: ReactNode;
};

function Grid({ className, columns = '2', children }: GridProps) {
  return <div className={`${className} grid-column-${columns}`}>{children}</div>;
}

export default Grid;
