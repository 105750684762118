import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { EligiblePackageInfo, PackageSubscription, SubscribedPackageInfo } from '../../Types';
import Tab from '../../../components/Tab';
import { Container } from '../../../styled-components/globalStyles';
import ViewPackages, { ViewPackagesProps } from './ViewPackages';
import { getPaidPackage } from '../../utils';
import { extractVariants } from './utils';
import { ContentMap } from '../..';
import { AggregatedFlowContext } from '@components-lib/Router/flows/componentFlow/Types';
import {
  EventDataBuilder,
  EventType,
  closeAnalyticsBracket,
  openAnalyticsBracket,
  sendAnalyticsEvent,
} from '@lib-components/Analytics';

export type PaidPackagesProps = {
  packages: EligiblePackageInfo[];
  removePaidPackages: () => void;
  termsLabel: ContentMap;
  tenantId: string;
  subscribedPackages: SubscribedPackageInfo[];
  packageSubscriptions: PackageSubscription[];
  flowMachineContext: AggregatedFlowContext;
} & Omit<ViewPackagesProps, 'packages'>;

const PaidPackages = ({
  packages,
  tenantId,
  termsLabel,
  subscribedPackages,
  packageSubscriptions,
  removePaidPackages,
  flowMachineContext,
  ...viewPackagesProps
}: PaidPackagesProps) => {
  // Extract package variants
  const variants = React.useMemo(
    () => extractVariants(subscribedPackages, packages, tenantId),
    [subscribedPackages, packages, tenantId],
  );

  const selectedVariantIndex = () => {
    const current = getPaidPackage(packageSubscriptions, subscribedPackages);
    const keys = Object.keys(variants);
    return current ? keys.map((k) => Number(k)).indexOf(current.variant.initialTerm) : keys.length - 1;
  };

  const [selectedTab, setSelectedTab] = useState<number>(selectedVariantIndex());

  const packagesByVariant = useMemo(() => Object.entries(variants), [variants]);

  if (Object.keys(variants).length === 0) {
    return null;
  }

  const fireListingEvent = () => {
    openAnalyticsBracket(flowMachineContext);
    sendAnalyticsEvent(
      new EventDataBuilder(EventType.ProductListingDisplayedEvent).withArgs(packagesByVariant[selectedTab][1]),
    );
    closeAnalyticsBracket();
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(fireListingEvent, [selectedTab]);

  const handleTabChange = (index: number) => {
    // clear selections
    removePaidPackages();
    // select tab
    setSelectedTab(index);
  };

  return (
    <Container>
      <Tab active={selectedTab} onTabChanged={handleTabChange}>
        {packagesByVariant.map(([key, value], index) => (
          <Tab.TabPane key={key} tabTitle={termsLabel[key]}>
            <ViewPackages
              {...viewPackagesProps}
              subscribedPackages={subscribedPackages}
              packageSubscriptions={packageSubscriptions}
              packages={value}
            />
          </Tab.TabPane>
        ))}
      </Tab>
    </Container>
  );
};

export default PaidPackages;
