import React from 'react';
import { formatAnalyticsEventName } from '@cv/portal-common-lib';
import { Button } from '@components-lib';
import { stringReplaceWithJSX } from '@utils/stringRegularReplace';
import styles from './CancelSubscription.module.css';

type CancelSubscriptionOverviewProps = {
  handleContinue: () => void;
  handleContinueDowngrade?: () => void;
  handleCancel: () => void;
  labels: {
    [index: string]: { primary: string; analyticsEventName?: string };
  };
  isEligibleForFreeTrial?: boolean;
};

export const CancelSubscriptionOptions = ({
  handleContinue,
  handleContinueDowngrade,
  handleCancel,
  isEligibleForFreeTrial,
  labels,
}: CancelSubscriptionOverviewProps) => {
  const {
    title,
    beforeYouGo,
    saveMoneyBy,
    cancelDateLater,
    contactCustomerCare,
    importantCancellationInfo,
    byClickingCancel,
    continueButtonSecond,
    keepSubscriptionButtonSecond,
    freeTrialAvailable,
  } = labels;

  const freeTrialAvailableLabel = stringReplaceWithJSX(freeTrialAvailable.primary, (value) => (
    <button className={styles['link-button']} onClick={handleContinueDowngrade}>
      {value}
    </button>
  ));

  return (
    <div className={styles['container']}>
      <h1 className="title">{title.primary}</h1>

      <div className={styles['options-container']}>
        <p className="before-you-go">{beforeYouGo.primary}</p>
        {isEligibleForFreeTrial ? (
          <p className="free-trial-available">{freeTrialAvailableLabel}</p>
        ) : (
          <p className="save-money">{saveMoneyBy.primary}</p>
        )}
        <p className="cancel-date-later">{cancelDateLater.primary}</p>
        <p className="contact">{contactCustomerCare.primary}</p>
        <p className="clicking-cancel">{byClickingCancel.primary}</p>
        <p className="cancel-info">{importantCancellationInfo.primary}</p>
      </div>
      <div className={styles['action-button-wrapper']}>
        <Button
          onClick={handleCancel}
          data-analyticseventname={formatAnalyticsEventName(keepSubscriptionButtonSecond?.analyticsEventName)}
        >
          {keepSubscriptionButtonSecond.primary}
        </Button>
        <Button
          variant="outlined"
          onClick={handleContinue}
          data-analyticseventname={formatAnalyticsEventName(continueButtonSecond?.analyticsEventName)}
        >
          {continueButtonSecond.primary}
        </Button>
      </div>
    </div>
  );
};
