import { send, assign } from 'xstate';
import { EligiblePackageInfo } from '@manageSubscription/Types';
import { getPaidPackage, isPaidPackage, getEligibleAddOns } from '@manageSubscription/utils';
import { ComponentRoutes } from '../../../types';
import { ComponentActions, ComponentFlowStateConfig, ManageAddonsFlowContext } from '../Types';
import { FlowEventName } from '@lib-appRouter/flowTypes';

export const manageAddonsFlow: ComponentFlowStateConfig<ManageAddonsFlowContext> = () => ({
  initial: 'pre',
  id: ComponentRoutes.manageAddOns,
  states: {
    pre: {
      entry: 'getEligibleAddons',
      always: [{ target: 'navigateForward', cond: 'hasNoEligibleAddons' }, 'idle'],
    },
    idle: {
      entry: [
        send({
          type: 'PUSH_HISTORY',
          data: { componentRoute: ComponentRoutes.manageAddOns },
        }),
      ],
      on: {
        [FlowEventName.NAVIGATE_FORWARD]: 'navigateForward',
        [FlowEventName.NAVIGATE_BACK]: 'navigateBack',
      },
    },
    navigateForward: {
      entry: 'navigateForward',
    },
    navigateBack: {
      entry: 'navigateBack',
    },
  },
});

export const actions: ComponentActions<ManageAddonsFlowContext> = {
  /**
   * Extract AddOns of corresponding selected regular package term (ex. 1 or 12 months)
   */
  getEligibleAddons: assign({
    eligibleAddOns: (context) => {
      const { eligiblePackages, packageSubscriptions, subscribedPackages } = context.flowSessionStorage;
      if (!eligiblePackages?.length) return [];
      const eligibleAddOns = getEligibleAddOns(eligiblePackages);
      const addOns = Array<EligiblePackageInfo>();
      const {
        variant: { id: parentId, initialTerm: parentTerm },
      } = getPaidPackage(packageSubscriptions, subscribedPackages);
      eligibleAddOns.forEach((pkg) => {
        pkg.variants.forEach((variant) => {
          if ((isPaidPackage(variant) && variant.initialTerm !== parentTerm) || variant.renewalTerm !== parentTerm)
            return;
          const vPackage = { ...pkg, parentId, variant: variant } as EligiblePackageInfo;
          addOns.push(vPackage);
        });
      });
      return addOns;
    },
  }),
};
