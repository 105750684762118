import { assign, send, raise } from 'xstate';
import { ComponentRoutes } from '../../../types';
import { ComponentFlowStateConfig, ComponentActions, ShowAuthFormFlowContext } from '../Types';
import { FlowEventName } from '../../../flowTypes';
import { ActionTypes } from '../../../flowSessionStorage';

export const confirmOrderFlow: ComponentFlowStateConfig<ShowAuthFormFlowContext> = () => ({
  initial: 'pre',
  id: ComponentRoutes.confirmOrder,
  states: {
    pre: {
      always: [{ target: 'shouldShowAuthForm', cond: 'isAuthCodeFunctionality' }, { target: 'idle' }],
    },
    shouldShowAuthForm: {
      entry: 'setLoading',
      invoke: {
        id: 'getAccountDetails',
        src: ({ subscriptionProps }) =>
          subscriptionProps.getAccountDetails(
            subscriptionProps.locale,
            subscriptionProps.userDetails.userId,
            subscriptionProps.accessToken,
          ),
        onDone: [
          {
            id: 'hideForm',
            target: 'idle',
            cond: 'isAuthFormHidden',
            actions: ['unsetLoading', 'hideForm'],
          },
          {
            id: 'error',
            target: 'idle',
            cond: 'isAuthFormError',
            actions: ['unsetLoading', 'showForm', 'setError'],
          },
          {
            target: 'idle',
            actions: ['unsetLoading', 'showForm'],
          },
        ],
        onError: {
          target: 'idle',
          actions: ['unsetLoading', 'hideForm'],
        },
      },
    },
    idle: {
      entry: [
        raise({
          type: 'PUSH_HISTORY',
          data: { componentRoute: ComponentRoutes.confirmOrder },
        }),
        raise({
          type: 'RESET_FLOW_LAST_PAGE',
        }),
        (context) => context.subscriptionProps.getSubscriptionInfo?.(context.subscriptionProps),
      ],
      on: {
        [FlowEventName.NAVIGATE_FORWARD]: { target: 'confirmed' },
      },
    },
    confirmed: {
      entry: ['resetState', 'clearCache', (context) => context.subscriptionProps.onDone?.()],
    },
  },
});

export const actions: ComponentActions<ShowAuthFormFlowContext> = {
  resetState: send({ type: FlowEventName.SET_SESSION_STORAGE, action: { type: ActionTypes.ResetState } }),

  clearCache: (context) => {
    context.queryClient.clear();
    context.clearSubscriptionPropsCache();
  },

  showForm: assign({ showAuthCodeForm: true }),
  hideForm: assign({ showAuthCodeForm: false }),
  setError: assign({ isAuthStatusError: true }),
};
