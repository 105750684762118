import React from 'react';
import useCpsRtsEntitlements from '@api/queries/useCpsRtsEntitlements';
import DisplayCheckbox from './DisplayCheckbox';
import OptSms, { OptSmsProps } from './OptSms';

const OptSmsCheckbox = (props: OptSmsProps) => {
  const { hasCpsEntitlement } = useCpsRtsEntitlements();

  if (hasCpsEntitlement('marketing-preference')) {
    return (
      <DisplayCheckbox>
        <OptSms {...props} />
      </DisplayCheckbox>
    );
  }

  return null;
};

export default OptSmsCheckbox;
