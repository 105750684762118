import { getAllPaymentMethods } from '@cv/portal-cps-lib';
import {
  GetAllPaymentMethodsRequest,
  GetAllPaymentMethodsResponse,
} from '@cv/portal-cps-lib/payment/payment-methods/models';
import { CombinedPaymentMethods, PaymentOptions } from '@lib-payment/Types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useMemo } from 'react';
import { SubscriptionProps } from '@manageSubscription';
import { options } from '../../commonService';

export const listAllPaymentMethods = (props: SubscriptionProps): Promise<GetAllPaymentMethodsResponse> => {
  const {
    userDetails: { userId },
  } = props;
  const request: GetAllPaymentMethodsRequest = {
    ...options(props),
    pathParams: { userId },
  };
  return getAllPaymentMethods(request).then((res) => res.data);
};

const useGetPaymentMethods = (
  subscriptionProps: SubscriptionProps,
  options?: UseQueryOptions<GetAllPaymentMethodsResponse>,
) => {
  return useQuery<GetAllPaymentMethodsResponse>(
    ['paymentMethods'],
    () => listAllPaymentMethods(subscriptionProps),
    options,
  );
};

export const useGetCombinedPaymentMethods = (
  subscriptionProps: SubscriptionProps,
  options?: UseQueryOptions<GetAllPaymentMethodsResponse>,
) => {
  const response = useGetPaymentMethods(subscriptionProps, options);

  const updatedData = useMemo(
    () =>
      [
        ...(response.data?.creditCard || []),
        ...(response.data?.paypal.map((ppDetails) => ({
          cardType: PaymentOptions.PAYPAL,
          ...ppDetails,
        })) || []),
      ] as CombinedPaymentMethods,
    [response.data],
  );

  return useMemo(() => ({ ...response, data: updatedData }), [response]);
};

export default useGetPaymentMethods;
