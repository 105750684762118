import { createMachine, spawn } from 'xstate';
import { assign, send, raise } from 'xstate/lib/actions';
import { getStorageData } from './flowUtils';
import { conditions } from './stateMachineConditions';
import { ApplicationFlowEvent, FlowEventName } from './flowTypes';
import { ComponentRoutes, FlowActions } from './types';
import { actions as ManageTrialPackagesActions } from './flows/componentFlow/common/ManageTrialPackages';
import { actions as ManagePromoPackagesActions } from './flows/componentFlow/common/ManagePromoPackages';
import { actions as ManagePromoCodePackagesActions } from './flows/componentFlow/common/ManagePromoCodePackages';
import { actions as ManagePaidPackagesActions } from './flows/componentFlow/common/ManagePaidPackages';
import { actions as ManageAddonsActions } from './flows/componentFlow/common/ManageAddOns';
import { actions as ReviewDowngradeOrderActions } from './flows/componentFlow/common/ReviewDowngradeOrder';
import { actions as InfoPageActions } from './flows/componentFlow/common/InformationPageSubscription';
import { actions as ConfirmOrderActions } from './flows/componentFlow/common/ConfirmOrder';
import { SESSION_STORAGE_KEY } from '@manageSubscription/utils/constants';
import { isEmpty, merge } from 'lodash';
import { InitialState, InitialStateType } from '@manageSubscription/Types';
import rootReducer from './flowSessionStorage/rootReducer';
import { buildEligiblePackages, buildSubscribedPackages } from '@manageSubscription/builders';
import { ActionTypes } from './flowSessionStorage';
import { AggregatedFlowContext } from './flows/componentFlow/Types';
import { getFlowCompoundState, Middleware } from './flowMiddleware';
import { getEligibleTrialPackages } from '@manageSubscription/utils';
import { invalidateCache } from '@manageSubscription/utils/commonUtils';
import { FetchMachineEvent, fetchPackagesMachine } from './fetchMachine';
import { SPINNER_TYPES } from '../components/Spinner/Spinner';
import { configs } from '../configs';

const getDirectStateEventName = (route: ComponentRoutes) => `direct_nav_${route}`;

const createDirectStateEvents = (middleware: Middleware) => {
  return {
    ...[
      ...(Object.keys(getFlowCompoundState(middleware).states) as ComponentRoutes[]),
      ComponentRoutes.checkIfEmailIsOnFile,
    ].reduce(
      (events, route) => ({
        ...events,
        [getDirectStateEventName(route)]: { target: `#${route}` },
      }),
      {},
    ),
  };
};

export const applicationFlowMachineInitialContext = {
  clearSubscriptionPropsCache: invalidateCache,
  flowSessionStorage: undefined,
  history: undefined,
  queryClient: undefined,
  loading: {
    isLoading: true,
    spinnerType: configs.initialSpinner,
  },
  flow: undefined,
  subscriptionProps: undefined,
  content: undefined,
} as unknown as AggregatedFlowContext;

export const createApplicationFlowMachine = (middleware: Middleware) => {
  return createMachine<AggregatedFlowContext, ApplicationFlowEvent>(
    {
      predictableActionArguments: true,
      id: 'applicationFlow',
      initial: 'idle',
      context: { ...applicationFlowMachineInitialContext },
      states: {
        idle: {
          entry: [
            assign({
              fetchPackagesMachineRef: () => spawn(fetchPackagesMachine, 'fetch-packages'),
            }),
            'getFlowDataFromSessionStorage',
            'getEligibleTrialPackages',
          ],
          on: {
            SET_SUBSCRIPTION_PROPS: {
              actions: [
                'assignSubscriptionProps',
                'saveSubscriptionProps',
                FetchMachineEvent.updateSubscriptionProps(),
              ],
              target: 'pre',
            },
          },
        },
        pre: {
          always: [{ target: 'global', cond: 'skipFetchingPackages' }, { target: 'fetch', cond: 'hasToken' }, 'global'],
          exit: FlowEventName.UNSET_LOADING,
        },
        fetch: {
          entry: [FlowEventName.SET_LOADING, FetchMachineEvent.fetchPackages()],
          on: {
            onFetchPackages: {
              target: 'global',
            },
            onFetchFailed: {
              target: 'global',
              actions: () => console.error('flowMachine on fetch failed'),
            },
          },
          exit: FlowEventName.UNSET_LOADING,
        },
        global: {
          always: [{ target: 'skipRoute', actions: 'skipToSavedRoute', cond: 'hasSkipToRouteProperty' }],
          initial: ComponentRoutes.checkIfEmailIsOnFile,
          states: {
            [ComponentRoutes.checkIfEmailIsOnFile]: {
              ...middleware.getComponentFlow(ComponentRoutes.checkIfEmailIsOnFile),
              on: {
                [FlowActions.proceed]: '#main',
              },
            },
          },
        },
        skipRoute: {},
        main: {
          id: 'main',
          entry: FlowEventName.UNSET_LOADING,
          ...getFlowCompoundState(middleware),
        },
      },
      on: {
        RESET_FLOW_LAST_PAGE: {
          actions: 'resetLastPage',
        },
        FETCH_PACKAGES_GLOBAL: {
          actions: 'setFetchPackagesData',
        },
        UPDATE_CONTEXT: {
          actions: 'setContextsAndHistory',
        },
        PUSH_HISTORY: {
          actions: 'pushHistory',
        },
        NAVIGATE_BACK: {
          actions: 'navigateBack',
        },
        SET_SUBSCRIPTION_PROPS: {
          actions: ['assignSubscriptionProps', 'saveSubscriptionProps', FetchMachineEvent.updateSubscriptionProps()],
        },
        [FlowEventName.SET_SESSION_STORAGE]: {
          actions: ['dispatchSessionStorageEvent', 'saveSessionStorage'],
        },
        setLoading: {
          actions: 'setLoading',
        },
        unsetLoading: {
          actions: 'unsetLoading',
        },
        onFetchFailed: { actions: [FlowEventName.NAVIGATE_TO_ERROR, FlowEventName.UNSET_LOADING] },
        navigateDirect: { actions: 'navigateDirect' },
        ...createDirectStateEvents(middleware),
      },
    },
    {
      actions: {
        resetLastPage: () => sessionStorage.removeItem(SESSION_STORAGE_KEY.FLOW_LAST_PAGE),
        setContextsAndHistory: assign({
          content: (_, event) => event.data.content,
          queryClient: (_, event) => event.data.queryClient,
          history: (_, event) => event.data.history,
        }),
        assignSubscriptionProps: assign((context, event) => ({
          subscriptionProps: merge({}, context.subscriptionProps, event.data),
        })),
        saveSubscriptionProps: (_, event) => {
          return sessionStorage.setItem(
            SESSION_STORAGE_KEY.SUBSCRIPTION,
            JSON.stringify(merge(getStorageData(SESSION_STORAGE_KEY.SUBSCRIPTION), event.data)),
          );
        },
        dispatchSessionStorageEvent: assign((context, event) => ({
          flowSessionStorage: rootReducer(context.flowSessionStorage, event.action),
        })),
        saveSessionStorage: (context) => {
          return sessionStorage.setItem(SESSION_STORAGE_KEY.FLOW, JSON.stringify(context.flowSessionStorage));
        },
        setFetchPackagesData: assign((context, event) => {
          let tempFlowSessionStorage = context.flowSessionStorage;
          const eligiblePackages = event.data?.fetchPackagesData?.eligiblePackages;
          const subscribedPackages = event.data?.fetchPackagesData?.subscribedPackages;
          if (eligiblePackages) {
            tempFlowSessionStorage = rootReducer(tempFlowSessionStorage, {
              type: ActionTypes.AddEligiblePackages,
              payload: buildEligiblePackages(eligiblePackages),
            });
          }
          if (subscribedPackages) {
            tempFlowSessionStorage = rootReducer(tempFlowSessionStorage, {
              type: ActionTypes.AddSubscribedPackages,
              payload: buildSubscribedPackages(subscribedPackages),
            });
          }
          return {
            flowSessionStorage: tempFlowSessionStorage,
          };
        }),
        skipToSavedRoute: raise((context) => ({ type: 'navigateDirect', route: context.skipToRoute })),
        navigateDirect: send((_, event) => ({ type: getDirectStateEventName(event.route) })),
        pushHistory: (context, event) => {
          const currentRoute = context.history?.location.pathname;
          context.history?.push(event.data.componentRoute, { from: currentRoute });
        },
        setLoading: assign({
          loading: (_, event) => ({
            isLoading: true,
            spinnerType: event?.data?.type || SPINNER_TYPES.TRANSLUCENT,
          }),
        }),
        unsetLoading: assign({
          loading: (_) => ({
            isLoading: false,
            spinnerType: SPINNER_TYPES.TRANSLUCENT,
          }),
        }),
        getFlowDataFromSessionStorage: assign({
          flowSessionStorage: () => {
            const flowStorageData = getStorageData(SESSION_STORAGE_KEY.FLOW) as InitialStateType;
            return isEmpty(flowStorageData) ? InitialState : flowStorageData;
          },
        }),
        getEligibleTrialPackages: assign({
          eligibleTrialPackages: (context) => {
            const { eligiblePackages, subscribedPackages } = context.flowSessionStorage;
            return getEligibleTrialPackages(eligiblePackages, subscribedPackages);
          },
        }),
        [FlowEventName.NAVIGATE_BACK]: send(FlowActions.return),
        [FlowEventName.NAVIGATE_FORWARD]: send(FlowActions.proceed),
        [FlowEventName.NAVIGATE_TO_CANCEL]: send(FlowActions.cancel),
        [FlowEventName.NAVIGATE_TO_SKIP]: send(FlowActions.skip),
        [FlowEventName.NAVIGATE_TO_ERROR]: send({
          type: 'PUSH_HISTORY',
          data: { componentRoute: ComponentRoutes.error },
        }),
        ...ManageTrialPackagesActions,
        ...ManagePromoPackagesActions,
        ...ManagePromoCodePackagesActions,
        ...ManagePaidPackagesActions,
        ...ManageAddonsActions,
        ...ReviewDowngradeOrderActions,
        ...InfoPageActions,
        ...ConfirmOrderActions,
      },
      guards: {
        ...conditions,
      },
    },
  );
};
