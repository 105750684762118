// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  background-color: var(--theme-color-background);
  color: var(--theme-color-text);
  display: flex;
  flex: 1;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/components/App/App.css"],"names":[],"mappings":"AAAA;EACE,+CAA+C;EAC/C,8BAA8B;EAC9B,aAAa;EACb,OAAO;EACP,sBAAsB;AACxB","sourcesContent":[".App {\n  background-color: var(--theme-color-background);\n  color: var(--theme-color-text);\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
