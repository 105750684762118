import React, { useState, useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import useMarketingPreference from '@api/queries/useMarketingPreference';
import { FormValues } from '@lib-appRouter/flows/componentFlow/Types';
import { get } from '@components/GlobalPreferences/GlobalPreferences';
import Field from '@components/Field';
import InputCheckbox from '@components/InputCheckbox';
import DisplayCheckbox from './DisplayCheckbox';

export type FlowSmsProps = {
  isSubmitting: boolean;
};

export const FlowSms = ({ isSubmitting }: FlowSmsProps) => {
  const [checked, setChecked] = useState(false);
  const {
    values: { primaryPhone: phone = '', email },
    errors,
  } = useFormikContext<FormValues>();

  const { createPreference, readPreference, readError, data, isLoading } = useMarketingPreference({
    immediatelyRead: false,
    smsNumber: phone,
  });

  const phoneValid = useMemo(() => !!phone && !errors.primaryPhone, [phone, errors]);

  useEffect(() => {
    const needToRead = data === undefined && !readError;

    if (needToRead && phoneValid) {
      readPreference();
    }
  }, [data, readError, phoneValid, readPreference]);

  useEffect(() => {
    if (isSubmitting && checked && !readError) {
      createPreference({ optIn: checked, phone, email });
    }
  }, [isSubmitting, checked, createPreference, phone, email, readError]);

  if (readError || !phoneValid || isLoading) {
    return null;
  }

  const rightLabel = get('smsCheckboxRightLabel') || '';

  return (
    <Field>
      <InputCheckbox checked={checked} onChange={() => setChecked(!checked)} placeholder={rightLabel} />
    </Field>
  );
};

const FlowSmsCheckbox = (props: FlowSmsProps) => (
  <DisplayCheckbox>
    <FlowSms {...props} />
  </DisplayCheckbox>
);

export default FlowSmsCheckbox;
