import React from 'react';
import { FaEdit } from 'react-icons/fa';

import InfoButton from './InfoButton';

import styles from './MonitoringAlerts.module.css';

type TabHeader = {
  label: string;
  onEdit?: () => void;
  onClickInfo?: () => void;
  Component?: React.ReactNode;
  content?: string;
  showInfoIcon?: boolean;
  displayEditButton?: boolean;
};

export default function TabHeader({
  label,
  onEdit,
  onClickInfo,
  Component = null,
  content = '',
  showInfoIcon = false,
  displayEditButton,
}: TabHeader) {
  const formattedText = content ? content.split('\n') : '';

  return (
    <div className={styles['tab-header']}>
      <div className={styles['tab-header-wrapper']}>
        <h4 className={styles['tab-header-label']}>
          {label}
          {onClickInfo && <InfoButton onClick={onClickInfo} />}
        </h4>

        {displayEditButton && (
          <button className={styles['tab-header-edit']} onClick={onEdit}>
            <FaEdit />
          </button>
        )}

        {Component && !onEdit && <div className={styles['tab-header-component']}>{Component}</div>}
      </div>
      <div className={styles['tab-header-content']}>
        {formattedText &&
          formattedText.map((text, index) => {
            return (
              <p key={`${label}-${index}`}>
                {text}
                {formattedText.length - 1 === index && showInfoIcon && (
                  <>
                    <InfoButton />
                    {'.'}
                  </>
                )}
              </p>
            );
          })}
      </div>
    </div>
  );
}
