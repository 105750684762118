import React, { useEffect } from 'react';
import { ContentfulConnect } from '@cv/webframework-react-components';
import { useSelector, useDispatch } from 'react-redux';
import App, { AppProps } from './App';
import { RootState } from '@app/reducers';
import { SETTINGS_ACTIONS } from '@redux/actions';
import { mapLocale, unMapLocale } from './localeMapper';
import { ContentfulObject, useFilterContent } from './filterUtils';
import { useConfig } from '@components/ConfigProvider';

const useSetContentfulLanguage = (language?: string) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (language) {
      dispatch({ type: SETTINGS_ACTIONS.SET_CONTENTFUL_LOCALE, data: unMapLocale(language) });
    }
  }, [language]);
};

export const ContentfulTagFilter = ({ pageContent = {}, pageContentLocale }: AppProps) => {
  useSetContentfulLanguage(pageContentLocale);

  const filteredPageContent = useFilterContent(pageContent as ContentfulObject);
  if (!filteredPageContent) {
    return null;
  }

  return <App pageContent={filteredPageContent} />;
};

const ContentfulTagFilterWrapper = () => {
  const config = useConfig();
  const configDefaultLanguage = config.getOemValue('DEFAULT_LANGUAGE');
  const storedLocale = useSelector((state: RootState) => mapLocale(state.settingsReducer.locale));

  const locale = storedLocale || configDefaultLanguage;
  const contentfulConnectConfig = {
    pageContent: { id: config.get<string>('CONTENTFUL_PAGE_ID'), depth: 10 },
  };

  const ConnectedComponent = ContentfulConnect(ContentfulTagFilter, contentfulConnectConfig);
  return <ConnectedComponent locale={locale} />;
};

export default ContentfulTagFilterWrapper;
