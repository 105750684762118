import React, { FunctionComponent } from 'react';
import ThemeProvider, { CustomTheme } from './ThemeProvider';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
interface LibProviderProps {
  theme: CustomTheme;
  children: React.ReactNode;
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const LibProvider: FunctionComponent<LibProviderProps> = ({ theme, children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <CssBaseline />
          {children}
        </StyledEngineProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default LibProvider;
