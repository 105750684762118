import React from 'react';
import { EligiblePackageInfo } from '../Types';
import {
  useFlowLocation,
  useFlowMachine,
  useFlowMachineContextContent,
  useFlowMachineContextSubscriptionProps,
} from '../../Router/RouterContext';
import { useSelector } from '@xstate/react';
import ManageTrialPackages from './ManageTrialPackages';
import { hasOnlyOneTrialEligibleVariant } from '@manageSubscription';

const ManageTrialPackagesContainer = () => {
  const flowMachine = useFlowMachine();
  const location = useFlowLocation();
  const eligibleTrialPackages = useSelector(flowMachine, (state) => state.context.eligibleTrialPackages);
  const {
    trialPackagesWebContent: { header, subHeader, trialEligibleDescription, legalText },
    commonWebContent: { productsToHide },
    assets,
  } = useFlowMachineContextContent();
  const {
    locale,
    tenantId,
    vehicleDetails: { make },
    shouldMatchTrialWithCCRequired,
  } = useFlowMachineContextSubscriptionProps();

  const hasMultipleTrialPackages = eligibleTrialPackages?.length > 1;
  const hasOneTrialEligibleVariant = hasOnlyOneTrialEligibleVariant(
    eligibleTrialPackages,
    shouldMatchTrialWithCCRequired,
  );

  const addAllPackages = (pkgs: EligiblePackageInfo[]) => {
    flowMachine.send({ type: 'ADD_ALL_PACKAGES', data: { packages: pkgs } });
  };

  const handleNoThanks = () => {
    flowMachine.send({ type: 'HANDLE_NO_THANKS', data: { packages: [] } });
  };

  return (
    <ManageTrialPackages
      addAllPackages={addAllPackages}
      header={header}
      subHeader={subHeader}
      trialEligibleDescription={trialEligibleDescription}
      legalText={legalText}
      trialPackages={eligibleTrialPackages}
      hasMultipleTrials={hasMultipleTrialPackages}
      hasOneTrialEligibleVariant={hasOneTrialEligibleVariant}
      productsToHide={productsToHide}
      assets={assets}
      make={make}
      locale={locale}
      tenantId={tenantId}
      location={location}
      handleNoThanks={handleNoThanks}
    />
  );
};

export default ManageTrialPackagesContainer;
