import React, { useState } from 'react';
import { Container, Content, Heading } from '@styled-components/globalStyles';
import { LegalContainer } from '../Legal';
import { TrialPackages } from '../Packages';
import { TrialPackagesProps } from '../Packages/DiscountPackage/TrialDiscount/TrialPackages';
import { EligiblePackageInfo } from '@manageSubscription/Types';
import { isTrialEligibleVariant } from '@manageSubscription';

type ManageTrialPackagesProps = {
  header: string;
  subHeader: string;
  trialEligibleDescription: string;
  legalText: string;
  hasMultipleTrials: boolean;
  hasOneTrialEligibleVariant: boolean;
} & Omit<TrialPackagesProps, 'selectedTrialPackage' | 'setSelectedTrialPackage'>;

const ManageTrialPackages: React.FC<ManageTrialPackagesProps> = ({
  header,
  subHeader,
  trialEligibleDescription,
  hasMultipleTrials,
  hasOneTrialEligibleVariant,
  legalText,
  ...rest
}) => {
  const [selectedTrialPackage, setSelectedTrialPackage] = useState<EligiblePackageInfo>();

  const isTrialEligibleSelected = hasOneTrialEligibleVariant || isTrialEligibleVariant(selectedTrialPackage?.variant);

  return (
    <div>
      <Container>
        <Heading>{header}</Heading>
        {hasMultipleTrials && <Content>{subHeader}</Content>}
        {isTrialEligibleSelected && <Content>{trialEligibleDescription}</Content>}
        <TrialPackages
          {...rest}
          hasMultipleTrials={hasMultipleTrials}
          hasOneTrialEligibleVariant={hasOneTrialEligibleVariant}
          selectedTrialPackage={selectedTrialPackage}
          setSelectedTrialPackage={setSelectedTrialPackage}
        />
        <LegalContainer legalText={legalText} showCheckbox={false} />
      </Container>
    </div>
  );
};

export default ManageTrialPackages;
