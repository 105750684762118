import React, { useState } from 'react';
import { LineBorder, Title } from '@styled-components/globalStyles';
import { EligiblePackageInfo } from '@manageSubscription/Types';
import { Radio, RadioGroup } from '@lib-components/Radio';
import { TrialContent, TrialOption, TrialPackagesContainer } from '@manageSubscription/Packages/DiscountPackage/styles';
import { FormControl, FormControlLabel } from '@mui/material';
import TrialPackageTerm from '@manageSubscription/Packages/DiscountPackage/TrialDiscount/TrialPackageTerm';
import TrialOptionsHeader from '@manageSubscription/Packages/DiscountPackage/TrialDiscount/TrialOptionsHeader';
import { useFlowMachineContextContent } from '@lib-appRouter/RouterContext';
import { isMultiCCRequiredScenario } from '@manageSubscription/utils';

export interface TrialPackageOptionsProps {
  trialPackage: EligiblePackageInfo;
  selectedTrialPackage: EligiblePackageInfo;
  onTrialVariantSelect: (trialVariant: EligiblePackageInfo) => void;
}

const TrialPackageOptions = ({
  trialPackage,
  selectedTrialPackage,
  onTrialVariantSelect,
}: TrialPackageOptionsProps) => {
  const {
    trialPackagesWebContent: { ccRequiredCopyBlock, ccNotRequiredCopyBlock },
  } = useFlowMachineContextContent();
  const [selectedVariantId, setSelectedVariantId] = useState<string>(selectedTrialPackage?.variant.id || '');

  const handleVariantSelect = (variantId: string) => {
    setSelectedVariantId(variantId);

    const selectedVariant = trialPackage.variants.find((v) => v.id === variantId);
    const mapPackageWithVariant = { ...trialPackage, variant: selectedVariant };
    onTrialVariantSelect(mapPackageWithVariant);
  };

  const multiCCRequiredScenario = isMultiCCRequiredScenario(trialPackage);

  return (
    <TrialPackagesContainer>
      <TrialOptionsHeader />
      <LineBorder />
      <FormControl>
        <RadioGroup value={selectedVariantId} onChange={(_, value) => handleVariantSelect(value)}>
          {trialPackage.variants
            .sort((v1, v2) => Number(v1.discounts[0].ccRequired) - Number(v2.discounts[0].ccRequired))
            .map((variant) => {
              const { discounts, id: variantId, startDate, endDate } = variant;
              const [discount] = discounts;
              return (
                <TrialOption key={variantId}>
                  <FormControlLabel
                    key={variantId}
                    value={variantId}
                    control={<Radio size={'small'} key={variantId} />}
                    label={
                      <Title>
                        <TrialPackageTerm startDate={startDate} endDate={endDate} />
                      </Title>
                    }
                  />
                  <TrialContent>{discount.marketingName || discount.description}</TrialContent>
                  {multiCCRequiredScenario && (
                    <TrialContent>{discount.ccRequired ? ccRequiredCopyBlock : ccNotRequiredCopyBlock}</TrialContent>
                  )}
                </TrialOption>
              );
            })}
        </RadioGroup>
      </FormControl>
    </TrialPackagesContainer>
  );
};

export default TrialPackageOptions;
