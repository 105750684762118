import React from 'react';

import styles from './EntryCreator.module.css';

interface CreatorButtonProps {
  label: string;
  onClick: () => void;
}

const CreatorButton = ({ label, onClick }: CreatorButtonProps) => {
  return (
    <button className={styles.CreatorButton} onClick={onClick}>
      {label}
    </button>
  );
};

export default CreatorButton;
