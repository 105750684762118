import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { uniq } from 'lodash';
import { getSubscribedPackages, getDefaultEligiblePackages } from '@cv/portal-cps-lib/subscription';
import { PackageType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import config from '@config/config';
import { getCookie } from '@utils/cookies';
import isPackageToShow from '@utils/isPackageToShow';
import { RootState } from '@app/reducers';
import { formatSubscription } from '@api/formatters';
import { SubscribedServices } from '@api/types';
import { TokenTypes } from '@api/services/StoreService';
import SubscriptionPackage from '@customTypes/SubscriptionPackage';
import useVehicle from './useVehicle';

type HookOptions = {
  excludeDefaultPkgs?: boolean;
};

export const useSubscribedPackages = ({ excludeDefaultPkgs = true }: HookOptions = {}) => {
  const { data } = useVehicle();

  const vehicleId: string = data?.vehicleId || '';
  const userId: string = useSelector(({ userReducer }: RootState) => userReducer?.userId || '');
  const environmentEnum = config.getEnvironmentEnum();
  const accessToken = getCookie(TokenTypes.ACCESS_TOKEN) ?? '';
  const locale = useSelector(({ settingsReducer }) => settingsReducer?.locale);
  const tenantId = useSelector(({ accountReducer }) => accountReducer?.tenantId);

  const request = {
    data: { userId, vehicleId },
    environment: environmentEnum,
    headers: {
      Authorization: accessToken,
      'Accept-Language': locale,
      'CV-Tenant-Id': tenantId,
    },
  };

  const fetchSubscribedPackages = async () => {
    let defaultPkgs;

    if (!excludeDefaultPkgs) {
      defaultPkgs = await getDefaultEligiblePackages(request);
    }

    return getSubscribedPackages(request).then((response) => ({
      data: formatSubscription(response.data, defaultPkgs?.data),
    }));
  };

  return useQuery({
    queryKey: ['subscribedPackages'],
    queryFn: fetchSubscribedPackages,
    select: (packages) =>
      ({
        vin: packages?.data?.vehicle?.vin,
        vehicleId: packages?.data?.vehicle?.vehicleId,
        activeType: packages?.data?.services?.some(
          (subscribed) =>
            subscribed.packageType === PackageType.Regular || subscribed.packageType === PackageType.Default,
        ),
        subscribedServiceIds: uniq(
          packages?.data?.subscribedPackages
            .filter((subscribedPackage) => isPackageToShow(subscribedPackage as SubscriptionPackage))
            .flatMap((subscribedPackage) => {
              const serviceIds = subscribedPackage.serviceIds;
              const productsServiceIds = subscribedPackage.products.flatMap((product) => product.serviceIds);
              return [...serviceIds, ...productsServiceIds];
            }),
        ),
        packages: packages?.data?.subscribedPackages,
      } as SubscribedServices),
    enabled: !!vehicleId,
  });
};

export default useSubscribedPackages;
