import React, { useMemo } from 'react';
import {
  canSubscribeToPackages,
  containOnlyTrialPackages,
  deDupeFarthestSubscriptions,
  getEligiblePaidPackages,
  getHighestSubscription,
  getPackagesMatchingTrials,
  hasTrialEligiblePackages,
  isHighestPaidSubscription,
  isPaidPackage,
  isTrialPackage,
  replaceTemplateString,
} from '../utils';
import {
  useFlowLocation,
  useFlowMachine,
  useFlowMachineContext,
  useFlowMachineContextFlowStorage,
} from '@lib-appRouter/RouterContext';
import ManagePaidPackages from './ManagePaidPackages';
import { MIN_PKG_TIER } from '../utils/constants';
import { OfferType, PackageType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { useSelector } from '@xstate/react';
import { FlowEventName } from '@lib-appRouter/flowTypes';
import { ActionTypes } from '@lib-appRouter/flowSessionStorage/actionTypes';
import { EligiblePackageInfo } from '../Types';
import { EventDataBuilder, EventType, sendAnalyticsEvent } from '@lib-components/Analytics';

const ManagePaidPackagesContainer = () => {
  const flowMachine = useFlowMachine();
  const { packageSubscriptions, eligiblePackages, subscribedPackages } = useFlowMachineContextFlowStorage();
  const flowMachineContext = useFlowMachineContext();
  const eligiblePromoPackages = useSelector(flowMachine, (state) => state.context.eligiblePromoPackages);
  const {
    content: {
      commonWebContent,
      packagesWebContent,
      trialPackagesWebContent,
      promoPackagesWebContent: { discount },
      assets,
    },
    subscriptionProps,
    hideBackButtonOnPaid,
    eligibleTrialPackages,
  } = flowMachineContext;
  const location = useFlowLocation();
  const {
    packagesHeaderLabel,
    eligiblePackagesWebContent: { noPackagesAvailableLabel },
    immediatelyProceed,
  } = packagesWebContent;
  const {
    vehicleDetails: { make },
    customerCareNumber,
    customerCareCancelPackageNumber,
    tenantId,
    shouldMatchTrialWithCCRequired,
  } = subscriptionProps;

  // Enable button if user has selected a paid package or has highest subscription already
  const hasPaidPackageSelected =
    (packageSubscriptions.some((pkg) => isPaidPackage(pkg.variant)) ||
      subscribedPackages.some((pkg) => isHighestPaidSubscription(pkg))) &&
    !!packageSubscriptions.length;

  const eligiblePaidPackages = getEligiblePaidPackages(eligiblePackages);
  const filteredPaidPackages = getPackagesMatchingTrials(
    eligiblePaidPackages,
    packageSubscriptions,
    shouldMatchTrialWithCCRequired,
  );

  const isTrialEligible = hasTrialEligiblePackages(packageSubscriptions, shouldMatchTrialWithCCRequired);

  const canUserSubscribeToPackages = canSubscribeToPackages(subscribedPackages, eligiblePackages, tenantId);
  const onlyTrialInPackages = containOnlyTrialPackages(packageSubscriptions);
  const hasEligibleTrialPackages = eligibleTrialPackages?.length > 0;

  const noMorePackagesAvailable = replaceTemplateString(noPackagesAvailableLabel, {
    vehicleMake: make,
    customerCareNumber: customerCareNumber,
  });

  const farthestSubscriptions = deDupeFarthestSubscriptions(subscribedPackages);
  const getHighestSubscriptionHeaderText = (): string => {
    const { tier, packageType, variant } = getHighestSubscription(farthestSubscriptions);
    const key =
      tier === MIN_PKG_TIER
        ? PackageType.Default
        : isTrialPackage(variant)
        ? OfferType.Trial
        : `${packageType}-${tier}`;
    let headerText = packagesHeaderLabel[key];
    if (!packagesHeaderLabel[key]) {
      headerText = packagesHeaderLabel.DEFAULT;
    }
    return headerText.toString();
  };

  const canShowBackButton = useMemo(
    () => !hideBackButtonOnPaid && (hasEligibleTrialPackages || eligiblePromoPackages?.length > 0),
    [eligibleTrialPackages, eligiblePromoPackages],
  );

  const handleAddServices = () => {
    sendAnalyticsEvent(new EventDataBuilder(EventType.ProductAddedToCartEvent).withArgs(packageSubscriptions));
    flowMachine.send({ type: 'HANDLE_ADD_SERVICES' });
  };

  const removePaidPackages = () => {
    flowMachine.send({ type: FlowEventName.SET_SESSION_STORAGE, action: { type: ActionTypes.RemovePaidPackages } });
  };

  const removePackage = (pkg: EligiblePackageInfo) => {
    flowMachine.send({
      type: FlowEventName.SET_SESSION_STORAGE,
      action: { type: ActionTypes.RemovePackage, payload: pkg },
    });
  };

  const onlyAdd = (pkg: EligiblePackageInfo) => {
    flowMachine.send({
      type: FlowEventName.SET_SESSION_STORAGE,
      action: { type: ActionTypes.AddPackage, payload: pkg },
    });
  };

  const addThenProceed = (pkg: EligiblePackageInfo) => {
    onlyAdd(pkg);
    navigateForward();
  };

  const addPackage = immediatelyProceed ? addThenProceed : onlyAdd;

  const removeDiscount = () => {
    flowMachine.send({
      type: FlowEventName.SET_SESSION_STORAGE,
      action: { type: ActionTypes.RemoveDiscountInfo, payload: null },
    });
  };

  const navigateForward = () => {
    sendAnalyticsEvent(new EventDataBuilder(EventType.ProductAddedToCartEvent).withArgs(packageSubscriptions));
    flowMachine.send({ type: FlowEventName.NAVIGATE_FORWARD });
  };

  const navigateBack = () => {
    flowMachine.send({ type: FlowEventName.NAVIGATE_BACK });
  };

  return (
    <ManagePaidPackages
      assets={assets}
      location={location}
      farthestSubscriptions={farthestSubscriptions}
      getHighestSubscriptionHeaderText={getHighestSubscriptionHeaderText}
      canShowBackButton={canShowBackButton}
      noMorePackagesAvailable={noMorePackagesAvailable}
      eligiblePaidPackages={filteredPaidPackages}
      isTrialEligible={isTrialEligible}
      canUserSubscribeToPackages={canUserSubscribeToPackages}
      onlyTrialInPackages={onlyTrialInPackages}
      hasPaidPackageSelected={hasPaidPackageSelected}
      customerCareCancelPackageNumber={customerCareCancelPackageNumber}
      commonWebContent={commonWebContent}
      packagesWebContent={packagesWebContent}
      trialPackagesWebContent={trialPackagesWebContent}
      discountWebContent={discount}
      removePaidPackages={removePaidPackages}
      subscribedPackages={subscribedPackages}
      packageSubscriptions={packageSubscriptions}
      subscriptionProps={subscriptionProps}
      removePackage={removePackage}
      addPackage={addPackage}
      removeDiscount={removeDiscount}
      handleAddServices={handleAddServices}
      navigateForward={navigateForward}
      navigateBack={navigateBack}
      eligibleTrialPackages={eligibleTrialPackages}
      flowMachineContext={flowMachineContext}
    />
  );
};

export default ManagePaidPackagesContainer;
