import {
  calculateSubscriptionTotals,
  EXCLUDE_AGREEMENT_TAXES_FOR_TENANTS,
  getActualPrice,
  getPromoCodeDiscounts,
  isTrialPackage,
  priceTotal,
} from '../utils';
import {
  DiscountInfo,
  OrderSummaryInfo,
  PackageSubscription,
  PreviewOrderRequestInfo,
  SubscribedPackageInfo,
} from '../Types';
import { PreviewOrderResponse } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { buildSubscribedPackages } from './subscriptionBuilders';
import { PaymentInfo } from '../../payment/Types';
import { ToyotaFlowContext } from '../../Router/flows/componentFlow/Types';
import { PackageType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { TenantIds } from '@manageSubscription/utils/productFlowMap';

export const buildPreviewOrderRequestInfo = (
  packageSubscriptions: PackageSubscription[],
  subscribedPackages: SubscribedPackageInfo[],
  discountInfo?: DiscountInfo,
  paymentInfo?: PaymentInfo,
) => {
  return {
    packagesToAdd: packageSubscriptions,
    packagesToRemove: subscribedPackages,
    discountInfo,
    paymentInfo,
  } as PreviewOrderRequestInfo;
};

export const buildOrderSummaryWithDiscount = (
  summaryInfo: PreviewOrderResponse,
  packageSubscriptions: PackageSubscription[],
  promoCode: string,
) => {
  const previewOrderSummary = buildOrderSummary(summaryInfo);
  const packages = previewOrderSummary.packages.map((pkg) => {
    pkg.packageName = packageSubscriptions.find(({ id }) => id === pkg.packageId)?.packageName;
    const promoCodeDiscounts = getPromoCodeDiscounts(pkg.variant, promoCode);
    if (!promoCodeDiscounts?.length) {
      return pkg;
    }
    return { ...pkg, amountWithoutTax: getActualPrice(pkg.variant.discounts, pkg.variant.listPrice) };
  });
  return { ...previewOrderSummary, packages };
};

export const buildOrderSummary = (summaryInfo) => {
  const {
    amountWithoutTaxTotal,
    refundAmountWithoutTaxTotal,
    taxTotal,
    refundAmountTotal,
    refundTaxTotal,
    amountTotal,
    subscribedPackages,
  } = summaryInfo;

  const packages = buildSubscribedPackages(subscribedPackages).sort(
    (p1, p2) => p2.amountWithoutTax - p1.amountWithoutTax,
  );

  const orderSummary: OrderSummaryInfo = {
    amountWithoutTaxTotal: amountWithoutTaxTotal,
    refundAmountWithoutTaxTotal: refundAmountWithoutTaxTotal,
    taxTotal: taxTotal,
    refundAmountTotal: refundAmountTotal,
    refundTaxTotal: refundTaxTotal,
    amountTotal: amountTotal,
    packages,
  };
  return orderSummary;
};

export const toyotaBuildSelectedPackageSummary = (orderSummary: PreviewOrderResponse, offerIds: string[]) => {
  const taxItemsMap: { [key: string]: ToyotaFlowContext['taxItems'][number] } = {};
  let subTotal = 0;
  let totalTax = 0;
  const packages = orderSummary.subscribedPackages.reduce((packages, subscribedPackage) => {
    const {
      active,
      packageId,
      packageVariantId,
      subscriptionPackageId,
      marketingName,
      tier,
      packageType,
      packageGroup,
      listPrice,
      amountWithoutTax,
      taxTotal,
      discounts,
      discountAmount,
      termServiceEndDate,
      termEndDate,
      bundle,
      autoRenew,
      initialTerm,
      initialTermUnit,
      startDate,
      endDate,
      promotionalMessage,
      renewalTerm,
      currentTerm,
      currentTermUnit,
      cancelReceiveDate,
      termStartDate,
      taxLineItems,
      shortDescription,
      longDescription,
    } = subscribedPackage;

    subTotal += parseFloat(amountWithoutTax?.toString()) || 0;
    taxLineItems.forEach((item) => {
      const amount = parseFloat(item.amount.toString()) || 0;
      if (taxItemsMap[item.name]) {
        taxItemsMap[item.name].amount += amount;
      } else
        taxItemsMap[item.name] = {
          name: item.name,
          amount: amount,
        };
    });
    totalTax += parseFloat(taxTotal?.toString()) || 0;

    if (offerIds.includes(packageVariantId)) {
      packages.push({
        packageId,
        subscriptionPackageId,
        marketingName,
        packageName: marketingName,
        packageGroup,
        tier,
        packageType,
        active,
        amountWithoutTax,
        taxTotal,
        currentTerm,
        currentTermUnit,
        cancelReceiveDate,
        termStartDate,
        shortDescription,
        initialTerm,
        longDescription,
        initialTermUnit,
        variant: {
          id: packageVariantId,
          initialTerm,
          initialTermUnit,
          actualPrice: listPrice,
          listPrice,
          startDate,
          endDate,
          termServiceEndDate,
          discounts,
          promotionalMessage,
          renewable: autoRenew,
          renewalTerm,
          requiresAutoRenew: autoRenew,
          taxTotal,
        },
        discountAmount,
        termServiceEndDate,
        termEndDate,
        bundle,
        autoRenew,
      });
    }

    return packages;
  }, [] as SubscribedPackageInfo[]);

  const {
    amountTotal,
    amountWithoutTaxTotal,
    taxTotal,
    refundTaxTotal,
    refundAmountTotal,
    refundAmountWithoutTaxTotal,
  } = orderSummary;

  return {
    orderInfo: {
      packages,
      amountTotal,
      amountWithoutTaxTotal,
      taxTotal,
      refundAmountTotal,
      refundAmountWithoutTaxTotal,
      refundTaxTotal,
    },
    subTotal,
    totalTax,
    taxItems: Object.values(taxItemsMap),
  };
};

export const buildSelectedPackageSummary = (
  packages: PackageSubscription[],
  orderSummary: PreviewOrderResponse,
  shouldIncludeTax: boolean,
) => {
  const sPackages = packages.map((subscribedPackage) => {
    const {
      id: packageId,
      packageName,
      subscriptionPackageId,
      marketingName,
      tier,
      packageType,
      variant,
    } = subscribedPackage;
    const { id: variantId, actualPrice, discounts, taxTotal, startDate, endDate, termServiceEndDate } = variant;

    const previewPackage = orderSummary?.subscribedPackages.find(
      (pkg) =>
        pkg.active &&
        pkg.packageVariantId === variantId &&
        !isTrialPackage({
          ...variant,
          discounts: pkg.discounts,
        }),
    );
    return {
      packageName,
      packageId,
      subscriptionPackageId,
      marketingName: previewPackage?.marketingName ?? marketingName,
      tier,
      packageType,
      active: true,
      amountWithoutTax: previewPackage?.listPrice
        ? getActualPrice(previewPackage.discounts, previewPackage.listPrice)
        : actualPrice,
      taxTotal: previewPackage?.taxTotal ?? taxTotal,
      variant: {
        ...variant,
        discounts: previewPackage?.discounts ?? discounts,
        startDate: previewPackage?.startDate ?? startDate,
        endDate: previewPackage?.endDate ?? termServiceEndDate ?? endDate,
      },
      discountAmount: previewPackage?.discountAmount ?? 0.0,
    } as SubscribedPackageInfo;
  });
  const totalAmount = sPackages.reduce(
    (total, pkg) => total + Number(pkg.amountWithoutTax) + Number(shouldIncludeTax ? pkg.taxTotal || 0.0 : 0.0),
    0,
  );
  return {
    amountTotal: totalAmount.toFixed(2),
    packages: sPackages,
  } as OrderSummaryInfo;
};

/**
 * Util to replace all latest subscription taxTotals with matching orderSummary taxTotals, as orderSummaryInfo
 * will have the correct taxInfo at any time. Also calculate totals considering latest updated info
 * subscribedPackages - Latest user subscriptions
 * orderSummaryInfo -  previewOrder response
 */
export const mapSubscriptionsWithSummaryTotals = (
  subscribedPackages: SubscribedPackageInfo[],
  orderSummaryInfo: OrderSummaryInfo,
  tenantId: TenantIds,
): OrderSummaryInfo => {
  const mappedSubscriptions = subscribedPackages
    .filter((p) => p.packageType === PackageType.Regular && p.active)
    .map((subscribedPackage) => {
      const matchingPackage = orderSummaryInfo.packages.find(
        (pkgFromSummary) => pkgFromSummary.variant.id === subscribedPackage.variant.id,
      );
      if (matchingPackage) {
        subscribedPackage.variant.taxTotal = matchingPackage.variant.taxTotal;
      }
      return subscribedPackage;
    });
  const { totalPrice, totalTaxes } = calculateSubscriptionTotals(mappedSubscriptions);

  return {
    packages: mappedSubscriptions,
    amountTotal: priceTotal(totalPrice, !EXCLUDE_AGREEMENT_TAXES_FOR_TENANTS.includes(tenantId) && totalTaxes),
    taxTotal: totalTaxes.toFixed(2),
  };
};

export const buildOrderTransactionInfo = (tranSource: string = '', transactionId: string, clientIP: string) => {
  return {
    inAuthTId__c: transactionId,
    source__c: tranSource,
    ipAddress__c: clientIP,
  };
};
