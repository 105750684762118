import { useQuery } from '@tanstack/react-query';
import { useApi } from '@api';
import { Entitlement } from '@api/types';

const useCpsRtsEntitlements = () => {
  const api = useApi();
  const { data, isFetched } = useQuery(['userInfo'], async () => await api.getUserInfo());

  const cpsEntitlements: Entitlement[] =
    data?.data?.sxmCPSEntitlement?.map((entitlement) => JSON.parse(entitlement)) || [];

  const rtsEntitlements: Entitlement[] =
    data?.data?.sxmRTSEntitlement?.map((entitlement) => JSON.parse(entitlement)) || [];

  const hasCpsEntitlement = (entitlement: string) =>
    isFetched && cpsEntitlements.some(({ resource }) => resource === entitlement);

  const hasRtsEntitlement = (entitlement: string) =>
    isFetched && rtsEntitlements.some(({ resource }) => resource === entitlement);

  return {
    cpsEntitlements,
    rtsEntitlements,
    hasCpsEntitlement,
    hasRtsEntitlement,
  };
};

export default useCpsRtsEntitlements;
