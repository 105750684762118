// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jvlT0UyM79FnX9RbX9dy {
  background-color: #fff;
  border: var(--theme-card-size-border, 1px) solid var(--theme-card-color-border, #ddd);
  display: inline-block;
  font-size: var(--theme-paragraph-font-size);
  margin: 0.7rem;
}

.oUPmX0yl4qgx9T7APNAQ {
  border: none;
  border-top: 8px solid var(--card-color-border-top, var(--theme-card-color-border-main));
}

.rm_Y6HsXHcMTsgQoEcpZ {
  box-shadow: rgb(0 0 0 / 20%) 0 2px 12px 0;
  display: flex;
  flex-direction: column;
  padding: 2.35rem 0.9rem;
  position: relative;
}

.rm_Y6HsXHcMTsgQoEcpZ p {
  margin-top: 1rem;
  margin-bottom: 1.1rem;
}

.rm_Y6HsXHcMTsgQoEcpZ a,
.rm_Y6HsXHcMTsgQoEcpZ a:hover {
  color: var(--theme-color-primary);
}

@media (min-width: 600px) {
  .rm_Y6HsXHcMTsgQoEcpZ {
    padding: 2.35rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Card/Card.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,qFAAqF;EACrF,qBAAqB;EACrB,2CAA2C;EAC3C,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,uFAAuF;AACzF;;AAEA;EACE,yCAAyC;EACzC,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;;EAEE,iCAAiC;AACnC;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".Card {\n  background-color: #fff;\n  border: var(--theme-card-size-border, 1px) solid var(--theme-card-color-border, #ddd);\n  display: inline-block;\n  font-size: var(--theme-paragraph-font-size);\n  margin: 0.7rem;\n}\n\n.Card--main {\n  border: none;\n  border-top: 8px solid var(--card-color-border-top, var(--theme-card-color-border-main));\n}\n\n.Card-inner {\n  box-shadow: rgb(0 0 0 / 20%) 0 2px 12px 0;\n  display: flex;\n  flex-direction: column;\n  padding: 2.35rem 0.9rem;\n  position: relative;\n}\n\n.Card-inner p {\n  margin-top: 1rem;\n  margin-bottom: 1.1rem;\n}\n\n.Card-inner a,\n.Card-inner a:hover {\n  color: var(--theme-color-primary);\n}\n\n@media (--viewport-xs) {\n  .Card-inner {\n    padding: 2.35rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Card": `jvlT0UyM79FnX9RbX9dy`,
	"Card--main": `oUPmX0yl4qgx9T7APNAQ`,
	"Card-inner": `rm_Y6HsXHcMTsgQoEcpZ`
};
export default ___CSS_LOADER_EXPORT___;
