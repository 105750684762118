import React, { useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import config from '@config/config';
import ContentfulComponent from '@customTypes/ContentfulComponent';
import useCancellationReducer from './useCancellationReducer';
import CancelLabelsContainer from './CancelLabelsContext';
import SelectPackagesToCancel from './steps/SelectPackagesToCancel/SelectPackagesToCancel';
import AllBenefits from './steps/AllBenefits/AllBenefits';

import styles from './Cancellation.module.css';

enum CancelFlow {
  SelectPackages = 'SelectPackages',
  AllBenefits = 'Benefits',
}

type CancellationProps = {
  contentSections: ContentfulComponent[];
};

const Cancellation = ({ contentSections }: CancellationProps) => {
  const [step, setStep] = useState<CancelFlow>(CancelFlow.SelectPackages);
  const { state, updatePackage } = useCancellationReducer();
  const history = useHistory();

  const goToBenefits = () => setStep(CancelFlow.AllBenefits);

  const keepSubscription = () => history.replace('/profile');

  const oemName = config.getOemName().toLowerCase();

  return (
    <CancelLabelsContainer contentSections={contentSections}>
      <section className={clsx(styles['cancel-container'], styles[oemName])}>
        <div className={styles['cancel-inner-container']}>
          {step === CancelFlow.SelectPackages && (
            <SelectPackagesToCancel
              selectedPackages={state.selectedPackages}
              onSelect={updatePackage}
              onContinue={goToBenefits}
              onKeep={keepSubscription}
            />
          )}
          {step === CancelFlow.AllBenefits && (
            <AllBenefits
              selectedPackages={state.selectedPackages}
              onContinue={() => undefined}
              onKeep={keepSubscription}
            />
          )}
        </div>
      </section>
    </CancelLabelsContainer>
  );
};

export default Cancellation;
