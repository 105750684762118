import React from 'react';
import { useDispatch } from 'react-redux';

import { ISxmKey, useApi } from '@api';
import { getPhoneTypeShortcut } from '@api/formatters';
import { CardView } from '@components/Card';
import { SalesChannel } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { ManageSubscription, SubscriptionProps } from '@components-lib';

import { ROOT_EVENTS } from '@app/reducers';
import usePreferencesSelector from '@redux/selectors/preferences';
import { ServerAsset } from '@utils/assets';
import { ContentfulLabelType, findDictionary } from '@utils/labels';
import { BrandNamesFilterGroup } from '@customTypes/BrandNamesFilterGroup';
import styles from './SubscriptionWrapper.module.css';
import { RRS_SOURCES, DISCOUNT_OFFER_ADDITIONAL_LEGAL_TEXT } from './constants';
import { getUserData } from '@utils/getUserData';
import useExpireRenewText from '@hooks/useExpireRenewText';
import useLogout from '@hooks/useLogout';
import useOpenPage from '@hooks/useOpenPage';
import isPackageToShow from '@utils/isPackageToShow';
import textForOffer from '@utils/textForOffer';
import urlSearchParams, { clearParams } from '@utils/urlSearchParams';
import ContentfulComponent from '@app/types/ContentfulComponent';
import ContentRenderer from '@components/ContentRenderer';
import { AgreementType } from '@cv/portal-cps-lib/agreements/agreement-service/enums';
import { clearPaymentMethods } from '@redux/actions/account';
import { useChangeLanguage } from '@components/LanguagePicker/LanguagePicker';
import { omitRecursivelyBy } from '@utils/objects';
import { isEmpty, merge } from 'lodash';
import { getCookie } from '@utils/cookies';
import { useGenerateSubscriptionContent, useGenerateSubscriptionProps } from './utils';
import { FormField } from '@components/EntryCreator/CreatorForm';
import { IVehicle } from '@redux/actions';
import { useQueryClient } from '@tanstack/react-query';

type SubscriptionWrapperProps = {
  labels: ContentfulLabelType[];
  assets: ServerAsset[];
  filterGroup?: BrandNamesFilterGroup;
  downloadApplication: ContentfulComponent[];
  portalCarousel: ContentfulComponent[];
  carouselList: Record<string, unknown>[];
  agreementType: AgreementType;
  portalPage?: string;
  formFields: FormField[];
  hideAppOnConfirmation?: boolean;
};

const renderContentfulComponent = ({
  name,
  content,
  props,
}: {
  name: string;
  content: ContentfulComponent[];
  props: object;
}) => <ContentRenderer name={name} content={content} {...props} />;

const SubscriptionWrapper = (props: SubscriptionWrapperProps) => {
  const {
    sxmKeyDetails,
    flowName,
    u: marketingUserId,
    v: marketingVehicleId,
    sxmkey: sxmKey = '',
    tenantId = getCookie('b'),
    userEmail,
    pkgIdsToRemove,
    salesChannel,
  } = urlSearchParams.getAll<{
    sxmKeyDetails: ISxmKey;
    u?: string;
    v?: string;
    flowName: string;
    sxmkey: string;
    tenantId?: string;
    userEmail: string;
    pkgIdsToRemove?: string[];
    salesChannel: SalesChannel;
  }>();
  const { agreementType, portalPage } = props;

  const content = useGenerateSubscriptionContent(props);

  const dispatch = useDispatch();
  const changeLanguage = useChangeLanguage();
  const preferences = usePreferencesSelector();

  const api = useApi();
  const goBackToPortal = useOpenPage(portalPage || '/');

  const queryClient = useQueryClient();

  const discountCriteria = {
    promoCode: api.storeService.getPromoCode(),
    ...(api.storeService.getPromoCodeCriteria() || {}),
  };

  const getSalesChannel = () => {
    if (RRS_SOURCES.includes(sxmKeyDetails?.source)) {
      return SalesChannel.Rapid_registration;
    }
    if (flowName === 'savemore') {
      return SalesChannel.SubscriberDiscount;
    }
    if (salesChannel) {
      return salesChannel;
    }
    return SalesChannel.Subscriber;
  };

  const subscriptionProps: SubscriptionProps = merge(
    // this would be overridden by next objects
    {
      userDetails: {
        email: userEmail,
      },
      vehicleDetails: omitRecursivelyBy(
        {
          vehicleId: marketingVehicleId || sxmKeyDetails?.vehicleId,
        },
        isEmpty,
      ),
    },
    // this would be overridden by next objects and will override previous one
    useGenerateSubscriptionProps(),
    // this would be overridden by next objects and will override previous one
    {
      agreementType,
      sxmKey,
      setLanguage: changeLanguage,
      publicKey: preferences.publicKey,
      location: flowName,
      salesChannel: getSalesChannel(),
      tenantId: tenantId || sxmKeyDetails?.tenantId,
      userDetails: omitRecursivelyBy(
        {
          userId: sxmKeyDetails?.userId || marketingUserId,
        },
        isEmpty,
      ),
      vehicleDetails: omitRecursivelyBy(
        {
          make: sxmKeyDetails?.make,
          vin: sxmKeyDetails?.vin,
          ownershipType: sxmKeyDetails?.ownershipType,
        },
        isEmpty,
      ),
      customerCareCancelPackageNumber: preferences.customerCaPreCancelPackageNumber,
      privacyPolicyUrl: preferences.privacyPolicyURL,
      discountCriteria,
      onSubscriptionConfirm: () => {
        dispatch({ type: ROOT_EVENTS.CLEAR_SUBSCRIPTIONS });
        dispatch(clearPaymentMethods());
      },
      onDone: () => {
        clearParams();
        if (sxmKey) {
          urlSearchParams.set('sxmkey', sxmKey);
        }
        queryClient.invalidateQueries({ queryKey: ['vehicle', 'subscription', 'capableServices'] });

        goBackToPortal();
      },
      handleCriticalError: useLogout(),
      getAccountDetails: api.getAccountDetails.bind(api),
      updateAccount: api.changeAccount.bind(api),
      /**
       * User is unauthorized for RRS flow,
       * so we updated subscriptionProps through the flow
       * and call this callback (getSubscriptionInfo) with
       * updated subscriptionProps to set this data in portal
       */
      getSubscriptionInfo: (updatedProps: SubscriptionProps) =>
        getUserData({
          accessToken: updatedProps.accessToken,
          refreshToken: updatedProps.refreshToken,
          dispatch,
          api,
          selectedVehicleId: updatedProps.vehicleDetails.vehicleId,
        }),
      setVehicleData: (vehicleData: IVehicle) => {
        api.storeService.setVehicleData(vehicleData);
      },
      expireRenewText: useExpireRenewText(),
      isPackageToShow,
      getOfferLegalText: textForOffer(
        findDictionary(props.labels, DISCOUNT_OFFER_ADDITIONAL_LEGAL_TEXT),
        props.filterGroup,
      ),
      renderContentfulComponent,
      pkgIdsToRemove,
      getPhoneTypeShortcut,
    },
  );

  const webContent = {
    // components
    components: {
      downloadApplication: props.downloadApplication,
      portalCarousel: props.portalCarousel,
    },
    ...content,
    //List of carousels
    carouselList: props.carouselList,
  };

  const [cardType, cardClass] = ['toyota', 'lexus'].includes(subscriptionProps.tenantId)
    ? (['default', styles['subscription-wrapper-without-border']] as const)
    : (['main'] as const);

  return (
    <CardView
      type={cardType}
      classes={{
        card: [styles['subscription-wrapper'], cardClass, styles['card-with-margin']],
        inner: styles['subscription-wrapper-inner'],
      }}
    >
      <ManageSubscription subscriptionProps={subscriptionProps} content={webContent} />
    </CardView>
  );
};

export default SubscriptionWrapper;
