// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Gzknc_fzUOoOjZ0G7dty {
  font-size: 0.75rem;
  padding: 0.3125rem;
  text-align: center;
  cursor: pointer;
  display: inline-flex;
  position: relative;
  align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border-radius: 0;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
}

.Gzknc_fzUOoOjZ0G7dty.ppA672ymD8CaPMMGAhAA {
  cursor: auto;
}

.Gzknc_fzUOoOjZ0G7dty input {
  top: 0;
  left: 0;
  width: 100%;
  cursor: inherit;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  z-index: 1;
  position: absolute;
}

.Y00BQHFia4qAcyNfwRn0 {
  color: var(--theme-color-grey-c1);
  border: 2px solid var(--theme-color-grey-c1);
  width: 1em;
  height: 1em;
  display: inline-block;
  border-radius: 50%;
  padding: 0.125em;
  box-sizing: content-box;
}

.Y00BQHFia4qAcyNfwRn0.R9qTL5T997zUullN9RBx {
  background: var(--theme-color-primary) !important;
  color: var(--theme-color-white) !important;
  border-color: var(--theme-color-primary) !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/RoundToggleButton/RoundToggleButton.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;EACnB,yBAAiB;KAAjB,sBAAiB;UAAjB,iBAAiB;EACjB,gBAAgB;EAChB,sBAAsB;EACtB,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,MAAM;EACN,OAAO;EACP,WAAW;EACX,eAAe;EACf,YAAY;EACZ,SAAS;EACT,UAAU;EACV,UAAU;EACV,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,iCAAiC;EACjC,4CAA4C;EAC5C,UAAU;EACV,WAAW;EACX,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,iDAAiD;EACjD,0CAA0C;EAC1C,mDAAmD;AACrD","sourcesContent":[".round-toggle-button {\n  font-size: 0.75rem;\n  padding: 0.3125rem;\n  text-align: center;\n  cursor: pointer;\n  display: inline-flex;\n  position: relative;\n  align-items: center;\n  user-select: none;\n  border-radius: 0;\n  vertical-align: middle;\n  justify-content: center;\n  text-decoration: none;\n}\n\n.round-toggle-button.disabled {\n  cursor: auto;\n}\n\n.round-toggle-button input {\n  top: 0;\n  left: 0;\n  width: 100%;\n  cursor: inherit;\n  height: 100%;\n  margin: 0;\n  opacity: 0;\n  padding: 0;\n  z-index: 1;\n  position: absolute;\n}\n\n.content {\n  color: var(--theme-color-grey-c1);\n  border: 2px solid var(--theme-color-grey-c1);\n  width: 1em;\n  height: 1em;\n  display: inline-block;\n  border-radius: 50%;\n  padding: 0.125em;\n  box-sizing: content-box;\n}\n\n.content.selected {\n  background: var(--theme-color-primary) !important;\n  color: var(--theme-color-white) !important;\n  border-color: var(--theme-color-primary) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"round-toggle-button": `Gzknc_fzUOoOjZ0G7dty`,
	"disabled": `ppA672ymD8CaPMMGAhAA`,
	"content": `Y00BQHFia4qAcyNfwRn0`,
	"selected": `R9qTL5T997zUullN9RBx`
};
export default ___CSS_LOADER_EXPORT___;
