import { QueryClient } from '@tanstack/react-query';
import { milliseconds } from 'date-fns';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      // prevent any refetching of data during 3 seconds
      // in case if we would fetch same data from different places simultaneously
      staleTime: milliseconds({ seconds: 3 }),
    },
  },
});

export default queryClient;
