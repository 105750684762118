import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { updatePaymentMethodById } from '@cv/portal-cps-lib';
import {
  GetAllPaymentMethodsResponse,
  UpdatePaymentMethodByIdRequest,
  UpdatePaymentMethodResponse,
} from '@cv/portal-cps-lib/payment/payment-methods/models';
import { options } from '../../commonService';
import { SubscriptionProps } from '@manageSubscription';
import { PaymentMethodDetails, PaymentOptions } from '@lib-payment/Types';
import { isEmpty } from 'lodash';
import { deletePayment } from './useDeletePayment';
import { queryClient } from '@styled-components/LibProvider';

export const updatePayment = (props: SubscriptionProps, updatedPayment: Partial<PaymentMethodDetails>) => {
  const {
    userDetails: { userId },
  } = props;
  const { paymentMethodId, defaultPaymentMethod, transactionInfo } = updatedPayment;

  let data: UpdatePaymentMethodByIdRequest['data'] = {
    defaultPaymentMethod,
    transactionInfo,
  };

  if (updatedPayment.cardType !== PaymentOptions.PAYPAL) {
    const { expirationMonth, expirationYear, cardHolderInfo } = updatedPayment;
    if (!isEmpty(cardHolderInfo)) {
      data.creditCardInfo = {
        expirationMonth,
        expirationYear,
        cardHolderName: cardHolderInfo.cardHolderName,
        addressLine1: cardHolderInfo.addressLine1,
        addressLine2: cardHolderInfo.addressLine2,
        city: cardHolderInfo.city,
        state: cardHolderInfo.state,
        zipCode: cardHolderInfo.zipCode,
      };
    }
  }

  const request: UpdatePaymentMethodByIdRequest = {
    ...options(props),
    pathParams: { userId, paymentMethodId },
    data,
  };
  return updatePaymentMethodById(request).then((res) => res.data);
};

export const useUpdatePayment = (
  props: SubscriptionProps,
  options?: UseMutationOptions<UpdatePaymentMethodResponse, unknown, Partial<PaymentMethodDetails>, unknown>,
) => {
  return useMutation(
    ['updateDefaultPaymentMethod'],
    (updatedPayment: Partial<PaymentMethodDetails>) => updatePayment(props, updatedPayment),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        // data.defaultPaymentMethod && !data.transactionInfo - means we create a new payment
        if (variables.defaultPaymentMethod && !variables.transactionInfo && props.isSinglePaymentMethod) {
          const paymentMethods = queryClient.getQueryData<GetAllPaymentMethodsResponse>(['paymentMethods']);

          const preparePaymentMethodToDelete = async (paymentDetails) =>
            await deletePayment(props, paymentDetails.paymentMethodId);

          const paymentMethodsToDelete = [
            ...paymentMethods.creditCard.map(preparePaymentMethodToDelete),
            ...paymentMethods.paypal.map(preparePaymentMethodToDelete),
          ];

          await Promise.all(paymentMethodsToDelete);
        }

        // we should re-fetch data to have updated payment methods
        queryClient.invalidateQueries({ queryKey: ['paymentMethods'] });
        options?.onSuccess?.(data, variables, context);
      },
    },
  );
};
