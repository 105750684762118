import React, { useContext } from 'react';
import ContentfulComponent from '@customTypes/ContentfulComponent';
import { getDictionariesContent } from '@utils/labels';
import useLabels from '@hooks/useLabels';

type CancelLabels = {
  selectSubscription: string;
  benefits: string;
  continueButton: string;
  keepButton: string;
  trial: string;
  paid: string;
  starts: string;
  renew: string;
  expires: string;
};

const CancelLabelsContext = React.createContext<CancelLabels | null>(null);

type CancelLabelsContainerProps = {
  contentSections: ContentfulComponent[];
  children: React.ReactNode;
};

const CancelLabelsContainer = ({ contentSections, children }: CancelLabelsContainerProps) => {
  const content = getDictionariesContent(contentSections);
  const labels = useLabels(content).getAllPrimaries();
  return <CancelLabelsContext.Provider value={labels}>{children}</CancelLabelsContext.Provider>;
};

export default CancelLabelsContainer;

export const useCancelLabels = () => {
  const labels = useContext(CancelLabelsContext);
  if (labels === null) {
    throw new Error("'useCancelLabels' should be used within CancelLabelsContext");
  }

  return labels;
};
