import React, { memo, useMemo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import loadAppDynamics from '../../loadAppDynamics';
import { ThemeProvider } from '@components/Theme';
import Header from '@components/Header';
import FilteredFooter from '@components/Footer/FilteredFooter';
import ErrorPage from '@components/ErrorPage';
import TokenExchange from '@components/TokenExchange';
import { PageContent } from './AppWrapper';
import AppRouter from './AppRouter';
import ReduxDialog from '@components/Dialog/ReduxDialog';
import GlobalPreferences from '@components/GlobalPreferences';
import { NavigationProvider } from '@components/Navigation';
import { AnalyticsHandlerWrapper } from '@components/Analytics';
import { Analytics } from '@cv/webframework-react-components';
import UrlParser from './UrlParser';
import { AuthProvider } from '@components/AuthProvider/AuthProvider';
import { PortalTargetProvider } from '@components/PortalTargetComponent/PortalTargetContext';
import CookieBar from './CookieBar';
import LibThemeProvider from './LibThemeProvider';
import AppLoading from './AppLoading';
import RegisterInterceptor from './RegisterInterceptor';
import { useConfig } from '@components/ConfigProvider';

export type AppProps = {
  pageContent?: PageContent;
  pageContentLocale?: string;
};
function App({ pageContent = {} }: AppProps): JSX.Element {
  const config = useConfig();
  const analyticsUrl = config.getOemValue('ADOBE_ANALYTICS_URL');

  const {
    header = [],
    footerContent = [],
    footerFilter,
    globalPreferences = [],
    pagesList,
    navigationMachine,
    unauthorizedPages = [],
  } = pageContent;

  const pages = useMemo(
    () =>
      (pagesList || [])
        .map((page) => {
          page.authorised = true;
          page.contentSections = page.contentSections?.map((subPage) => ({ ...subPage, authorised: true })) || [];
          return page;
        })
        .concat(unauthorizedPages),
    [pagesList, unauthorizedPages],
  );

  const [headerContent] = header;
  const [globalPreferencesContent] = globalPreferences;
  const loginEnabled = Boolean(config.get('loginEnabled', true));
  const headerNavigationEnabled = Boolean(config.get('headerNavigationEnabled', true));
  const headerUserEnabled = Boolean(config.get('headerUserEnabled', true));
  const appDynamicsEnabled = config.get<string>('APP_DYNAMICS_ENABLED', 'false') === 'true';
  const appDynamicsKey = config.getOemValue('APP_DYNAMICS_KEY');
  if (appDynamicsEnabled) {
    loadAppDynamics(appDynamicsKey);
  }

  return (
    <Analytics url={analyticsUrl}>
      <AnalyticsHandlerWrapper>
        <Router>
          <AuthProvider>
            {/* TODO: get details if we still need this TokenExchange component */}
            <TokenExchange>
              <ThemeProvider themes={pageContent.theme}>
                <LibThemeProvider>
                  <>
                    <RegisterInterceptor apiErrors={pageContent.apiErrors} pages={pages} />
                    <NavigationProvider pagesList={pages} navigationJson={navigationMachine}>
                      {globalPreferencesContent && (
                        <GlobalPreferences content={globalPreferencesContent} labelsFilter={footerFilter} />
                      )}

                      <PortalTargetProvider>
                        <UrlParser>
                          <div className="App">
                            <AppLoading />
                            {headerContent && (
                              <Header
                                content={headerContent}
                                withNavigation={headerNavigationEnabled}
                                withUser={headerUserEnabled}
                              />
                            )}
                            <ErrorBoundary FallbackComponent={ErrorPage}>
                              <AppRouter isLoginEnabled={loginEnabled} pageContent={pageContent} />
                            </ErrorBoundary>
                            <FilteredFooter content={footerContent} filter={footerFilter} />
                            <CookieBar cookiesCloseIcon={globalPreferences[0]?.cookiesCloseIcon} />
                            <ReduxDialog />
                          </div>
                        </UrlParser>
                      </PortalTargetProvider>
                    </NavigationProvider>
                  </>
                </LibThemeProvider>
              </ThemeProvider>
            </TokenExchange>
          </AuthProvider>
        </Router>
      </AnalyticsHandlerWrapper>
    </Analytics>
  );
}
export default memo(App);
