import { EligiblePackageInfo } from '@app/components-lib/manageSubscription/Types';
import {
  CheckoutStepData,
  EventType,
  OrderData,
  PageLoadData,
  PageLoadStartedData,
  UserData,
  UserProfileUpdatedData,
  FormViewedData,
  FormStartedData,
  FormSucceededData,
  FormFailedData,
  UserSignedOutData,
  ProductListingDisplayedData,
  ProductAddedToCartData,
  UserSignInErroredData,
  UserSignedInData,
} from './EventDataTypes';
import { AggregatedFlowContext } from '@components-lib/Router/flows/componentFlow/Types';
import { isMobile } from 'react-device-detect';
import { maskVin } from '@manageSubscription';
import { getFlowId } from '@components-lib/Router/flowMiddleware';

async function digestMessage(message: string) {
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  return hashHex;
}

type BuildUserSignInErroredData = () => UserSignInErroredData;
const buildUserSignInErroredData: BuildUserSignInErroredData = () => {
  return { event: EventType.UserSignInErrored };
};

export const buildProductInfoData = (p: EligiblePackageInfo) => {
  return {
    name: p.packageName,
    package_id: p.id,
    productDiscount: `${p.variant.discounts?.[0]?.discountCode || 'no discount code'} ${
      p.variant.discounts?.[0]?.amount || 'no discount amount'
    }`,
    productID: p.id,
    variant_id: p.variant.id,
  };
};

type BuildProductAddedToCartDataType = (products: EligiblePackageInfo[]) => ProductAddedToCartData;
const buildProductDataFromEligiblePackages = (p: EligiblePackageInfo) => {
  return {
    price: {
      priceTier: `${p.variant.actualPrice} ${p.variant.initialTermUnit}`,
    },
    productInfo: buildProductInfoData(p),
  };
};
const buildProductAddedToCartData: BuildProductAddedToCartDataType = (products) => {
  return {
    event: EventType.ProductAddedToCartEvent,
    product: products.map(buildProductDataFromEligiblePackages),
  };
};

type BuildProductListingDisplayedDataType = (listings: EligiblePackageInfo[]) => ProductListingDisplayedData;
const buildProductListingDisplayedData: BuildProductListingDisplayedDataType = (listings) => {
  return {
    event: EventType.ProductListingDisplayedEvent,
    listingDisplayed: {
      listing: listings.map(buildProductDataFromEligiblePackages),
    },
  };
};

type BuildFormSucceededDataType = (data: { name: string }) => FormSucceededData;
const buildFormSucceededData: BuildFormSucceededDataType = ({ name }) => {
  return {
    event: EventType.FormSucceededEvent,
    form: { formName: name },
  };
};

type BuildFormFailedDataType = (data: { name: string; error?: string }) => FormFailedData;
const buildFormFailedData: BuildFormFailedDataType = ({ name, error }) => {
  return {
    event: EventType.FormFailedEvent,
    form: { formName: name, formError: error || 'unknown error' },
  };
};

type BuildFormViewedDataType = (data: { name: string }) => FormViewedData;
const buildFormViewedData: BuildFormViewedDataType = ({ name }) => {
  return {
    event: EventType.FormViewedEvent,
    form: { formName: name },
  };
};

type BuildFormStartedDataType = (data: { name: string }) => FormStartedData;
const buildFormStartedDataData: BuildFormStartedDataType = ({ name }) => {
  return {
    event: EventType.FormStartedEvent,
    form: { formName: name },
  };
};

type BuildUserProfileUpdatedDataType = (data: {
  userId: UserProfileUpdatedData['user']['custKey'];
  email: UserProfileUpdatedData['user']['hashedEmail'];
}) => Promise<UserProfileUpdatedData>;
const buildUserProfileUpdatedData: BuildUserProfileUpdatedDataType = async ({ userId, email }) => {
  const hashedEmail = await digestMessage(email);
  return {
    event: EventType.UserProfileUpdatedEvent,
    user: {
      custKey: userId,
      hashedEmail,
    },
  };
};

type BuildUserSignedOutEventDataType = (data: { userId: UserSignedOutData['user']['custKey'] }) => UserSignedOutData;
const buildUserSignedOutEventData: BuildUserSignedOutEventDataType = (data) => {
  return {
    event: EventType.UserSignedOut,
    user: {
      custKey: data.userId,
    },
  };
};

type BuildUserSignedInEventDataType = (data: {
  userId: UserSignedInData['user']['custKey'];
  email: string;
}) => Promise<UserSignedInData>;
const buildUserSignedInEventData: BuildUserSignedInEventDataType = async ({ userId, email }) => {
  const hashedEmail = await digestMessage(email);
  return {
    event: EventType.UserSignedIn,
    user: {
      custKey: userId,
      hashedEmail,
    },
  };
};

type BuildUserEventDataType = (data: {
  userId: UserData['user']['custKey'];
  userStatus: UserData['user']['loginStatus'];
}) => UserData;
const buildUserEventData: BuildUserEventDataType = (data) => {
  return {
    event: EventType.UserEvent,
    user: {
      custKey: data.userId,
      loginStatus: data.userStatus,
    },
  };
};

type BuildOrderEventDataType = (data: OrderData['transaction']) => OrderData;
const buildOrderEventData: BuildOrderEventDataType = (data) => {
  return { event: EventType.OrderEvent, transaction: data };
};

type BuildCheckoutStepEventDataType = (data: {
  checkoutStep: string;
  products: EligiblePackageInfo[];
}) => CheckoutStepData;
const buildCheckoutStepEventData: BuildCheckoutStepEventDataType = (data) => {
  return {
    event: EventType.CheckoutStepEvent,
    eventDetails: {
      checkoutStep: data.checkoutStep,
    },
    product: data.products.map(buildProductDataFromEligiblePackages),
  };
};

type BuildPageLoadEventDataType = () => PageLoadData;
const buildPageLoadEventData: BuildPageLoadEventDataType = () => {
  return { event: EventType.PageLoadEvent };
};

type BuildPageLoadStartedEventDataType = (context: AggregatedFlowContext) => PageLoadStartedData;
const buildPageLoadStartedEventData: BuildPageLoadStartedEventDataType = (context) => {
  return {
    event: EventType.PageLoadStartedEvent,
    page: {
      customer_flow: getFlowId(context.flow) || '',
      oem: context.subscriptionProps.tenantId,
      pageCategory: 'Manage Subscriptions',
      pageName: context.history?.location.pathname?.substring(1) || '',
      pageType: '',
      siteCountry: context.subscriptionProps.userDetails?.billingAddress?.country || 'US',
      siteExperience: isMobile ? 'Mobile' : 'Desktop',
      siteLanguage: context.subscriptionProps.locale || '',
      siteName: '',
      subsection: '',
      subsection2: '',
      subsection3: '',
      vin_mask: maskVin(context.subscriptionProps?.vehicleDetails?.vin),
      vehicleID: context.subscriptionProps?.vehicleDetails?.vehicleId || '',
    },
  };
};

export const eventTypeBuilderMapping = {
  [EventType.UserEvent]: buildUserEventData,
  [EventType.UserSignedOut]: buildUserSignedOutEventData,
  [EventType.UserSignedIn]: buildUserSignedInEventData,
  [EventType.UserSignInErrored]: buildUserSignInErroredData,
  [EventType.OrderEvent]: buildOrderEventData,
  [EventType.CheckoutStepEvent]: buildCheckoutStepEventData,
  [EventType.PageLoadEvent]: buildPageLoadEventData,
  [EventType.PageLoadStartedEvent]: buildPageLoadStartedEventData,
  [EventType.UserProfileUpdatedEvent]: buildUserProfileUpdatedData,
  [EventType.FormViewedEvent]: buildFormViewedData,
  [EventType.FormStartedEvent]: buildFormStartedDataData,
  [EventType.FormSucceededEvent]: buildFormSucceededData,
  [EventType.FormFailedEvent]: buildFormFailedData,
  [EventType.ProductListingDisplayedEvent]: buildProductListingDisplayedData,
  [EventType.ProductAddedToCartEvent]: buildProductAddedToCartData,
};

export class EventDataBuilder<T extends EventType> {
  private event: T;
  constructor(event: T) {
    this.event = event;
  }
  withArgs(...args: Parameters<(typeof eventTypeBuilderMapping)[T]>) {
    return eventTypeBuilderMapping[this.event].apply(null, args);
  }
}
