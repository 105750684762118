import { styled } from '@mui/material';

import Button from '../../components/Button';
import Image from '../../components/Image/Image';
import { breakpoint, Content } from '../../styled-components/globalStyles';

export const AddOnDescription = styled(Content)`
  margin-bottom: 0.4rem;
`;

export const SaveAddOnsButton = styled(Button)`
  margin-top: 3.5rem;
`;

export const ProductSection = styled('div')`
  margin: 0 -1rem;
`;

export const ExpirationImage = styled(Image)`
  width: 30%;
  max-width: 300px;
  margin: auto;
  padding: 0 1rem 1rem 1rem;
`;

export const ContinueButton = styled(Button)`
  font-size: 0.9375rem;
  font-weight: 700;
  height: 50px;
  width: auto;
  margin: 0;

  @media only screen and ${breakpoint.device.md} {
    align-self: flex-end;
    min-width: 235px;
  }
`;

export const ContinueButtonsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  gap: 1rem;
`;
