import {
  EligiblePackageInfo,
  PackageSubscription,
  SubscribedPackageInfo,
  VariantInfo,
} from '@manageSubscription/Types';
import { getEligiblePackagesAndVariants, isTiered } from './packageUtils';
import { getDiscounts } from './promoPackageUtils';
import { OfferType, PackageType, TermUnit } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { MAX_PKG_TERM_IN_MONTHS, MAX_PKG_TIER, MIN_PKG_TIER } from '@manageSubscription/utils/constants';
import { toDays } from './monthsToDays';

export const getEligibleTrialPackages = (ePackages: EligiblePackageInfo[], sPackages: SubscribedPackageInfo[] = []) => {
  let maxTier = Math.min(...sPackages.map((pkg) => pkg.tier));
  if (!maxTier) {
    maxTier = MIN_PKG_TIER;
  }
  const maxSubscribedInitialTermInDays = Math.max(
    ...sPackages
      .filter((pkg) => pkg.packageType !== PackageType.Default)
      .map((pkg) => toDays(pkg.initialTerm, pkg.initialTermUnit)),
    0,
  );

  const eligibleTrials = getEligiblePackagesAndVariants(
    ePackages,
    [OfferType.Trial, OfferType.Trial_renewal],
    [TermUnit.Days, TermUnit.Months],
    maxTier,
    maxSubscribedInitialTermInDays,
  );
  return mapTrialPackageVariant(eligibleTrials);
};

/**
 * Method to find a trial variant that matches given ccRequired flag and set as default variant
 * OR set the first one as default, when no matching ccRequired variant found.
 */
export const mapTrialPackageVariant = (trialPackages: EligiblePackageInfo[], isCCRequired = false) => {
  return trialPackages.map((pkg) => {
    const { variants } = pkg;
    const variant =
      variants.find((trialVariant) => trialVariant.discounts[0].ccRequired === isCCRequired) || variants[0];
    return { ...pkg, variant };
  });
};

/**
 * Method responsible to return tiered, eligiblePaid packages that matches:
 * - Selected trials with ccRequired = true
 * - shouldMatchTrialWithCCRequired = true
 * If selected trials are non-tiered or does not match any of the above criteria then return eligiblePackages as it is.
 */
export const getPackagesMatchingTrials = (
  ePackages: EligiblePackageInfo[],
  packages: PackageSubscription[],
  shouldMatchTrialWithCCRequired: boolean,
) => {
  const trialEligibleList = trialEligiblePackages(packages);
  if (shouldMatchTrialWithCCRequired && trialEligibleList?.length > 0) {
    return ePackages.filter((ePkg) => trialEligibleList.some((pkg) => pkg.id === ePkg.id));
  }
  return ePackages;
};

export const hasTrialEligiblePackages = (packages: PackageSubscription[], shouldMatchTrialWithCCRequired: boolean) => {
  const trialEligibleList = trialEligiblePackages(packages);
  return shouldMatchTrialWithCCRequired && trialEligibleList?.length > 0;
};

export const hasOnlyOneTrialEligibleVariant = (
  trialPackages: EligiblePackageInfo[],
  shouldMatchTrialWithCCRequired: boolean,
) => {
  return (
    shouldMatchTrialWithCCRequired &&
    trialPackages?.length === 1 &&
    trialPackages[0].variant.discounts.some((d) => d.ccRequired)
  );
};

export const isTrialEligibleVariant = (variant: VariantInfo) => {
  return isTrialPackage(variant) && variant.discounts[0].ccRequired;
};

const trialEligiblePackages = (packages: PackageSubscription[]) => {
  return packages?.filter((pkg) => isTiered(pkg.tier) && isTrialEligibleVariant(pkg.variant));
};

export const containsTrialPackage = (packages: PackageSubscription[]) =>
  !!packages.length && packages.some(({ variant }) => isTrialPackage(variant));

export const containOnlyTrialPackages = (packages: PackageSubscription[]) =>
  !!packages.length && packages.every(({ variant }) => isTrialPackage(variant));

export const isTrialPackage = (variant: VariantInfo) => {
  if (!variant) return false;
  return getDiscounts(variant.discounts, [OfferType.Trial, OfferType.Trial_renewal]).length > 0;
};

export const isHighestTrialSubscription = (pkg: SubscribedPackageInfo) => {
  return pkg.tier === MAX_PKG_TIER && pkg.variant.renewalTerm == MAX_PKG_TERM_IN_MONTHS && isTrialPackage(pkg.variant);
};
