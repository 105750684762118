import React, { useMemo } from 'react';
import { createTheme, PaletteOptions, ThemeProvider as MuiThemeProvider, darken } from '@mui/material/styles';

export interface CustomTheme {
  color: string;
  secondaryColor: string;
  backgroundColor: string;
  headerTextColor: string;
  infopageHeaderSecondaryTextColor: string;
  infopageFooterTextColor: string;
  infopageFooterBgColor: string;
  themeCardSizeBorder: string;
  themeCardColorBorder: string;
  themeParagraphFontSize: string;
  cardColorBorderTop: string;
  themeCardColorBorderMain: string;
  themeColorError: string;
  fontFamily?: string;
  greyColor?: string;
  buttonBorderWidth?: string;
  buttonRadius?: string;
  buttonPadding?: string;
  buttonPrimaryHoverColor?: string;
}

declare module '@mui/material/styles' {
  interface Palette {
    portalTheme: Partial<CustomTheme>;
  }
  interface PaletteOptions {
    portalTheme: Partial<CustomTheme>;
  }
}

// should be removed after we remove styled-components
declare module 'styled-components' {
  export interface DefaultTheme extends CustomTheme {}
}

const ThemeProvider: React.FC<{ theme: CustomTheme; children: React.ReactNode }> = ({ theme, children }) => {
  const mappedPalette: PaletteOptions = { portalTheme: { ...theme } };
  const {
    color,
    secondaryColor,
    themeColorError,
    fontFamily,
    buttonPadding = '0.375rem 0.75rem',
    buttonBorderWidth = '1px',
    buttonRadius = '6px',
    buttonPrimaryHoverColor = darken(color, 0.3),
  } = theme;
  if (color) {
    mappedPalette.primary = { main: color };
    mappedPalette.action = { disabledOpacity: 0.4, disabled: 'rgba(255, 255, 255, 0.5)' };
  }

  secondaryColor && (mappedPalette.secondary = { main: secondaryColor });
  themeColorError && (mappedPalette.error = { main: themeColorError });

  const mappedTheme = useMemo(
    () =>
      createTheme({
        ...(fontFamily && {
          typography: {
            fontFamily: fontFamily,
          },
        }),
        palette: { ...mappedPalette },
        components: {
          MuiTypography: {
            variants: [
              {
                props: { variant: 'h6' },
                style: {
                  fontSize: '1rem',
                },
              },
            ],
          },
          MuiCssBaseline: {
            styleOverrides: `
                        * {
                            box-sizing: border-box;
                            margin: 0;
                            padding: 0;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                        }
                      `,
          },
          MuiCheckbox: {
            styleOverrides: {
              root: () => ({
                svg: {
                  border: '2px solid black',
                  borderRadius: '3px',
                  width: '15px',
                  height: '15px',
                  fill: 'none',
                  stroke: color,
                  strokeWidth: '3.5px',
                },
              }),
            },
          },
          MuiLink: {
            styleOverrides: {
              root: {
                textDecoration: 'none',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                },
              },
            },
          },
          MuiButton: {
            variants: [
              {
                props: { variant: 'contained' },
                style: {
                  color: 'white',
                },
              },
            ],
            defaultProps: {
              disableElevation: true,
              size: 'medium',
            },
            styleOverrides: {
              root: ({ theme }) => ({
                fontFamily: 'inherit',
                textTransform: 'none',
                padding: buttonPadding,
                borderRadius: buttonRadius,
                //Border for making contained and outlined buttons same height because of overrides paddings
                border: `${buttonBorderWidth} solid ${theme.palette.primary.main}`,

                '@media screen and (max-width: 425px)': {
                  fontSize: '1.2rem',
                },
                '&:hover': {
                  border: `${buttonBorderWidth} solid ${buttonPrimaryHoverColor}`,
                },
                '&.Mui-disabled': {
                  opacity: theme.palette.action.disabledOpacity,
                },
              }),
              contained: ({ theme }) => ({
                '&:hover': {
                  backgroundColor: buttonPrimaryHoverColor,
                },
                '&.Mui-disabled': {
                  color: theme.palette.action.disabled,
                  backgroundColor: theme.palette.primary.main,
                },
              }),
              outlined: ({ theme }) => ({
                color: theme.palette.primary.main,
                '&:hover': {
                  color: buttonPrimaryHoverColor,
                },
                '&.Mui-disabled': {
                  color: theme.palette.primary.main,
                  borderColor: theme.palette.primary.main,
                  borderWidth: buttonBorderWidth,
                },
              }),
              text: ({ theme }) => ({
                disableRipple: true,
                padding: 0,
                borderColor: 'transparent',
                '&:hover': {
                  borderColor: 'transparent',
                  backgroundColor: 'transparent',
                  textDecoration: 'underline',
                },
                '&.Mui-disabled': {
                  color: theme.palette.primary.main,
                },
                '@media screen and (max-width: 425px)': {
                  fontSize: '1rem',
                },
              }),
              sizeMedium: {
                fontSize: '1rem',
              },
              sizeLarge: {
                fontSize: '1.5rem',
              },
            },
          },
        },
      }),
    [theme],
  );
  return <MuiThemeProvider theme={mappedTheme}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
