import React from 'react';
import { useFlowMachineContextContent } from '@lib-appRouter/RouterContext';
import { Heading } from '@styled-components/globalStyles';

const TrialOptionsHeader = () => {
  const {
    trialPackagesWebContent: { trialOptionHeader },
  } = useFlowMachineContextContent();

  return <Heading>{trialOptionHeader}</Heading>;
};

export default TrialOptionsHeader;
