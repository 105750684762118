import React from 'react';
import { Container, Heading, LineSeparator, Link } from '../../styled-components/globalStyles';
import Button from '../../components/Button';
import { PaymentDetails } from './PaymentDetails';
import { OrderSummary } from './OrderSummary';
import { LegalContainer } from '../Legal';
import Spinner from '../../components/Spinner';
import { PreviewOrderSummaryFetcher } from './PreviewOrderSummaryFetcher';
import { SubscriptionSummary } from './SubscriptionSummary';
import { PreviewOrderResponse } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { PaymentInfo, PaymentOptions } from '../../payment/Types';
import { DiscountInfo, OrderSummaryInfo, PackageToRemoveBuilderType, PreviewOrderRequestInfo } from '../Types';
import { SubscriptionProps, WebContent } from '..';
import { filterPackagesType } from '../Packages/SubscribedPackage/SubscribedPackages';
import { Section } from './styles';

interface ReviewOrderProps {
  isCreateOrderLoading: boolean;
  defaultPayment?: PaymentInfo;
  orderSummaryInfo: OrderSummaryInfo;
  termsAgreed: boolean;
  previewOrderResponse;
  content: WebContent;
  discountInfo: DiscountInfo;
  location: string;
  previewOrderRequestInfo: PreviewOrderRequestInfo;
  subscriptionProps: SubscriptionProps;
  onConfirmOrder: () => void;
  setTermsAgreed: (data: boolean) => void;
  setPreviewOrderResponse: (data: PreviewOrderResponse) => void;
  navigateBack?: () => void;
  filterPackages?: filterPackagesType;
  packageToRemoveBuilder: PackageToRemoveBuilderType;
}

export const ReviewOrder: React.FC<ReviewOrderProps> = ({
  isCreateOrderLoading,
  defaultPayment,
  orderSummaryInfo,
  termsAgreed,
  previewOrderResponse,
  discountInfo,
  location,
  previewOrderRequestInfo,
  subscriptionProps,
  content,
  onConfirmOrder,
  setTermsAgreed,
  setPreviewOrderResponse,
  navigateBack,
  filterPackages,
  packageToRemoveBuilder,
}) => {
  const {
    reviewOrderLabel,
    subscribeNowButtonLabel,
    reviewOrderTerms,
    commonWebContent: { backButtonLabel },
  } = content;
  return (
    <div>
      {isCreateOrderLoading && <Spinner />}
      <Container>
        <Heading>{reviewOrderLabel}</Heading>
        <LineSeparator />
        {defaultPayment && defaultPayment.paymentOption !== PaymentOptions.PAYPAL && (
          <PaymentDetails paymentInfo={defaultPayment} />
        )}
        <PreviewOrderSummaryFetcher
          setPreviewOrderResponse={setPreviewOrderResponse}
          isFetchPreviewOrder={true}
          previewOrderRequestInfo={previewOrderRequestInfo}
          subscriptionProps={subscriptionProps}
          packageToRemoveBuilder={packageToRemoveBuilder}
        />
        <OrderSummary
          location={location}
          discountInfo={discountInfo}
          orderSummaryInfo={orderSummaryInfo}
          subscriptionProps={subscriptionProps}
          content={content}
          filterPackages={filterPackages}
        >
          <SubscriptionSummary
            content={content}
            subscriptionSummaryInfo={orderSummaryInfo}
            subscriptionProps={subscriptionProps}
          />
        </OrderSummary>
        <LegalContainer legalText={reviewOrderTerms} setTermsAgreed={setTermsAgreed} termsAgreed={termsAgreed} />
        <Section>
          <Button disabled={!termsAgreed || !previewOrderResponse} onClick={onConfirmOrder}>
            {subscribeNowButtonLabel}
          </Button>
          {navigateBack && <Link onClick={navigateBack}>{backButtonLabel}</Link>}
        </Section>
      </Container>
    </div>
  );
};
