// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tdfddrHQRT8Qj3k1KGQ5 {
  margin-top: 1em;
}

.TI_nvhhTfHIHJYfxEUQK {
  color: var(--color-expiry);
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/SubscriptionOverview/ServiceExpiry.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,0BAA0B;EAC1B,iBAAiB;AACnB","sourcesContent":[".ServiceExpiry-service {\n  margin-top: 1em;\n}\n\n.ServiceExpiry-expiry {\n  color: var(--color-expiry);\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ServiceExpiry-service": `tdfddrHQRT8Qj3k1KGQ5`,
	"ServiceExpiry-expiry": `TI_nvhhTfHIHJYfxEUQK`
};
export default ___CSS_LOADER_EXPORT___;
