import React, { useMemo, useEffect } from 'react';
import clsx from 'clsx';
import { SubscribedPackage } from '@cv/portal-cps-lib/subscription/subscription-management/models/package-subscription';
import useSubscribedPackages from '@api/queries/useSubscribedPackages';
import { useCancelLabels } from '../../CancelLabelsContext';
import Checkbox from '@lib-components/Checkbox';
import Button from '@lib-components/Button';
import Loader from '@components/Loader';
import CustomerPackage from './CustomerPackage';

import styles from '../../Cancellation.module.css';

export type SelectPackagesToCancelProps = {
  selectedPackages: SubscribedPackage[];
  onSelect: (pkg: SubscribedPackage) => void;
  onContinue: () => void;
  onKeep: () => void;
};

const SelectPackagesToCancel = ({ selectedPackages, onSelect, onContinue, onKeep }: SelectPackagesToCancelProps) => {
  const { data, isLoading, isFetched } = useSubscribedPackages();

  const subscribedPackages: SubscribedPackage[] = useMemo(() => data?.packages || [], [data]);

  const { selectSubscription, continueButton, keepButton } = useCancelLabels();

  useEffect(() => {
    const onlyOnePackage = isFetched && subscribedPackages.length === 1;
    if (onlyOnePackage) {
      onSelect(subscribedPackages[0]);
      onContinue();
    }
  }, [isFetched, subscribedPackages, onSelect, onContinue]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <h3 className={styles['cancel-layout-header']}>{selectSubscription}</h3>
      <ul className={styles['customer-packages-list']}>
        {subscribedPackages.map((subscribedPackage) => {
          const isSelected = selectedPackages.some(
            (pkgItem) => pkgItem.subscriptionPackageId === subscribedPackage.subscriptionPackageId,
          );
          return (
            <li key={subscribedPackage.subscriptionPackageId}>
              <CustomerPackage
                customerPackage={subscribedPackage}
                checkbox={
                  <Checkbox
                    className={styles['select-to-cancel-checkbox']}
                    onChange={() => onSelect(subscribedPackage)}
                    checked={isSelected}
                    placeholder={subscribedPackage.packageId}
                  />
                }
              />
            </li>
          );
        })}
      </ul>
      <div className={styles['buttons-continue-keep-container']}>
        <Button
          className={clsx(styles['bottom-button'], styles['bottom-continue'])}
          onClick={onContinue}
          disabled={selectedPackages.length === 0}
        >
          {continueButton}
        </Button>
        <Button className={styles['bottom-button']} variant="outlined" onClick={onKeep}>
          {keepButton}
        </Button>
      </div>
    </>
  );
};

export default SelectPackagesToCancel;
