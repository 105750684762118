import React from 'react';
import { ContentfulAssets } from '@manageSubscription';
import { Link } from '../../styled-components/globalStyles';
import Modal, { useModal } from '../Modal';
import { OkModal } from './OkModal';

interface LinkToOkModalProps {
  className?: string;
  message: React.ReactNode;
  buttonText: string;
  assets: ContentfulAssets;
  onConfirm?: () => void;
}

const LinkToOkModal = ({
  className,
  message,
  buttonText,
  assets,
  onConfirm,
  children,
}: React.PropsWithChildren<LinkToOkModalProps>) => {
  const { isShown, toggleModal } = useModal();

  const showModal = () => {
    return (
      <OkModal
        onConfirm={() => {
          toggleModal();
          onConfirm?.();
        }}
        buttonText={buttonText}
        message={message}
      />
    );
  };

  return (
    <div>
      <Link className={className} onClick={toggleModal} title="Open">
        {children}
      </Link>

      <Modal assets={assets} content={showModal()} isShown={isShown} hide={toggleModal} />
    </div>
  );
};

export default LinkToOkModal;
