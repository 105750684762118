import {
  containsPaidPackage,
  containsTrialPackage,
  getEligibleTrialPackages,
  hasOnlyOneTrialEligibleVariant,
  hasTrialEligiblePackages,
  isTrialPackage,
  noPromosPackagesAvailable,
} from '@manageSubscription';
import { is2fFlow } from '@lib-appRouter/flowUtils';

const trialPackageConditions = {
  hasSelectedTrialEligibleVariant: (FlowContext) => {
    const { packageSubscriptions } = FlowContext.flowSessionStorage;
    const { shouldMatchTrialWithCCRequired } = FlowContext.subscriptionProps;

    return hasTrialEligiblePackages(packageSubscriptions, shouldMatchTrialWithCCRequired);
  },
  hasEligibleTrialPackages: (FlowContext) => {
    const { eligiblePackages, subscribedPackages } = FlowContext.flowSessionStorage;
    const eligibleTrialPackages = getEligibleTrialPackages(eligiblePackages, subscribedPackages);
    return eligibleTrialPackages?.length > 0;
  },
  hasNoEligibleTrialPackages: (FlowContext) => {
    const { eligiblePackages, subscribedPackages } = FlowContext.flowSessionStorage;
    const eligibleTrialPackages = getEligibleTrialPackages(eligiblePackages, subscribedPackages);
    return !eligibleTrialPackages?.length;
  },
  shouldAddTrialEligiblePackage: (FlowContext) => {
    const { packageSubscriptions } = FlowContext.flowSessionStorage;
    const { shouldMatchTrialWithCCRequired } = FlowContext.subscriptionProps;
    const { eligiblePackages, subscribedPackages } = FlowContext.flowSessionStorage;
    const eligibleTrialPackages = getEligibleTrialPackages(eligiblePackages, subscribedPackages);

    const hasOneTrialEligibleVariant = hasOnlyOneTrialEligibleVariant(
      eligibleTrialPackages,
      shouldMatchTrialWithCCRequired,
    );
    return !containsTrialPackage(packageSubscriptions) && hasOneTrialEligibleVariant;
  },
  is2fFlowWithTrialEligibleHasNoPromos: (FlowContext) => {
    const { packageSubscriptions, eligiblePackages, subscribedPackages } = FlowContext.flowSessionStorage;
    const { location, shouldMatchTrialWithCCRequired, salesChannel } = FlowContext.subscriptionProps;
    const { eligiblePromoPackages } = FlowContext;

    const isTrialEligible = hasTrialEligiblePackages(packageSubscriptions, shouldMatchTrialWithCCRequired);
    return (
      is2fFlow(location) &&
      noPromosPackagesAvailable(eligiblePromoPackages, subscribedPackages, eligiblePackages, salesChannel) &&
      isTrialEligible
    );
  },
  is2fFlowWithTrialEligibleHasNoPaidPkg: (FlowContext) => {
    const { packageSubscriptions } = FlowContext.flowSessionStorage;
    const { location, shouldMatchTrialWithCCRequired } = FlowContext.subscriptionProps;

    const isTrialEligible = hasTrialEligiblePackages(packageSubscriptions, shouldMatchTrialWithCCRequired);
    return is2fFlow(location) && isTrialEligible && !containsPaidPackage(packageSubscriptions);
  },
  onlyTrialInPackages: (FlowContext) => {
    const { packageSubscriptions } = FlowContext.flowSessionStorage;
    return packageSubscriptions.every(({ variant }) => isTrialPackage(variant));
  },
};

export default trialPackageConditions;
