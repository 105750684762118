import { useQuery, useMutation } from '@tanstack/react-query';
import { useApi } from '@api';
import { EditPayload } from '@api/services/MarketingPreferenceService';
import { get } from '@components/GlobalPreferences/GlobalPreferences';

export const readKey = 'readMarketingPreference';

type HookOptions = {
  immediatelyRead?: boolean;
  smsNumber?: string;
};

function useMarketingPreference({ immediatelyRead = true, smsNumber }: HookOptions = {}) {
  const api = useApi();

  const {
    data,
    isLoading: readLoading,
    isError: readError,
    refetch: readPreference,
  } = useQuery([readKey], async () => await api.readMarketingPreference({ smsNumber }), {
    enabled: immediatelyRead,
  });

  const filterID = get('filterID') || '';

  type OptInOptions = { optIn: boolean; phone: string; email: string };

  const createEditPayload = ({ optIn, phone, email }: OptInOptions): EditPayload => {
    const phoneWithoutCountry = Number(phone.slice(2));
    return {
      marketingPreferenceList: [
        {
          filterID,
          contactElement: {
            pft: phoneWithoutCountry,
          },
          optIn,
          preferenceAttributes: [
            {
              key: 'AgentID',
              value: email,
            },
          ],
        },
      ],
    };
  };

  const { mutate: createPreference, isLoading: createLoading } = useMutation({
    mutationFn: async (options: OptInOptions) => await api.createMarketingPreference(createEditPayload(options)),
  });

  const { mutate: updatePreference, isLoading: updateLoading } = useMutation({
    mutationFn: async (options: OptInOptions) => await api.updateMarketingPreference(createEditPayload(options)),
  });

  const isLoading = readLoading || createLoading || updateLoading;

  return { data: data?.data, isLoading, readError, readPreference, createPreference, updatePreference };
}

export default useMarketingPreference;
