import { PackageSubscription, SubscribedPackageInfo, VariantInfo } from '../Types';
import { MIN_PKG_TIER } from './constants';
import { PackageType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import { doesBundleContainPackage, getProductMatchingBundle } from './alaCartePackageUtils';
import { isTrialPackage } from './trialPackageUtils';
import { PackageToRemove } from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { VehicleDetails } from '@manageSubscription/Subscription';
import { buildPackageToRemove } from '@manageSubscription/builders/packageBuilders';
import { isAddOnPackage } from './packageCancellationUtils';
import { isPackageFutureDated } from '@manageSubscription';
import { uniqBy } from 'lodash';

export const packagesToAdd = (selectedPackages: PackageSubscription[]) => {
  return selectedPackages
    ?.sort((pkg) => (isTrialPackage(pkg.variant) ? -1 : 1))
    .map((p, index) => {
      return {
        sequence: index + 1,
        packageVariantId: p.variant.id,
        autoRenew: isAutoRenew(p.variant),
        parentId: p.parentId,
      };
    });
};

export const packagesToRemove = (
  subscribedPackages: SubscribedPackageInfo[],
  selectedPackages: PackageSubscription[],
): PackageToRemove[] => {
  // Do not consider AddOns while determining packagesToRemove as it will be determined with their parent pkg
  selectedPackages = selectedPackages?.filter((sPkg) => sPkg.packageType !== PackageType.Add_on) || [];

  const initialPackagesToRemove =
    subscribedPackages
      ?.filter((sPkg) => {
        return (
          isTieredPackage(sPkg) ||
          sPkg.packageType !== PackageType.Regular ||
          // Is the selected bundle contain one of subscribed packages
          !!getProductMatchingBundle(selectedPackages, sPkg) ||
          isAlaCartePackageAnUpgradeDowngrade(sPkg, selectedPackages)
        );
      })
      // Do not include end of term, trial non-renewable subscriptions, as it will be taken care at backend
      .filter((sPkg) => {
        return (
          isAddOnPackage(sPkg.packageType) ||
          !isTierDowngradeOrAlaCarteTrial(sPkg, selectedPackages) ||
          sPkg.variant.renewable
        );
      }) || [];

  //We need to remove packages from the same packageGroup if they start in future (if there is a time gap between them)
  const packagesToRemove = [
    ...initialPackagesToRemove,
    ...initialPackagesToRemove.flatMap((sPkg) =>
      subscribedPackages?.filter(
        (comparePkg) => sPkg.packageGroup === comparePkg.packageGroup && isPackageFutureDated(comparePkg),
      ),
    ),
  ];

  return uniqBy(
    packagesToRemove.map((sPkg) => {
      const isCancelEffectiveToday =
        isAddOnPackage(sPkg.packageType) || !isTierDowngradeOrAlaCarteTrial(sPkg, selectedPackages);
      return buildPackageToRemove(sPkg, isCancelEffectiveToday);
    }),
    'subscriptionPackageId',
  );
};

export const addVehicleData = <T extends object>(
  { vehicleId, vin }: VehicleDetails,
  data: T,
): T & ({ vehicleId: string } | { vin: string }) => {
  const vehicleData = vehicleId ? { vehicleId } : { vin };
  return Object.assign(data, vehicleData);
};

const isTierDowngradeOrAlaCarteTrial = (subPackage: SubscribedPackageInfo, selPackages: PackageSubscription[]) => {
  return isTieredPackage(subPackage)
    ? isTieredPkgADowngradeFromTrial(subPackage, selPackages)
    : isAlaCarteATrialPackage(subPackage);
};

/**
 * A tiered selected package is treated as downgrade from subscribedPkg when:
 *  - Given selected (Trial/Paid) tier > subscribedTrial tier (tier: 1 - being the highest)
 *  - OR Given selected (Trial/Paid) tier = subscribedTrial tier AND selectedTerm < subscribedTerm
 */
const isTieredPkgADowngradeFromTrial = (subPackage: SubscribedPackageInfo, selPackages: PackageSubscription[]) => {
  return (
    isTieredPackage(subPackage) &&
    isTrialPackage(subPackage.variant) &&
    selPackages.every(
      (selPackage) =>
        selPackage.tier > subPackage.tier ||
        (selPackage.tier === subPackage.tier &&
          (selPackage.variant.initialTermUnit !== subPackage.variant.initialTermUnit ||
            selPackage.variant.initialTerm < subPackage.variant.initialTerm)),
    )
  );
};

const isAlaCarteATrialPackage = (subPackage: SubscribedPackageInfo) => {
  return !isTieredPackage(subPackage) && isTrialPackage(subPackage.variant);
};

const isAlaCartePackageAnUpgradeDowngrade = (subPackage: SubscribedPackageInfo, selPackages: PackageSubscription[]) => {
  return selPackages.some(
    (selPkg) =>
      (subPackage.packageId === selPkg.id && subPackage.variant.id !== selPkg.variant.id) ||
      doesBundleContainPackage(subPackage, selPkg),
  );
};

const isTieredPackage = ({ tier }: SubscribedPackageInfo) => {
  return tier !== null && tier !== MIN_PKG_TIER;
};

const isAutoRenew = (variant: VariantInfo) => {
  // For trialPackage, autoRenew should always be false, so that it does not require any payment to add to file
  if (isTrialPackage(variant)) {
    return false;
  }
  const { renewable, actualPrice, discounts } = variant;
  // When ccRequired is false and actual pkgPrice is not a $0 pkg then return true else false
  const isPkgNotZeroDollar = actualPrice > 0;
  return discounts?.length && discounts[0]?.hasOwnProperty('ccRequired')
    ? discounts[0].ccRequired
      ? true
      : isPkgNotZeroDollar
    : renewable;
};
